import * as React from 'react';
import { ValidationRule } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import Utility from 'Core/Utility';

export default class ValidationUtility {
  public static required(message?: string): ValidationRule[] {
    message = message ?? 'This is required';
    return [{ required: true, message }];
  }

  public static If(expression: boolean, rules: ValidationRule[]): ValidationRule[] {
    if (expression) return rules;
    else return [];
  }

  public static pattern(pattern: RegExp, message?: string): ValidationRule[] {
    message = message ?? 'input not properly formated';
    return [{ required: true, message }];
  }

  public static email(message?: string): ValidationRule[] {
    const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    message = message ?? 'email address is not correct';
    return [...this.pattern(emailRegex, message)];
  }

  public static OneMustBeSupplied(
    form: WrappedFormUtils,
    fieldNames: string[],
    message?: React.ReactNode
  ): ValidationRule[] {
    const validator = (rule, value, callback) => {
      message = message ?? `Either ${fieldNames?.reduce((x, y) => `${y}, ${x}`)} must be supplied`;
      let oneIsSupplied = false;

      fieldNames?.forEach(fieldName => {
        if (form.getFieldValue(fieldName)) {
          oneIsSupplied = true;
        }
      });

      if (!oneIsSupplied) {
        return callback(message);
      } else {
        return callback();
      }
    };
    return [{ validator: validator }];

  }

  public static password(): ValidationRule[] {
    const validator = (rule, value, callback) => {
      const hasNumberRegex = /\d/;
      const hasLowerCaseRegex = /[a-z]/;
      const hasUpperCaseRegex = /[A-Z]/;
      const atleast6CharactersRegex = /(\S).{6,}/;

      const hasNumber = hasNumberRegex.test(value);
      const hasLowerCase = hasLowerCaseRegex.test(value);
      const hasUpperCase = hasUpperCaseRegex.test(value);
      const atleast6Characters = atleast6CharactersRegex.test(value);

      if (hasNumber && hasLowerCase && hasUpperCase && atleast6Characters) {
        callback();
      } else {
        const messages = (
          <ul style={{ listStyle: 'arrow' }}>
            <li style={{ color: hasNumber ? 'green' : 'red' }}>Must have at least one number</li>
            <li style={{ color: hasLowerCase ? 'green' : 'red' }}>
              Must have one lower case character
            </li>
            <li style={{ color: hasUpperCase ? 'green' : 'red' }}>
              Must have one upper case character
            </li>
            <li style={{ color: atleast6Characters ? 'green' : 'red' }}>
              Must be atleast 6 characters long
            </li>
          </ul>
        );

        callback(messages);
      }
    };

    return [{ validator: validator }];
  }

  public static compare(
    form: WrappedFormUtils,
    otherFieldName: string,
    message: React.ReactNode
  ): ValidationRule[] {
    const validator = (rule, value, callback) => {
      message = message ?? otherFieldName + ' must be equal to ' + (rule.field ?? 'other field');
      const otherFieldValue = form.getFieldValue(otherFieldName);

      if (value !== otherFieldValue) {
        return callback(message);
      } else {
        return callback();
      }
    };
    return [{ validator: validator }];
  }

  public static TransformEditorState(
    value
  ) {
    if (value && value.toRAW) {
      return value?.toRAW();
    } else {
      return value;
    }
  }

  public static EditorStateIsEmpty(
    message?: React.ReactNode
  ): ValidationRule[] {
    message = message ?? `field Is required`;
    const validator = (rule, value, callback) => {
      if ((value?.isEmpty && value.isEmpty()) || !value) {
        callback(message);
      } else {
        callback();
      }
    };
    return [{ validator: validator }];
  }

  public static Base64ImageHasValidImageSizes(
    validImagesSizes: { width: number, height: number }[],
    message?: React.ReactNode
  ): ValidationRule[] {
    message = message ?? `Image size is not correct`;
    const validator = (rule, value, callback) => {
      console.log("in validator")
      try {
        if ((value?.content)) {
          var imageSize = Utility.getImageDimensions(value.content).then(x => {
            if (validImagesSizes.some(validImagesSize => validImagesSize.width == x.width && validImagesSize.height)) {
              callback();
            } else {
              callback(message);
            }
          });


        } else {
          callback();
        }
      } catch (error) {
        console.log("error", error);
        callback();
      }
    };
    return [{ validator: validator }];
  }
}










