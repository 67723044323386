import { Col, Row } from 'antd';
import * as React from 'react';
import { RouteProps } from 'react-router-dom';
import styled from 'styled-components';
import ManagementTeam from 'components/BoardOfDirectors';
import { Redirect } from 'react-router';

export interface Props extends RouteProps {
  children?: React.ReactNode;
}

export interface State {
  isConfirmed: boolean;
}

export default class NewsPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);


        this.state = { isConfirmed: false };
    }

    componentDidMount() {

    }

    render() {
        return <ManagementTeam />;
    }
}










