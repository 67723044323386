import { DataFileType, DocumentModel, IDocumentModel } from 'Core/Api/Api';
import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { capitalize, startCase } from 'lodash';
import { Button, Popconfirm, Popover } from 'antd';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
const DocumentContainer = styled.div`
  display: flex;
  gap: 5px;
`;
const Title = styled.div<{ deleted: boolean }>`
text-decoration:  ${(props) => props.deleted ? "line-through" : "none"};
`;
const DocumentTypeContainer = styled.div<{ deleted: boolean }>`
text-decoration:  ${(props) => props.deleted ? "line-through" : "none"};
`;
const DocumentActionsContainer = styled.div``;


interface Props {
  document: IDocumentModel;
  onDelete?: (document: IDocumentModel) => void;
  onEdit?: (document: IDocumentModel) => void;
  readonly?: boolean;
}

export default ({ document, onDelete, onEdit, readonly }: Props) => {

  const handleOnEditClicked = (event: SyntheticEvent) => {
    if (onEdit) {
      onEdit(document);
    }
  }

  const handleOnDeleteClicked = (e?: React.MouseEvent<HTMLElement>) => {
    if (onDelete) {
      onDelete(document);
    }
  }
  const isImage = (value) => {
    return value?.type?.includes("image") || [".jpg", ".png", ".jpeg", ".webp", ".gif", ".svg"].some(x => value?.name?.includes(x));
  }

  const PopoverDisplay = ({ document }: { document: IDocumentModel }) => {
    return <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      {isImage(document.content) && <img width={"100px"} height={"100px"} src={document?.content?.content ?? (document?.content as any)?.url} />}
      <div>{document.description}</div>
    </div>;
  }

  const LinkToDocument = ({ children }) => {
    if (readonly) {
      return <a href={document?.content?.content ?? document?.content?.url} download target="_blank">
        {children}
      </a>
    } else {
      return <>
        {children}
      </>
    }
  }

  return <DocumentContainer>
    <LinkToDocument>
      <Title deleted={!!document.removed} > <Popover content={<PopoverDisplay document={document} />}>{document.name ?? document.url} </Popover></Title>
      <DocumentTypeContainer deleted={!!document.removed}><i>({startCase(document.fileType ?? DataFileType.GalleryDocument)})</i></DocumentTypeContainer>
      <ShowIfTrue condition={!document.removed && !readonly}>
        <DocumentActionsContainer>
          <Button icon='edit' onClick={handleOnEditClicked}></Button>


          <Popconfirm title="Are you sure you with to remove this document?" onConfirm={handleOnDeleteClicked} >
            <Button icon='delete'></Button>
          </Popconfirm>

        </DocumentActionsContainer>
      </ShowIfTrue>
    </LinkToDocument>
  </DocumentContainer>;
}