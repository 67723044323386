import React from 'react';
import antd from 'antd';
import { GetFieldDecoratorOptions } from 'antd/lib/form/Form';
import { FormItemProps } from 'antd/lib/form';

const { Form } = antd;
export interface Props extends GetFieldDecoratorOptions, FormItemProps {
  children: any;
  getFieldDecorator: any;
  name: string;
}

const FormControl = ({ children, getFieldDecorator, name, ...rest }: Props) => (
  <Form.Item {...rest}>{getFieldDecorator(name, rest)(children)}</Form.Item>
);

export default FormControl;













