import ITableDataFetcher from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import ITableDataResponse from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';
import {
    AddUserModel,
    EditUserModel,
    GetAllRolesByFilterModel,
    GetAllUsersByFilterModel,
    GetOrderReceiptForAdministratorQueryRequest,
    GetPaymentsByFilterQueryRequest,
    GetUserByIdModel,
    IGetAllRolesByFilterModel,
    IGetOrderReceiptForAdministratorQueryRequest,
    OrderBy,
    PaymentListItem,
    SlimUser,
} from 'Core/Api/Api';
import { SecuredClient } from 'Core/Api/SecuredClient';
import TableRequest from 'Core/TableUtility/Models/TableRequest';

export default class PaymentHandler implements ITableDataFetcher<PaymentListItem> {
    constructor(props?: any) { }

    GetData(tableRequest: TableRequest): Promise<ITableDataResponse<PaymentListItem>> {
        return this.GetPayments(tableRequest);
    }

    //GetAllUserByFilter(request: TableRequest) {
    //  const client = new SecuredClient();
    //  client.showLoading = false;
    //  client.handleGeneralError = true;
    //  const getFilteredMealsForOwnerRequest = new UserSlimUserFilterResponseFilterRequest(request);
    //  return client.getAllUsersByFilter(getFilteredMealsForOwnerRequest);
    //  }

    GetPayments(request: TableRequest) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;
        const getFilteredMealsForOwnerRequest = new GetPaymentsByFilterQueryRequest(request);
        return client.getPaymentsListByFilter(getFilteredMealsForOwnerRequest);
    }

    GetFilteredRoles(requestInterface?: IGetAllRolesByFilterModel) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;
        let request = requestInterface
            ? new GetAllRolesByFilterModel(requestInterface)
            : new GetAllRolesByFilterModel();
        request.amountPerPage = 20;
        request.orderBy = [];
        request.orderBy.push(new OrderBy({ field: 'creationDate', ascending: false }));
        return client.getAllRolesByFilter(request);
    }

    GetPaymentById(id: string) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;
        return client.getPaymentDetails(id);
    }

    GetUserById(userId?: string) {
        const client = new SecuredClient();
        client.showLoading = true;
        client.handleGeneralError = true;
        const request = new GetUserByIdModel();
        request.id = userId;
        return client.getUserById(request);
    }

    EditUser(request: EditUserModel) {
        const client = new SecuredClient();
        client.showLoading = true;
        client.handleGeneralError = true;

        return client.editUser(request);
    }

    AddUser(request: AddUserModel) {
        const client = new SecuredClient();
        client.showLoading = true;
        client.handleGeneralError = true;

        return client.addUser(request);
    }

    GetReceipt(request: IGetOrderReceiptForAdministratorQueryRequest) {
        const client = new SecuredClient();
        client.showLoading = true;
        client.handleGeneralError = true;
        const model = new GetOrderReceiptForAdministratorQueryRequest(request);
        return client.getOrderReceiptForAdministrator(model);
    }
}
