import * as React from 'react';
import { Menu, Icon, Row, Col, PageHeader } from 'antd';
import { NavLink } from 'react-router-dom';
import { SFooter } from '../Footer.Styled';
import MenuAvatar from '../MenuAvatar';
import styled from 'styled-components';
import DarkLogoImg from '../../../assets/images/dark_logo.png';
import AuthContext from '../../../Context/AuthContext';
import Header from 'antd/lib/calendar/Header';
import Title from 'antd/lib/typography/Title';

export interface Props {
    children?: React.ReactNode;
}

export interface State { }

export default class FooterLayout extends React.Component<Props, State> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <SFooter>
                <Row type="flex" gutter={16}>
                    <Col xs={{ span: 24, order: 3 }} sm={{ span: 8, order: 1 }} style={{ textAlign: 'center' }} >
                        <img src={DarkLogoImg} style={{ maxHeight: '130px', maxWidth: '100%' }} />
                        <p style={{ color: 'white', marginTop: '30px', textAlign: 'justify' }}  >
                            The Jamaica Social Stock Exchange, JSSE, seeks
                            to engage the entire Jamaican economy in
                            promoting the Social Capital Market, believing that
                            true development of any country happens in all
                            areas of life and facets of society.
                        </p>
                    </Col>
                    <Col xs={{ span: 12, order: 1 }} sm={{ span: 7, order: 2, offset:1 }} style={{ color: 'white !important' }}>
                        <Title level={4} style={{ color: 'white' }}> Contact Us </Title>
                        <p >40 Harbour Street, P.O.Box 1084,
                            <br />
                            Kingston, Jamaica, West Indies.
                            <br />
                            <br />
                            jsse@jamstockex.com
                            <br />
                            <br />
                            (876) 967-3271 ext. 2363
                            <br />
                            (876) 618-1118 ext. 2363
                            <br />
                            (876) 322-4505 (cell)

                        </p>
                        <p></p>
                        <p></p>
                    </Col>
                    <Col xs={{ span: 12, order: 2 }} sm={{ span: 8, order: 3 }} style={{ color: 'white !important' }}>
                        <Title level={4} style={{ color: 'white' }}>Useful Links</Title>
                        <Row> <Col span={12}>
                            <a href="#">Home</a>
                            <br/>
                            <a href="#">About Us</a>
                            <br/>
                            <a href="#">Social Partners</a>
                            <br/>
                            <a href="#">Contact Us</a>
                        </Col>
                            <Col span={12}>
                                <a href="#">FAQ</a>
                            <br/>
                                <a href="#">How To Apply</a>
                            <br/>
                                <a href="#">Current Projects</a>
                            <br/>
                                <a href="#">Glossary</a>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ marginTop: '30px' }} className="social-media">
                            <Col span={6}>
                                <a href="#"><Icon style={{  }} type="instagram" /></a>
                            </Col>
                            <Col span={6}>
                                <a href="#"><Icon style={{}} type="linkedin" theme='filled' /></a>
                            </Col>
                            <Col span={6}>
                                <a href="#"><Icon style={{ }} type="twitter" /></a>
                            </Col>
                            <Col span={6}>
                                <a href="#"><Icon style={{ }} type="facebook" /></a>
                            </Col>
                        </Row>
                    </Col>

                </Row>
                <Row type="flex" align="bottom" gutter={24} className={"lowerFooter"}>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} style={{ textAlign:'right', fontSize:'0.8em' }}  >
                        Copyright  &copy; 2023 Jamaica Social Stock Exchange. All Rights Reserved </Col>

                    <Col xs={{ span: 24 }} sm={{ span: 12 }} style={{ textAlign: 'left', fontSize: '0.8em' }}  >
                        <a href="#">Disclaimer</a> | <a href="#">Privacy and Security Policy</a>
                        <a href="#">Terms and Conditions</a>
                        <a href="#">Risk</a>
                    </Col>
                    {/*<Col span={8} offset={8}>*/}
                    {/*    <div className='social-media'>*/}
                    {/*        <Icon type="facebook" theme="filled" />*/}
                    {/*        <Icon type="instagram" theme="filled" />*/}
                    {/*        <Icon type="twitter-circle" theme="filled" />*/}
                    {/*    </div>*/}
                    {/*</Col>*/}
                </Row>
            </SFooter>
        );
    }
}
















