import React from 'react';
import { Form, Row, Input, Col, Button, Select, Transfer, Typography } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import FormControl from 'components/Forms/FormControl';
import { withRouter, RouteComponentProps, NavLink, RouteProps } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import DashboardImageHandler from '../DashboardImageHandler';
import ValidationUtility from '../../../Core/ValidationUtility';
import SimpleSelect from '../../Forms/SimpleSelect';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import NotificationService from 'Core/NotificationService';
import AuthContext from 'Context/AuthContext';
import {
    singleColFormColSize,
    FullWithButton,
    doubleColFormColSize,
} from 'components/Forms/Form.Styled';
import { LeftAlignedRow } from '../../Styled/Layout/LeftAlignedRow';
import Base64FileUploader, { IFile } from '../../Forms/Base64FileUploader/Base64FileUploader';

import Group_3649 from 'assets/images/Group_3649.png';
import Group_3650 from 'assets/images/Group_3650.png';
import Group_3651 from 'assets/images/Group_3651.png';
import Group_3652 from 'assets/images/Group_3652.png';
import Group_3653 from 'assets/images/Group_3653.png';
import { SelectValue } from 'antd/lib/select';
import { StyleRegisterForm } from '../../UserManagement/RegisterForm/RegisterForm.Style';
import { IChangeBannerImageWebModel } from '../../../Core/Api/Api';
import styled from 'styled-components';

const { Title } = Typography;

const HomeBanners = styled.div`
.selected{
    border: 4px solid #1970A2;
    transform: scale(0.95);
    transition: all 0.3s ease;
}
`;


const MainContent = styled.div`
.hidden {
    display: none;
}
.uploadbox {
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
`;

export interface Props extends FormComponentProps<IChangeBannerImageWebModel>, RouteProps {
    children?: React.ReactNode;
}

export interface State {
    file?: IFile;
    mainImageUrl?: string;
    topleftImageUrl?: string;
    toprightImageUrl?: string;
    bottomleftImageUrl?: string;
    bottomrightImageUrl?: string;
    isForm1Visible: boolean;
    isForm2Visible: boolean;
    isForm3Visible: boolean;
    isForm4Visible: boolean;
    isForm5Visible: boolean;
}

export class BaseDashboardImageForm extends React.Component<Props, State> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    handler: DashboardImageHandler;

    constructor(props: Props) {
        super(props);

        this.state = {
            isForm1Visible: false,
            isForm2Visible: false,
            isForm3Visible: false,
            isForm4Visible: false,
            isForm5Visible: false,
        };
        this.handler = new DashboardImageHandler(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.updateBanner();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {

    }

    handleSelectedImagePosition = (pos) => {
        const imageElement = document.getElementById(pos + '');

        if (imageElement?.classList.contains('selected')) {
            imageElement?.classList.remove('selected');
            const formElement = document.getElementById(`Form-${pos}`);
            if (formElement) {
                formElement.classList.add('hidden');
            }
            switch (pos) {
                case 1:
                    this.setState({ isForm1Visible: false });
                    break;
                case 2:
                    this.setState({ isForm2Visible: false });
                    break;
                case 3:
                    this.setState({ isForm3Visible: false });
                    break;
                case 4:
                    this.setState({ isForm4Visible: false });
                    break;
                case 5:
                    this.setState({ isForm5Visible: false });
                    break;
            }
        } else {
            imageElement?.classList.add('selected');
            const formElement = document.getElementById(`Form-${pos}`);
            if (formElement) {
                formElement.classList.remove('hidden');
            }
            switch (pos) {
                case 1:
                    this.setState({ isForm1Visible: true });
                    break;
                case 2:
                    this.setState({ isForm2Visible: true });
                    break;
                case 3:
                    this.setState({ isForm3Visible: true });
                    break;
                case 4:
                    this.setState({ isForm4Visible: true });
                    break;
                case 5:
                    this.setState({ isForm5Visible: true });
                    break;
            }
        }
    }


    updateBanner() {
        this.handler.GetBanner().then(response => {
            if (response.images?.mainImageFileId != null) {
                this.setState({ mainImageUrl: "/api/file/" + response.images?.mainImageFileId + "/" + response.images?.mainImageFileName })
            } else {
                this.setState({ mainImageUrl: Group_3649 })
            }
            if (response.images?.topLeftImageFileId != null) {
                this.setState({ topleftImageUrl: "/api/file/" + response.images?.topLeftImageFileId + "/" + response.images?.topLeftImageFileName })
            } else {
                this.setState({ topleftImageUrl: Group_3652 })
            }
            if (response.images?.topRightImageFileId != null) {
                this.setState({ toprightImageUrl: "/api/file/" + response.images?.topRightImageFileId + "/" + response.images?.topRightImageFileName })
            } else {
                this.setState({ toprightImageUrl: Group_3650 })
            }
            if (response.images?.bottomLeftImageFileId != null) {
                this.setState({ bottomleftImageUrl: "/api/file/" + response.images?.bottomLeftImageFileId + "/" + response.images?.bottomLeftImageFileName })
            } else {
                this.setState({ bottomleftImageUrl: Group_3653 })
            }
            if (response.images?.bottomRightImageFileId != null) {
                this.setState({ bottomrightImageUrl: "/api/file/" + response.images?.bottomRightImageFileId + "/" + response.images?.bottomRightImageFileName })
            } else {
                this.setState({ bottomrightImageUrl: Group_3651 })
            }

        })
    }

    handleMainImageUpload = (base64Url, pos) => {
        switch (pos) {
            case 1:
                this.setState({ mainImageUrl: base64Url?.content ?? base64Url });
                break;
            case 2:
                this.setState({ topleftImageUrl: base64Url?.content ?? base64Url });
                break;
            case 3:
                this.setState({ toprightImageUrl: base64Url?.content ?? base64Url });
                break;
            case 4:
                this.setState({ bottomleftImageUrl: base64Url?.content ?? base64Url });
                break;
            case 5:
                this.setState({ bottomrightImageUrl: base64Url?.content ?? base64Url });
                break;
        }
    };

    handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {

            if (!err) {
                this.handler.ChangeBanner(values).then(response => {
                    NotificationService.success(
                        'Success!',
                        <>
                            <p>
                                You have successfully updated the banner image. You can see the changes above!
                            </p>
                        </>, undefined, true
                    );
                    this.updateBanner();
                    this.props.form.resetFields();
                });
            }
        });
    }

    render() {
        var { getFieldDecorator } = this.props.form;
        return (
            <MainContent>

                <Row>
                    <Col span={24}>
                        <Row style={{ height: '100%' }} gutter={16}>
                            <div>
                                <h3>*Please select a banner position to upload. Note you can upload multiple at the same time</h3>
                            </div>
                            <HomeBanners>
                                <Col id={'1'} className={"banner-image"} xs={{ span: 12 }} style={{ height: '100%', }}>
                                    <img src={this.state.mainImageUrl} style={{ width: '100%' }} onClick={() => this.handleSelectedImagePosition('1')} />
                                </Col>
                                <Col xs={{ span: 12 }} style={{ height: '100%' }}>
                                    <Row gutter={16}>
                                        <Col id={'2'} className={"banner-image"} span={12}>
                                            <img src={this.state.topleftImageUrl} style={{ width: '100%' }} onClick={() => this.handleSelectedImagePosition('2')} />
                                        </Col>
                                        <Col id={'3'} className={"banner-image"} span={12}>
                                            <img src={this.state.toprightImageUrl} style={{ width: '100%' }} onClick={() => this.handleSelectedImagePosition('3')} />
                                        </Col>
                                    </Row>
                                    <Row gutter={16} style={{ height: '100%', marginTop: '16px' }}>
                                        <Col id={'4'} className={"banner-image"} span={12}>
                                            <img src={this.state.bottomleftImageUrl} style={{ width: '100%' }} onClick={() => this.handleSelectedImagePosition('4')} />
                                        </Col>
                                        <Col id={'5'} className={"banner-image"} span={12}>
                                            <img src={this.state.bottomrightImageUrl} style={{ width: '100%' }} onClick={() => this.handleSelectedImagePosition('5')} />
                                        </Col>
                                    </Row>

                                </Col>
                            </HomeBanners>
                        </Row>
                        <StyleRegisterForm labelAlign={'left'} labelCol={{
                            xs: { span: 24 }, sm: { span: 24 }
                        }}
                            wrapperCol={{
                                xs: { span: 24 }, sm: { span: 24 }
                            }}

                            autoComplete="off" layout="horizontal" onSubmit={this.handleSubmit}>
                            <br></br>
                            <br></br>
                            <Row>
                                <Col className={"form-body"} span={24}>
                                    <Col span={11} style={{ paddingRight: '1vw' }}>
                                        <Row gutter={16}>
                                            <Col span={24} id={'Form-1'} className={'hidden'}>
                                                <FormControl
                                                    getFieldDecorator={getFieldDecorator}
                                                    rules={[...ValidationUtility.If(this.state.isForm1Visible, ValidationUtility.required('Please upload an Image')),
                                                    ...ValidationUtility.Base64ImageHasValidImageSizes([{ width: 940, height: 577 }], "image size is not correct")]}
                                                    help={"Thumbnail images must be 940x577 pixels"}
                                                    name="mainImageBase64"
                                                    label="Main Image"
                                                    className="uploadbox"
                                                >
                                                    <Base64FileUploader accept={"image/*"} onUpload={(base64Url) => this.handleMainImageUpload(base64Url, 1)} />
                                                </FormControl>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={11}>
                                        <Row gutter={16}>
                                            <Col span={11} id={'Form-2'} className={'hidden'}>
                                                <FormControl
                                                    getFieldDecorator={getFieldDecorator}
                                                    rules={[...ValidationUtility.If(this.state.isForm2Visible, ValidationUtility.required('Please upload an Image')),
                                                    ...ValidationUtility.Base64ImageHasValidImageSizes([{ width: 473, height: 283 }], "image size is not correct")]}
                                                    help={"Thumbnail images must be 473x283 pixels"}
                                                    name="topLeftImageBase64"
                                                    label="Top Left Image"
                                                    className="uploadbox"
                                                >
                                                    <Base64FileUploader accept={"image/*"} onUpload={(base64Url) => this.handleMainImageUpload(base64Url, 2)} />
                                                </FormControl>
                                            </Col>

                                            <Col span={11} id={'Form-3'} className={'hidden'}>
                                                <FormControl
                                                    getFieldDecorator={getFieldDecorator}
                                                    rules={[...ValidationUtility.If(this.state.isForm3Visible, ValidationUtility.required('Please upload an Image')),
                                                    ...ValidationUtility.Base64ImageHasValidImageSizes([{ width: 473, height: 283 }], "image size is not correct")]}
                                                    help={"Thumbnail images must be 473x283 pixels"}
                                                    name="topRightImageBase64"
                                                    label="Top Right Image"
                                                    className="uploadbox"
                                                >
                                                    <Base64FileUploader accept={"image/*"} onUpload={(base64Url) => this.handleMainImageUpload(base64Url, 3)} />
                                                </FormControl>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col span={11} id={'Form-4'} className={'hidden'}>
                                                <FormControl
                                                    getFieldDecorator={getFieldDecorator}
                                                    rules={[...ValidationUtility.If(this.state.isForm4Visible, ValidationUtility.required('Please upload an Image')),
                                                    ...ValidationUtility.Base64ImageHasValidImageSizes([{ width: 473, height: 283 }], "image size is not correct")]}
                                                    help={"Thumbnail images must be 473x283 pixels"}
                                                    name="bottomLeftImageBase64"
                                                    label="Bottom Left Image"
                                                    className="uploadbox"
                                                >
                                                    <Base64FileUploader accept={"image/*"} onUpload={(base64Url) => this.handleMainImageUpload(base64Url, 4)} />
                                                </FormControl>
                                            </Col>
                                            <Col span={11} id={'Form-5'} className={'hidden'}>
                                                <FormControl
                                                    getFieldDecorator={getFieldDecorator}
                                                    rules={[...ValidationUtility.If(this.state.isForm5Visible, ValidationUtility.required('Please upload an Image')),
                                                    ...ValidationUtility.Base64ImageHasValidImageSizes([{ width: 473, height: 283 }], "image size is not correct")]}
                                                    help={"Thumbnail images must be 473x283 pixels"}
                                                    name="bottomRightImageBase64"
                                                    label="Bottom Right Image"
                                                    className="uploadbox"
                                                >
                                                    <Base64FileUploader accept={"image/*"} onUpload={(base64Url) => this.handleMainImageUpload(base64Url, 5)} />
                                                </FormControl>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={15}>
                                    <Col xs={{ span: 8, offset: 0 }} sm={{ span: 6, offset: 0 }} md={{ span: 4, offset: 0 }}>
                                        <NavLink to="/">
                                            <Button className="cancel-form-button" size="large" >Back</Button>
                                        </NavLink>
                                    </Col>
                                    <Col xs={{ span: 8, offset: 8 }} sm={{ span: 6, offset: 12 }} md={{ span: 4, offset: 16 }}>
                                        <Button className="change-banner-form-button" size="large" type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Col>
                                </Col>
                            </Row>
                        </StyleRegisterForm >
                    </Col>
                </Row>
            </MainContent>
        );
    }
}


const DashboardImageForm = Form.create<Props>({
    name: 'ChangeBanner',
})(BaseDashboardImageForm);

export default DashboardImageForm;
