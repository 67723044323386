import { Form, Icon, Input, Button, Checkbox, Row, Col, DatePicker, Divider, Radio, Collapse, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React from 'react';
import { Redirect, RouteProps, NavLink } from 'react-router-dom';
import AuthContext from '../../../Context/AuthContext';
import FormControl from '../../Forms/FormControl';
import { StyleRegisterForm } from './RegisterForm.Style';
import { RegisterFormHandler } from './RegisterFormHandler';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import { singleColFormColSize } from 'components/Forms/Form.Styled';
import ValidationUtility from 'Core/ValidationUtility';
import MaskedInput from 'components/Forms/MaskedInput';
import moment from 'moment';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import NotificationService from 'Core/NotificationService';
import ISelectOption from '../../Forms/SimpleSelect/ISelectOption';
import SimpleSelect from '../../Forms/SimpleSelect';
import styles from './RegistrationStyles.module.css';
import { MainLogo } from '../../Layout/RegisterLayout/RegisterLayout.Style';


const { Option } = Select;
const { Panel } = Collapse;

export interface Props extends FormComponentProps, RouteProps {
    children?: React.ReactNode;
}

export interface State {
    registered: boolean;
    isCompany: boolean;
    isIndividual: boolean;
    questions: ISelectOption[];
    dobMode: "decade" | "time" | "year" | "month" | "date" | undefined;

}

class BaseRegisterForm extends React.Component<Props, State> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    registerFormHandler: RegisterFormHandler;

    constructor(props: Props) {
        super(props);
        this.registerFormHandler = new RegisterFormHandler(props);
        this.state = {
            registered: false,
            isCompany: false,
            isIndividual: false,
            questions: [],
            dobMode: 'decade'
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.setSecurityQuestions();
        this.props.form.setFieldsValue({ clientType: 1 });
    }

    setSecurityQuestions() {
        var questions = [
            { text: 'What is the name of your childhood best friend?' },
            { text: 'What is the name of your manager at your first job?' },
            { text: 'Who is your favourite author?' },
            { text: 'What was the name of your first pet?' },
            { text: 'Where did you meet your spouse?' },
            { text: 'What is the name of the hospital in which you were born?' },
            { text: "What is your mother's middle name?" },
            { text: 'What was your favourite restaurant in college?' },
            { text: 'What was your favourite food as a child?' },
            { text: 'What is the name of your favourite book?' },
            { text: 'What is the first name of your favourite aunt?' },
            { text: 'What was your dream job as a child?' },
            { text: 'What was the name of your first school?' },
            { text: 'What is the first name of your oldest niece?' },
            { text: 'Where did you spend your honeymoon?' }
        ];
        this.setState({ questions: questions });
    }

    onChange = e => {
        if (e.target.value == 1) {

        } else if (e.target.value == 2) {

        } else {

        }
        this.setState({
            isCompany: (e.target.value == 1 ? false : true),
            isIndividual: (e.target.value == 2 ? false : true),
        });
    };

    handleSubmit(e: React.FormEvent) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {


            if (!err) {

                values.dateOfBirth = moment(values.dateOfBirth).format('YYYY-MM-DD');
                this.registerFormHandler.RequestRegister(values).then(user => {
                    NotificationService.success(
                        'Successfully Registered',
                        <>
                            <p>
                                You have successfully registered your account. Please check your email address to activate your account.
                            </p>
                        </>, undefined, true
                    );
                    // this.context.Update(user);
                    this.setState({ registered: true });



                });
            }
        });
    }

    onPanelChange = (v, m) => {

        if (m != null) {
            this.setState({
                dobMode: m
            });
        } else {
            var newMode;
            switch (this.state.dobMode) {
                case 'date': newMode = 'date';
                    break;
                case 'decade': newMode = 'year';
                    break;
                case 'month': newMode = 'date';
                    break;
                case 'year': newMode = 'month';
                    break;
                default: newMode = null;
                    break;

            }

            this.setState({
                dobMode: newMode
            });
        }
    }

    onPanelOpen = (v) => {

        this.setState({
            dobMode: 'decade'
        });

    }

    render() {
        const { getFieldDecorator } = this.props.form;
        let state: { from?: any; noPermission?: boolean } = {};

        if (this.props.location) {
            state = this.props.location.state || {};
        }

        if (this.state.registered) {
            return <Redirect to="/login" />;
        }

        return (

            <StyleRegisterForm labelAlign={'left'} labelCol={{
                xs: { span: 24 }, sm: { span: 24 }
            }}
                wrapperCol={{
                    xs: { span: 24 }, sm: { span: 24 }
                }}

                autoComplete="off" layout="horizontal" onSubmit={this.handleSubmit}>
                <NavLink to="/" ><MainLogo /></NavLink>

                <Row type={'flex'} align={'bottom'}>
                    <Col xs={12} sm={12}>
                        <Title style={{ color: '#202020', fontWeight: 'bold' }} level={2}>Sign Up</Title>
                    </Col>
                    <Col xs={12} sm={12}>
                        <RightAlignedRow>
                            <Col>
                                <Paragraph>Already have an account? <NavLink to={{ pathname: 'login' }} style={{ color: '#F0403D', fontWeight: 'normal' }}>SIGN IN</NavLink> </Paragraph>
                            </Col>
                        </RightAlignedRow>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ span: 24 }}>
                        <Divider orientation="left"></Divider>
                        <Row>
                            <Col xs={{ span: 23, offset: 0 }} sm={{ span: 23, offset: 0 }}>
                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    label="Client Type: "

                                    rules={[...ValidationUtility.required('Please select a client type!')]}
                                    name="clientType" //getFieldDecorator(name: { hidden: true })
                                >
                                    <Radio.Group onChange={this.onChange}>
                                        <Radio value={1}>Individual</Radio>
                                        <Radio value={2}>Company</Radio>
                                    </Radio.Group>
                                </FormControl>

                            </Col>
                        </Row>
                        {/*   <Form.Item
                                    label="Client Type">
                                    
                                    {getFieldDecorator("clientType", {
                                        rules: [{ required: true, message: "Please select a client type!" }]
                                    })(
                                        <Radio.Group onChange={this.onChange}>
                                            <Radio value={1}>Individual</Radio>
                                            <Radio value={2}>Company</Radio>
                                        </Radio.Group>
                                    )}
                                </Form.Item> */}


                        <div id="personalDiv" style={!this.state.isCompany ? {} : { display: 'none' }}>
                            <div className={styles.separator}>Personal Details</div>
                            <Row>
                                <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>

                                    <FormControl
                                        getFieldDecorator={getFieldDecorator}
                                        rules={[...ValidationUtility.If(!this.state.isCompany, ValidationUtility.required('First name is required'))]}
                                        name="firstName" //getFieldDecorator(name: { hidden: true })
                                        label="First Name"
                                    >
                                        <Input />
                                    </FormControl>
                                </Col>
                                <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11 }}>
                                    <FormControl
                                        getFieldDecorator={getFieldDecorator}
                                        rules={[...ValidationUtility.If(!this.state.isCompany, ValidationUtility.required('Last name is required'))]}
                                        name="lastName"
                                        label="Last Name"
                                    >
                                        <Input />
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>

                                    <FormControl
                                        getFieldDecorator={getFieldDecorator}
                                        rules={[...ValidationUtility.If(!this.state.isCompany, ValidationUtility.required('Telephone is required'))]}
                                        name="telephone"
                                        label="Telephone"
                                    >
                                        <Input />
                                    </FormControl>
                                </Col>
                                <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11 }}>
                                    <FormControl
                                        getFieldDecorator={getFieldDecorator}
                                        rules={[...ValidationUtility.If(!this.state.isCompany, ValidationUtility.required('Date of Birth is required'))]}
                                        name="dateOfBirth"
                                        label="Date of Birth"
                                    >

                                        <DatePicker mode={this.state.dobMode} onPanelChange={this.onPanelChange} onOpenChange={this.onPanelOpen} defaultPickerValue={moment("1999-01-01")} disabledDate={d => !d || d.isSameOrAfter(moment())} style={{ width: '100%' }}></DatePicker>
                                    </FormControl>
                                </Col>
                            </Row>
                            <div className={styles.separator}>Identification</div>

                            <Row>
                                <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>
                                    <FormControl
                                        getFieldDecorator={getFieldDecorator}
                                        rules={[...ValidationUtility.If(!this.state.isCompany, ValidationUtility.required('Id Type is required'))]}
                                        name="idType"
                                        label="ID Type"
                                    >
                                        <Select allowClear={true}
                                            placeholder={'Please select an option'}
                                            optionFilterProp={'label'} >
                                            <Option key={'Passport'} label={'Passport'} value={'Passport'}>
                                                Passport
                                            </Option>
                                            <Option key={"Driver's License"} label={"Driver's License"} value={"Driver's License"}>
                                                Driver's License
                                            </Option>
                                            <Option key={'National ID'} label={'National ID'} value={'National ID'}>
                                                National ID
                                            </Option>
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11 }}>

                                    <FormControl
                                        getFieldDecorator={getFieldDecorator}
                                        rules={[...ValidationUtility.If(!this.state.isCompany, ValidationUtility.required('Id Number is required'))]}
                                        name="idNumber"
                                        label="ID Number"
                                    >
                                        <Input />
                                    </FormControl>
                                </Col>
                            </Row>
                        </div>
                        <div id="companyDiv" style={this.state.isCompany ? {} : { display: 'none' }}>
                            <div className={styles.separator}>Company Details</div>
                            <Row>
                                <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>

                                    <FormControl
                                        getFieldDecorator={getFieldDecorator}
                                        rules={[...ValidationUtility.If(this.state.isCompany, ValidationUtility.required('Business name is required'))]}
                                        name="businessName"
                                        label="Business Name"
                                    >
                                        <Input />
                                    </FormControl>
                                </Col>
                                <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11 }}>
                                    <FormControl
                                        getFieldDecorator={getFieldDecorator}
                                        rules={[...ValidationUtility.If(this.state.isCompany, ValidationUtility.required('Contact First Name is required'))]}
                                        name="contactFirstName"
                                        label="Contact First Name"
                                    >
                                        <Input />
                                    </FormControl>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>
                                    <FormControl
                                        getFieldDecorator={getFieldDecorator}
                                        rules={[...ValidationUtility.If(this.state.isCompany, ValidationUtility.required('Contact Last Name is required'))]}
                                        name="contactLastName"
                                        label="Contact Last Name"
                                    >
                                        <Input />
                                    </FormControl>
                                </Col>
                                <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11 }}>
                                    <FormControl
                                        getFieldDecorator={getFieldDecorator}
                                        rules={[...ValidationUtility.If(this.state.isCompany, ValidationUtility.required('Telephone is required'))]}
                                        name="contactTelephone"
                                        label="Contact Telephone"
                                    >
                                        <Input />
                                    </FormControl>
                                </Col>
                            </Row>

                            <div className={styles.separator}>Identification</div>
                            <Row>
                                <Col xs={{ span: 23, offset: 1 }}>

                                    <FormControl
                                        getFieldDecorator={getFieldDecorator}
                                        rules={[...ValidationUtility.If(this.state.isCompany, ValidationUtility.required('TRN is required'))]}
                                        name="trn"
                                        label="TRN"
                                    >
                                        <Input />
                                    </FormControl>

                                </Col>
                            </Row>
                        </div>

                        <div className={styles.separator}>Account Setup</div>
                        <Row>
                            <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>

                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    rules={[...ValidationUtility.required('Email is required')]}
                                    name="emailAddress"
                                    label="Email"
                                >
                                    <Input />
                                </FormControl>
                            </Col>
                            <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11 }}>

                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    rules={[...ValidationUtility.required('Confirm email is required')]}
                                    name="confirmEmail"
                                    label="Confirm Email"
                                >
                                    <Input />
                                </FormControl>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11, offset: 1 }}>

                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    rules={[...ValidationUtility.required('Password is required')]}
                                    name="password"
                                    label="Password"
                                >
                                    <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="password" />
                                </FormControl>
                            </Col>
                            <Col xs={{ span: 23, offset: 1 }} sm={{ span: 11 }}>
                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    rules={[...ValidationUtility.required('Confirm Password is required')]}
                                    name="confirmPassword"
                                    label="Confirm Password"
                                >
                                    <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                        type="password" />
                                </FormControl>
                            </Col>
                        </Row>

                        <div className={styles.separator}>Security Questions</div>
                        <Row>
                            <Col xs={{ span: 23, offset: 1 }} sm={{ span: 13, offset: 1 }}>

                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    rules={[...ValidationUtility.required('Security Question 1 is required')]}
                                    name="question1"
                                    label="Security Question 1"
                                >
                                    <SimpleSelect options={this.state.questions} />
                                </FormControl>
                            </Col>
                            <Col xs={{ span: 23, offset: 1 }} sm={9}>

                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    rules={[...ValidationUtility.required('Answer 1 is required')]}
                                    name="answer1"
                                    label="Answer"
                                >
                                    <Input />
                                </FormControl>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 23, offset: 1 }} sm={{ span: 13, offset: 1 }}>

                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    rules={[...ValidationUtility.required('Security Question 2 is required')]}
                                    name="question2"
                                    label="Security Question 2"
                                >
                                    <SimpleSelect options={this.state.questions} />
                                </FormControl>
                            </Col>
                            <Col xs={{ span: 23, offset: 1 }} sm={9}>

                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    rules={[...ValidationUtility.required('Answer 2 is required')]}
                                    name="answer2"
                                    label="Answer"
                                >
                                    <Input />
                                </FormControl>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={{ span: 23, offset: 1 }} sm={{ span: 13, offset: 1 }}>

                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    rules={[...ValidationUtility.required('Security Question 3 is required')]}
                                    name="question3"
                                    label="Security Question 3"
                                >
                                    <SimpleSelect options={this.state.questions} />

                                </FormControl>
                            </Col>
                            <Col xs={{ span: 23, offset: 1 }} sm={9}>

                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    rules={[...ValidationUtility.required('Answer 3 is required')]}
                                    name="answer3"
                                    label="Answer"
                                >
                                    <Input />
                                </FormControl>
                            </Col>
                        </Row>
                    </Col>

                </Row>

                <Row>
                    <Col span={24}>
                        <FormControl
                            wrapperCol={{ xs: { offset: 1, span: 23 }, sm: { offset: 1, span: 20 } }}
                            getFieldDecorator={getFieldDecorator}
                            rules={[
                                {
                                    required: true,
                                    transform: value => value || undefined,
                                    type: 'boolean',
                                    message: 'You must agree to the terms of use and the privacy policy.',
                                },
                            ]}
                            label=""
                            name="agreement" //getFieldDecorator(name: { hidden: true })
                        >
                            <p className="text-content-justified">
                                <Checkbox>
                                    I agree to the Terms of Use and have read and understand the Privacy Policy
                                </Checkbox>
                            </p>
                        </FormControl>


                    </Col>
                </Row>
                <Row>
                    <Col span={15}></Col>
                    <Col xs={{ span: 8, offset: 8 }} sm={{ span: 6, offset: 12 }} md={{ span: 4, offset: 16 }}>

                        <Button className="register-form-button" size="large" type="primary" htmlType="submit">
                            Submit
                                </Button>
                    </Col>
                    <Col xs={{ span: 8, offset: 0 }} sm={{ span: 6, offset: 0 }} md={{ span: 4, offset: 0 }}>
                        <NavLink to="/">
                            <Button className="cancel-form-button" size="large" ghost >Cancel</Button>
                        </NavLink>
                    </Col>
                </Row>
            </StyleRegisterForm>

        );
    }

    private ShouldRedirectTo(state: { from?: any; noPermission?: boolean | undefined }): boolean {
        return !!state.from && state.from.pathname !== '/logout';
    }
}

const RegisterForm = Form.create<Props>({
    name: 'Register',
})(BaseRegisterForm);

export default RegisterForm;













