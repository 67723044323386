import React from 'react';
import { Form, Row, Input, Col, Button, Select, Transfer, Typography, Card } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import FormControl from 'components/Forms/FormControl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import UserHandler from '../UserHandler';
import ValidationUtility from '../../../Core/ValidationUtility';
import SimpleSelect from '../../Forms/SimpleSelect';
import {
    RoleStatus,
    Operations,
    User,
    Role,
    UserStatus,
    EditUserModel,
    AddUserModel
} from '../../../Core/Api/Api';
import Utility from 'Core/Utility';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import MaskedInput from 'components/Forms/MaskedInput';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import DetailsMode from 'Core/models/DetailsMode';
import DetailsData from 'Core/models/DetailsData';
import NotificationService from 'Core/NotificationService';
import TextArea from 'antd/lib/input/TextArea';
import { TransferItem } from 'antd/lib/transfer';
import AuthContext from 'Context/AuthContext';
import { delay, findLastIndex } from 'lodash';
import SimpleTransfer from 'components/Forms/SimpleTransfer';
import {
    singleColFormColSize,
    FullWithButton,
    doubleColFormColSize,
} from 'components/Forms/Form.Styled';
import { CenterAlignedRow } from '../../Styled/Layout/CenterAlignedRow';
import ShowIfTrue from 'components/Forms/ShowIfTrue';

const { Title } = Typography;

const buttonStyle = {
    width: '80px',
    fontWeight: 600,
};

const labelStyle = {
    fontWeight: 600
};

export interface Props extends FormComponentProps, RouteComponentProps {
    children?: React.ReactNode;
}

export interface State {
    jurisdictions: ISelectOption[];
    operationsListOptions: TransferItem[];
    operations: string[];
    status: ISelectOption[];
    AllRoles: Role[];
    user?: User;
    roles: ISelectOption[];
    detailsMode: DetailsMode;
}

export class UserDetailsFormWithoutFormAndRouter extends React.Component<Props, State> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    handler: UserHandler;

    constructor(props: Props) {
        super(props);

        this.state = {
            jurisdictions: [],
            operations: [],
            operationsListOptions: [],
            status: [],
            roles: [],
            AllRoles: [],
            detailsMode: DetailsMode.New,
        };
        this.handler = new UserHandler(props);
    }

    componentDidMount() {
        this.getRoles();
        this.getStatus();
        this.getUserDetails();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {

    }

    getUserDetails() {
        const urlState = this.props.location.state as DetailsData | undefined;

        if (urlState && urlState.mode == DetailsMode.Edit) {
            this.handler.GetUserById(urlState.id).then(
                x => {
                    this.setState({ user: x.user, detailsMode: DetailsMode.Edit }, () => {
                        this.props.form.setFieldsValue(x.user ?? {});
                        this.props.form.setFieldsValue({ roles: x.roles });
                    });
                },
                x => NotificationService.error(x.message)
            );
        } else {
            this.setState({ detailsMode: DetailsMode.New });
        }
    }


    getRoles() {
        this.handler.GetFilteredRoles().then(x => {
            this.setState({ AllRoles: x.results ?? [] }, () => {
                this.updateRoleList();

                this.props.form.setFieldsValue({
                    roles: this.state.user?.userRoles ?? [],
                });
            });
        });
    }


    updateRoleList = () => {
        let filteredRoles: Role[];
        filteredRoles = this.state.AllRoles;

        const roles: IFilterSelectOption[] =
            filteredRoles?.map(y => ({
                text: y.name,
                value: y.id,
            })) ?? [];
        this.setState({ roles });
    };

    getStatus() {
        var status: ISelectOption[] = [];
        Utility.enumToMap(UserStatus).forEach((x, y) => status.push({ text: x, value: y }));
        this.setState({ status });
    }

    //getOperations() {
    //    this.handler.GetOperations().then(x => {
    //        this.setState({ operations: x.operations ?? [] }, () => {
    //            this.props.form.setFieldsValue({ operations: this.state.role?.operations } ?? {});
    //            this.updateOperationsList();
    //        });
    //    });
    //}

    updateOperationsList = () => {
        let operationsList: TransferItem[] = [];

        operationsList = this.state.operations.map(x => ({ key: x, title: x }));

        this.setState({ operationsListOptions: operationsList });
    };

    onJurisdictionChange = (value: any) => {
        // This is done because the value doesn't seem to update on deselect
        delay(this.updateOperationsList, 10, value);
    };

    onSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values: any) => {
            if (error) {
                return;
            }

            if (this.state.detailsMode == DetailsMode.Edit) {
                const request = new EditUserModel();
                request.id = this.state.user?.id;
                request.firstName = values.firstname;
                request.lastName = values.lastname;
                request.phoneNumber = (values as any).phoneNumber;
                request.emailAddress = values.email;
                request.status = values.status;

                this.setRoles(request, values.roles as any);

                console.log(values, request);
                this.handler.EditUser(request).then(x => {
                    this.props.history.goBack();
                    NotificationService.success(`Updated ${x.user?.username}`);
                });
            } else {
                const request = new AddUserModel();
                this.setRoles(request, values.roles as any);
                request.emailAddress = values.email;
                request.userName = values.userName;
                request.firstName = values.firstname;
                request.lastName = values.lastname;
                request.password = values.password;
                request.confirmationPassword = values.confirmationPassword;
                request.phoneNumber = (values as any).phoneNumber;
                console.log(request, values);
                this.handler.AddUser(request).then(x => {
                    this.props.history.goBack();
                    NotificationService.success(`Added ${x.newUser?.username}`);
                });
            }
        });
    };

    setRoles(request: { roles?: string[] }, arg1: any) {
        if (arg1.map) {
            request.roles = arg1;
        } else {
            request.roles = [];
            request.roles.push(arg1);
        }
    }

    render() {
        var { getFieldDecorator } = this.props.form;
        return (
            <Card style={{ display: 'inline-block', verticalAlign: "center", width: '90%', height: '90%', padding: "50px 150px 100px 100px", borderRadius: '50px', border: '1px solid #01aed8', backgroundColor: 'white' }}>
                <Row style={{ marginBottom: '30px' }}>
                    <ShowIfTrue condition={this.state.detailsMode == DetailsMode.New} >
                        <h1 style={{ justifyContent: 'center', display: 'flex', fontSize: '40px' }}>Add User</h1>
                    </ShowIfTrue>

                    <ShowIfTrue condition={this.state.detailsMode == DetailsMode.Edit} >
                        <h1 style={{ justifyContent: 'center', display: 'flex', fontSize: '40px' }}>Edit User</h1>
                    </ShowIfTrue>
                </Row>
                <Row>
                    <Col>
                        <Form {...doubleColFormColSize} layout="horizontal" onSubmit={this.onSubmit}>
                            <Row>
                                <Col span={12} style={{ paddingRight: '30px' }}>
                                    <FormControl
                                        getFieldDecorator={getFieldDecorator}
                                        rules={[...ValidationUtility.required('first name is required')]}
                                        style={labelStyle}
                                        name="firstname"
                                        label="First Name">
                                        <Input />
                                    </FormControl>
                                </Col>
                                <Col span={12}>
                                    <FormControl
                                        getFieldDecorator={getFieldDecorator}
                                        rules={[...ValidationUtility.required('last name is required')]}
                                        style={labelStyle}
                                        name="lastname"
                                        label="Last Name">
                                        <Input />
                                    </FormControl>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12} style={{ paddingRight: '30px' }}>
                                    <FormControl
                                        getFieldDecorator={getFieldDecorator}
                                        rules={[
                                            ...ValidationUtility.required('Username is required')
                                        ]}
                                        style={labelStyle}
                                        name="userName"
                                        label="Username">
                                        <Input disabled={this.state.detailsMode == DetailsMode.Edit} autoComplete='off' />
                                    </FormControl>
                                </Col>
                                <Col span={12}>
                                    <FormControl
                                        getFieldDecorator={getFieldDecorator}
                                        rules={[...ValidationUtility.required('Role is required')]}
                                        style={labelStyle}
                                        name="roles"
                                        label="Role">
                                        <SimpleSelect options={this.state.roles}></SimpleSelect>
                                    </FormControl>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12} style={{ paddingRight: '30px' }}>
                                    <FormControl
                                        getFieldDecorator={getFieldDecorator}
                                        rules={[
                                            ...ValidationUtility.If(this.state.detailsMode == DetailsMode.New, [
                                                ...ValidationUtility.required('email is required'),
                                                ...ValidationUtility.email(),
                                            ]),
                                        ]}
                                        style={labelStyle}
                                        name="email"
                                        label="Email">
                                        <Input />
                                    </FormControl>
                                </Col>
                                <Col span={12}>
                                    <FormControl
                                        getFieldDecorator={getFieldDecorator}
                                        rules={[
                                            ...ValidationUtility.If(this.state.detailsMode == DetailsMode.New, [
                                                ...ValidationUtility.required('Confirm Email is required'),
                                                ...ValidationUtility.email(),
                                            ]),
                                        ]}
                                        style={labelStyle}
                                        name="confirmationEmailAddress"
                                        label="Confirm Email">
                                        <Input />
                                    </FormControl>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12} style={{ paddingRight: '30px' }}>
                                    <ShowIfTrue condition={this.state.detailsMode == DetailsMode.New} >
                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            rules={[
                                                ...ValidationUtility.If(this.state.detailsMode == DetailsMode.New, [
                                                    ...ValidationUtility.required('Password is required'),
                                                    ...ValidationUtility.password(),
                                                ]),
                                            ]}
                                            style={labelStyle}
                                            name="password"
                                            label="Password"
                                        >
                                            <Input.Password />
                                        </FormControl>
                                    </ShowIfTrue>
                                </Col>
                                <Col span={12}>
                                    <ShowIfTrue condition={this.state.detailsMode == DetailsMode.New} >
                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            rules={[
                                                ...ValidationUtility.If(this.state.detailsMode == DetailsMode.New, [
                                                    ...ValidationUtility.required('Confirm Password is required'),
                                                    ...ValidationUtility.password(),
                                                ]),
                                            ]}
                                            style={labelStyle}
                                            name="confirmationPassword"
                                            label="Confirm Password">
                                            <Input.Password autoComplete='off' />
                                        </FormControl>
                                    </ShowIfTrue>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={12} style={{ paddingRight: '30px' }}>
                                    <ShowIfTrue condition={this.state.detailsMode == DetailsMode.Edit} >
                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            rules={[...ValidationUtility.required('Status is required')]}
                                            style={labelStyle}
                                            name="status"
                                            label="Status">
                                            <SimpleSelect options={this.state.status}></SimpleSelect>
                                        </FormControl>
                                    </ShowIfTrue>
                                </Col>
                            </Row>

                            <CenterAlignedRow style={{ paddingTop: '30px' }}>
                                <Col span={10} offset={7}>
                                    <Button onClick={() => this.props.history.goBack()} type="danger"
                                        style={buttonStyle}
                                    >
                                        Back
                                    </Button>
                                    &nbsp;
                                    &nbsp;
                                    <Button type="primary" htmlType="submit"
                                        style={buttonStyle}
                                    >
                                        Submit
                                    </Button>
                                </Col>
                            </CenterAlignedRow>
                        </Form>
                    </Col>
                </Row>
            </Card>
        );
    }
}

const UserDetailsFormWithRouter = Form.create<Props>({
    name: 'UserDetails',
})(UserDetailsFormWithoutFormAndRouter);

const UserDetailsForm = withRouter(UserDetailsFormWithRouter);

export default UserDetailsForm;
