import * as React from 'react';
import styled from 'styled-components';
import { Layout, Row } from 'antd';

export const CenterAlignedRow = styled(Row)`
  text-align: center;
`;













