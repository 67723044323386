import * as React from 'react';

import { RouteComponentProps, NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { startCase } from 'lodash';
import { RightAlignedRow } from '../Styled/Layout/RightAlignedRow';
import TableFilterContextProvider from '../Forms/DataGrid/TableFilterContextProvider';
import { Avatar, Button, Card, Col, Icon, Input, Progress, Row } from 'antd';
import ISelectOption from '../Forms/SimpleSelect/ISelectOption';
import SimpleSelect from '../Forms/SimpleSelect';
import { Banner } from 'components/News/NewsStyled';


const { Search } = Input;



export interface Props extends RouteComponentProps {
    children?: React.ReactNode;
}

export interface State {
    board?: any[];

}

export class BoardOfDirectorsWithoutRouter extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        this.getTeam();
    }


    getTeam() {
        this.setState({
            board: [{ name: 'Professor Neville Ying CD, PHD', position: 'Chairman', img: '' },
                { name: 'Marlene Street Forrest. JP, CD', position: 'Managing Director (JSE)', img: '' },
                { name: 'Chairmaine Brimm', position: 'Technical Specialist', img: '' },
                { name: 'Courtney Campbell, JP, CD', position: 'Chairman (UCME)', img: '' },
                { name: 'Steven Gooden', position: 'Chief Executive Officer (NCB Capital Markets Limited)', img: '' },
                { name: 'Garth Kiddoe', position: 'Enterprise Consultant', img: '' },
                { name: 'Julian Mair', position: 'Group Chief Investment Strategist (JMMB)', img: '' },
                { name: 'Howard Mitchell, JP, CD', position: 'President (PSOJ)', img: '' },
                { name: 'Janet Morrison', position: 'Partner (Hart Muirhead Fatta)', img: '' },
                { name: 'Onyka Barrett Scott', position: 'General Manager (JN Foundation)', img: '' },
                { name: 'Omar Sweeney', position: 'Engineering Professional', img: '' },
                { name: 'Dennise Williams', position: 'Creative Director (Financially Focoused Media)', img: '' },
            ]
        });
    }

    render() {
        return (
            <div>
                <Banner className={"board"}>
                    <Row style={{ height: '100%' }}>
                        <Col>
                            <div>
                                <a className="backToHome" target="">Back to Home</a>
                                <Button className={'donateHeader'}>Donate</Button>
                                <Button className={'volunteer'}>Volunteer</Button>
                            </div>
                        </Col>
                    </Row>
                </Banner>
                <Row>
                    <Col style={{ paddingLeft: '140px', paddingTop: '117px', paddingRight: '137px' }} >
                        {this.state.board?.map(item => {
                            return <Col style={{ width: '365px', height: '550px', display: 'inline-Block' }}>
                                <Col style={{ backgroundColor: '#fff', width: '348px', height: '500px', paddingRight: '20px', paddingBottom: '20px' }}>
                                    <Col xs={{ span: 24 }}>
                                        <div > <Avatar style={{ width: '348px', height: '400px' }} src={item.img} shape="square" />
                                        </div>
                                    </Col>
                                    <Col xs={{ span: 24 }}>
                                        <p style={{ fontSize: '20px', paddingTop: '30px', lineHeight: '1px', textAlign: 'center' }} >{item.name}</p>
                                        <p style={{ fontSize: '15px', color: '#59ba56', textAlign: 'center' }}>{item.position}</p>
                                    </Col>
                                </Col>
                            </Col>
                        })}
                    </Col>
                </Row>
            </div>
        );
    }
}

const BoardOfDirectors = withRouter(BoardOfDirectorsWithoutRouter);

export default BoardOfDirectors;