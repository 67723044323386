import { Col, Row } from 'antd';
import * as React from 'react';
import { RouteProps } from 'react-router-dom';
import styled from 'styled-components';
import { FAQView } from 'components/Information/FAQView';
import { Redirect } from 'react-router';

export interface Props extends RouteProps {
  children?: React.ReactNode;
}

export interface State {
  isConfirmed: boolean;
}

const TallCol = styled(Col)`
  height: 100%;
  min-height: 200px;
`;

export default class AboutUsPage extends React.Component<Props, State> {
  
  constructor(props: Props) {
    super(props);
   

    this.state = { isConfirmed: false };
  }

  componentDidMount() {
  
  }

  render() {
       return <FAQView />;
  }
}










