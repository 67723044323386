import { SecuredClient } from 'Core/Api/SecuredClient';
import {
    ApproveProjectCommandRequest,
    ContactUsCommandRequest,
    DataFileType,
    FundingPurpose,
    FundingType,
    GetAllUsersByFilterModel,
    GetProjectByIdForAdminQueryRequest,
    GetProjectListByFilterForPublicRequest,
    GetProjectsByFilterForAdminQueryRequest,
    IApproveProjectCommandRequest,
    OrderBy, ProjectCategory, ProjectListModel, ProjectStatus, SlimUser, User,
} from 'Core/Api/Api';
import Utility from 'Core/Utility';
import ITableDataFetcher from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import ITableDataResponse from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';

export class ProjectsHandler implements ITableDataFetcher<ProjectListModel> {

    props: any;
    constructor(props) {
        this.props = props;
    }

    GetData(tableRequest: TableRequest): Promise<ITableDataResponse<ProjectListModel>> {
        return this.GetProjectsByFilterForAdmin(tableRequest);
    }

    GetProjectsByFilterForAdmin(tableRequest: TableRequest) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        const request = new GetProjectsByFilterForAdminQueryRequest(tableRequest);
        if (!request.amountPerPage) {
            request.amountPerPage = 12;

        }
        if (!request.orderBy || !request.orderBy.length) {
            request.orderBy = [];
            request.orderBy.push(new OrderBy({ field: 'Name', ascending: true }));
        }

        return client.getProjectsByFilterForAdmin(request);
    }

    GetProjectByIdForAdmin(id: string) {
        const client = new SecuredClient();
        client.showLoading = true;
        client.handleGeneralError = true;

        const request = new GetProjectByIdForAdminQueryRequest({ id: id });
        return client.getProjectByIdForAdmin(request);
    }

    GetProjectCategory() {
        return Utility.enumToSelectionOptionArray(ProjectCategory, true);
    }

    GetFundingPurpose() {
        return Utility.enumToSelectionOptionArray(FundingPurpose, true);
    }

    GetFundingType() {
        return Utility.enumToSelectionOptionArray(FundingType, true);
    }

    GetFileType() {
        return Utility.enumToSelectionOptionArray(DataFileType, true);
    }

    getProjectStatus() {
        return Utility.enumToSelectionOptionArray(ProjectStatus, true);
    }


    ApproveProject(model: IApproveProjectCommandRequest) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;
        const request = new ApproveProjectCommandRequest(model);

        return client.approveProject(request);
    }
}










