import * as React from 'react';
import { CenterAlignedRow } from 'components/Styled/Layout/CenterAlignedRow';
import { Col, Form, Input, Row, Button, Descriptions } from 'antd';
import FormControl from 'components/Forms/FormControl';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps, NavLink } from 'react-router-dom';
import ValidationUtility from 'Core/ValidationUtility';
import UserProfileHandler from '../UserProfileHandler';
import { IResetPasswordCommandRequest, IChangePasswordCommandRequest } from 'Core/Api/Api';
import FormItem from 'antd/lib/form/FormItem';
import NotificationService from 'Core/NotificationService';
import ConfigService from 'Core/ConfigService';
import Title from 'antd/lib/typography/Title';

export interface Props extends FormComponentProps, RouteComponentProps {
    children?: React.ReactNode;
}

export interface State {
    profile?: any
}

export class UserProfileFormWithoutFormsAndRouter extends React.Component<Props, State> {
    handler: UserProfileHandler;

    constructor(props: Props) {
        super(props);

        this.state = {};
        this.handler = new UserProfileHandler(props);
    }

    componentDidMount() {
        this.getProfileInfo();
    }

    getProfileInfo() {
        this.handler.GetMyProfile().then(
            x => {
                this.setState({ profile: x });
            },
            x => NotificationService.error(x.message)
        )
    }

    onSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        this.props.form.validateFields((error, values: IChangePasswordCommandRequest) => {
            if (error) {
                return;
            }

            this.handler.ChangePassword(values).then(() => {
                this.props.history.push('/login');
                NotificationService.success('Password has been updated', "", 10, true);
            });
        });
    };

    render() {
        var { getFieldDecorator } = this.props.form;
        var passwordValidations = ValidationUtility.password;
        var compareValidations = ValidationUtility.compare;
        return (
            <Row type="flex" justify="center">
                <Col>
                    <Row>
                        <Col span={24}>
                            <Row type="flex">
                                <Col style={{ paddingTop: "75px" }}>
                                    <Title>User Profile &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Title>
                                    <Descriptions bordered column={1} >
                                        <Descriptions.Item label="First Name" >
                                            <div style={{ fontWeight: 'bolder' }}>
                                                {this.state.profile?.firstName}
                                            </div>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Last Name" >
                                            <strong>{this.state.profile?.lastName}</strong>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Phone Number" >
                                            <strong>{this.state.profile?.phoneNumber}</strong>
                                        </Descriptions.Item>
                                    </Descriptions>

                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <NavLink to="/edit-user-profile">Edit User Profile</NavLink>
                        </Col>
                        <Col>
                            <NavLink to="/change-password">Change Password</NavLink>
                        </Col>
                        <Col>
                            <NavLink to="/update-security">Update Security Questions</NavLink>
                        </Col>
                    </Row>
                </Col>

            </Row>
        );
    }
}

const UserProfileFormWithoutRouter = Form.create<Props>({
    name: 'UserProfileForm',
})(UserProfileFormWithoutFormsAndRouter);

const UserProfileForm = withRouter(UserProfileFormWithoutRouter);

export default UserProfileForm;










