import * as React from 'react';
import { Row, Col, Button, Tabs, Input, Card, Divider, Avatar, Carousel } from 'antd';
import TableFilterContextProvider from 'components/Forms/DataGrid/TableFilterContextProvider';
import { DashboardViewCard } from '.';
import { RouteComponentProps, NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import { BannerDiv, NewsClasses, DashboardTitle, STabCardDiv, WhoWeAreClasses, LessonsLearntClasses, EndorsementClasses, ProjectClasses, Page } from './DashboardView.Style';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import Title from 'antd/lib/typography/Title';
import Search from 'antd/lib/input/Search';
import { BrandedButton } from '../../Styled/Branded/BrandedButton'
import Background1 from 'assets/images/background1.png';
import World from 'assets/images/world.png';

import Group_3649 from 'assets/images/Group_3649.png';
import Group_3650 from 'assets/images/Group_3650.png';
import Group_3651 from 'assets/images/Group_3651.png';
import Group_3652 from 'assets/images/Group_3652.png';
import Group_3653 from 'assets/images/Group_3653.png';
import LogoImg from '../../../assets/images/dashboard-logo.png';
import { CenterAlignedRow } from '../../Styled/Layout/CenterAlignedRow';
import NumberFormat from 'react-number-format';
import { startCase } from 'lodash';
import moment from 'moment';
import { RightAlignedRow } from '../../Styled/Layout/RightAlignedRow';

const { TabPane } = Tabs;

export interface Props extends RouteComponentProps {
    children?: React.ReactNode;
}

export interface State {
    news?: any[];
    projects?: any[];
    endorsements?: any[];
    lessonsLearnt?: any[];
    totalSupporters?: string;
    totalDonations?: string;
    lastDonation?: string;

}

export class DashboardViewWithoutRouter extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        this.getNews();
        this.getProjects();
        this.getEndorsements();
        this.getLessons();
    }

    getNews() {
        this.setState({
            news: [{ title: 'Acrecent paves the way for regional impact investing', url: "#" },
            { title: 'Social development expert: Caribbean must redefine climate justice', url: "#" },
            { title: 'JSSE Salutes Dr. Trisha Gayle for her philanthropy in the Social Sector', url: "#" },
            { title: 'PIOJ Best Practices Symposium', url: "#" },
            { title: 'The Jamaica Stock Exchange & You podcast is now available', url: "#" },
            { title: 'The Jamaica Stock Exchange & You podcast is now available', url: "#" },
            { title: 'The Jamaica Stock Exchange & You podcast is now available', url: "#" },
            ]
        });
    }

    getLessons() {
        this.setState({
            lessonsLearnt: [{ text: 'The legal definition of a charitable organization (and of charity) varies between countries and in some instances regions of the country. The regulation, the tax treatment.', from: "Jonathan Richards", occupation: "Zion Eye Media", img: '#' },
            { text: 'The legal definition of a charitable organization (and of charity) varies between countries and in some instances regions of the country. The regulation, the tax treatment.', from: "Jonathan Richards", occupation: "Zion Eye Media", img: '#' },
            { text: 'The legal definition of a charitable organization (and of charity) varies between countries and in some instances regions of the country. The regulation, the tax treatment.', from: "Jonathan Richards", occupation: "Zion Eye Media", img: '#' },
            { text: 'The legal definition of a charitable organization (and of charity) varies between countries and in some instances regions of the country. The regulation, the tax treatment.', from: "Jonathan Richards", occupation: "Zion Eye Media", img: '#' },
            ]
        });
    }

    getEndorsements() {
        this.setState({

            endorsements: [{
                img: '#', text1: 'The United Way of Jamaica congratulates the Jamaica Stock Exchange on its forward-thinking thrust to introduce a Social Stock Exchange. Mobilizing capital for social good is something the United Way of Jamaica has been doing successfully for the past thirty-three years. We have systems in place for this and are delighted to partner with the Jamaica Social Stock Exchange (SSE) to ensure its realization and success.',
                text2: 'By partnering with the JSSE, the United Way of Jamaica will help to ensure that the creation of this new facility, designed to drive social enterprises and deliver social benefits, will positively impact the lives of everyday Jamaicans, build stronger communities and a stronger, more equitable Jamaica.'
            },
            {
                img: '#', text1: 'The United Way of Jamaica congratulates the Jamaica Stock Exchange on its forward-thinking thrust to introduce a Social Stock Exchange. Mobilizing capital for social good is something the United Way of Jamaica has been doing successfully for the past thirty-three years. We have systems in place for this and are delighted to partner with the Jamaica Social Stock Exchange (SSE) to ensure its realization and success.',
                text2: 'By partnering with the JSSE, the United Way of Jamaica will help to ensure that the creation of this new facility, designed to drive social enterprises and deliver social benefits, will positively impact the lives of everyday Jamaicans, build stronger communities and a stronger, more equitable Jamaica.'
            },
            {
                img: '#', text1: 'The United Way of Jamaica congratulates the Jamaica Stock Exchange on its forward-thinking thrust to introduce a Social Stock Exchange. Mobilizing capital for social good is something the United Way of Jamaica has been doing successfully for the past thirty-three years. We have systems in place for this and are delighted to partner with the Jamaica Social Stock Exchange (SSE) to ensure its realization and success.',
                text2: 'By partnering with the JSSE, the United Way of Jamaica will help to ensure that the creation of this new facility, designed to drive social enterprises and deliver social benefits, will positively impact the lives of everyday Jamaicans, build stronger communities and a stronger, more equitable Jamaica.'
            },
            {
                img: '#', text1: 'The United Way of Jamaica congratulates the Jamaica Stock Exchange on its forward-thinking thrust to introduce a Social Stock Exchange. Mobilizing capital for social good is something the United Way of Jamaica has been doing successfully for the past thirty-three years. We have systems in place for this and are delighted to partner with the Jamaica Social Stock Exchange (SSE) to ensure its realization and success.',
                text2: 'By partnering with the JSSE, the United Way of Jamaica will help to ensure that the creation of this new facility, designed to drive social enterprises and deliver social benefits, will positively impact the lives of everyday Jamaicans, build stronger communities and a stronger, more equitable Jamaica.'
            }
            ]
        });
    }

    getProjects() {
        this.setState({
            projects: [{ img: "#", title: 'Mustard Seed Communities', subtitle: 'Mustard Seed Communities', raised: 1900750.00, goal: 6000000.00 },
            { img: "#", title: 'Mona-Tech Engineering Support', subtitle: 'Mona-Tech Engineering Services Limited', raised: 3509200.00, goal: 3509200.00 },
            { img: "#", title: 'Connect A Child JA', subtitle: 'One Laptop Or Tablet Per Child Initiative', raised: 2139792.50, goal: 200000000.00 },
            { img: "#", title: 'Mona-Tech Engineering Support', subtitle: 'Mona-Tech Engineering Services Limited', raised: 3509200.00, goal: 3509200.00 },

            ],
            totalDonations: '$100M',
            totalSupporters: '30M',
            lastDonation: '$5M'
        });
    }

    render() {
        return (
            <Page >
                <h1>JSSE Admin Portal</h1>
            </Page>
        );
    }
}

const DashboardView = withRouter(DashboardViewWithoutRouter);

export default DashboardView;











