import * as React from 'react';
import TableFilterContextProvider from 'components/Forms/DataGrid/TableFilterContextProvider';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { Col, Button, Row } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import AdminProjectUpdateListTable from './AdminProjectUpdateListTable/AdminProjectUpdateListTable';
import AdminProjectUpdateListFilterBar from './AdminProjectUpdateListFilterBar/AdminProjectUpdateListFilterBar';
import { ProjectUpdateDetailsSidebar } from '../ProjectUpdateDetailsSidebar';
import { useHistory, useParams } from 'react-router';
import { useEffect, useState } from 'react';

export interface Props {
  children?: React.ReactNode;
}

export interface State {
  showDetailsSidebar?: boolean;
}

export const ProjectUpdateListView = ({ }: Props) => {

  const [selectedProjectUpdateId, setSelectedProjectUpdateId] = useState<string | undefined>(undefined);
  const [showDetailsSidebar, setShowDetailsSidebar] = useState(false);
  const handleDetailsSubmitted = () => { };
  const params = useParams<{ id }>();
  const history = useHistory();


  // useEffect(() => {
  //   if (params.id) {
  //     setShowDetailsSidebar(true);
  //     setSelectedProjectUpdateId(params.id);
  //   }
  // }, [params])


  return (
    <TableFilterContextProvider>
      <Row>
        <Col md={24}>
          <AdminProjectUpdateListFilterBar />
        </Col>
      </Row>
      <Row>
        <Col md={24}>
          <AdminProjectUpdateListTable />
        </Col>
      </Row>
      <ProjectUpdateDetailsSidebar
        onClose={x => history.goBack()}
        onSubmitted={handleDetailsSubmitted}
        projectUpdateId={params?.id}
        visible={!!params?.id} />
    </TableFilterContextProvider>
  );
}

export default ProjectUpdateListView;


