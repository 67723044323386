import { useEffect } from 'react';
import * as React from 'react';
import { Button, Col, Form, Input, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { fullWidthSingleColFormColSize, FullWithButton } from 'components/Forms/Form.Styled';
import FormControl from 'components/Forms/FormControl';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import ValidationUtility from 'Core/ValidationUtility';
import { useHistory } from 'react-router-dom';
import { NewsHandler } from '../NewsHandler';
import NotificationService from 'Core/NotificationService';
import { FormComponentProps } from 'antd/lib/form';
import { INewsModel } from 'Core/Api/Api';
import styled from 'styled-components';
import { RichTextEditor } from 'components/Forms/RichTextEditor';
import { Base64FileUploader } from 'components/Forms/Base64FileUploader';



export interface AddNewsFormProps extends FormComponentProps<INewsModel> {
    children?: React.ReactNode;
}

export const ButtonGroup = styled.div`
  
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(100px,200px));
    gap: 5px;
  `;

export function AddNewsForm({ children, form }: AddNewsFormProps) {

    let getFieldDecorator = form?.getFieldDecorator;
    let history = useHistory();
    let newsHandler = new NewsHandler({});
    const [nameFieldIsDirty, setNameFieldIsDirty] = React.useState<boolean>(false);
    useEffect(() => { }, []);

    const onSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        form?.validateFieldsAndScroll((errors, values) => {
            if (errors != null) { return; }

            newsHandler.AddNews(values).then(response => {
                history.goBack();
                NotificationService.success(`Added ${response.news?.name}`);
            });
        });
    };

    const setNameFieldIfNotTouch = (e: React.SyntheticEvent) => {
        console.log("in");
        if (!nameFieldIsDirty) {
            form.setFieldsValue({ "name": form.getFieldValue("title") });
        }
    };

    const checkIfNameFieldIsDirty = (e: React.SyntheticEvent) => {
        if (form.isFieldTouched("name")) {
            setNameFieldIsDirty(true);
        }
    };

    const onNewsImageLinkChange = () => {
        if (form.getFieldValue("newsPicture") != null) {
            form.setFieldsValue({ "newsPicture": null });
        }

    }
    const onNewsImageChange = () => {
        if (form.getFieldValue("mainImageLink") != null) {
            form.setFieldsValue({ "mainImageLink": null });
        }
    }



    return (
        <LeftAlignedRow>
            <Col>
                <Form {...fullWidthSingleColFormColSize} layout="horizontal" onSubmit={onSubmit}>
                    <LeftAlignedRow type='flex' justify='start'>
                        <Col span={20}>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                rules={[...ValidationUtility.required('Title is required')]}
                                name="title"
                                label="Title">
                                <Input size='large' onKeyUp={setNameFieldIfNotTouch} />
                            </FormControl>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                rules={[...ValidationUtility.required('Name is required')]}
                                name="name"
                                labelAlign='left'
                                label="Name">
                                <Input size='small' onChange={checkIfNameFieldIsDirty} />
                            </FormControl>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                name="mainImageLink"
                                label="Main Image Link">
                                <Input onChange={() => {onNewsImageLinkChange()}} />
                            </FormControl>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                name="ignoreOrText"
                                label=" "
                                colon={false}>
                                <Typography.Text>Or</Typography.Text>
                            </FormControl>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                name="newsPicture"
                                // rules={[...ValidationUtility.Base64ImageHasValidImageSizes([{ width: 300, height: 300 }], "Image size is not correct")]}
                                // help={"Image must be 300x300 pixels"}
                                // validateTrigger="onChange"
                                label="Main Image">
                                <Base64FileUploader accept='image/*' previewSize={{ width: "200", height: "150" }} onChange={() => {onNewsImageChange()}} />
                            </FormControl>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                rules={[...ValidationUtility.required('Overview is required')]}
                                name="overview"
                                label="Overview">
                                <TextArea rows={2} />
                            </FormControl>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                name="description"
                                rules={
                                    [
                                        {
                                            transform: ValidationUtility.TransformEditorState
                                        },
                                    ]
                                }
                                validateTrigger="onBlur"
                                label="Body">
                                <RichTextEditor />
                            </FormControl>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                name="link"
                                label="Link">
                                <Input />
                            </FormControl>
                        </Col>
                    </LeftAlignedRow>


                    <LeftAlignedRow>
                        <Col span={20}>
                            <Form.Item
                                colon={false}>
                                <ButtonGroup>
                                    <Button onClick={() => history.goBack()} type="default">
                                        Back
                                    </Button>
                                    <FullWithButton type="primary" htmlType="submit">
                                        Submit
                                    </FullWithButton>
                                </ButtonGroup>
                            </Form.Item>
                        </Col>
                    </LeftAlignedRow>
                </Form>
            </Col>
        </LeftAlignedRow>
    );
}

export default Form.create({ name: "add-news-form" })(AddNewsForm);