import { Col, Row } from 'antd';
import * as React from 'react';
import { RouteProps } from 'react-router-dom';
import styled from 'styled-components';
import RoleDetailsForm from 'components/Admin/RoleDetailsForm/RoleDetailsForm';
import { Redirect } from 'react-router';
import { BannerDiv, CenteredFormContent } from 'components/Layout/LoginLayout/LoginLayout.Style';
import { PageTitle } from 'components/Layout/PageTitle';

export interface Props extends RouteProps {
    children?: React.ReactNode;
}

export interface State { }

export default class RolesDetailsPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);


        this.state = {};
    }

    componentDidMount() {

    }

    render() {
        return <CenteredFormContent>
            <BannerDiv className='admin'>
                <PageTitle title={"Role"} />
            </BannerDiv>
            <Row type="flex" align="middle" justify="center">
                <Col span={21} offset={1}>
                    <RoleDetailsForm />
                </Col>
            </Row>

        </CenteredFormContent>;
    }
}
