import { Row, Col, Button } from "antd";
import { CenteredFormContent, BannerDiv } from "components/Layout/LoginLayout/LoginLayout.Style";
import { PageTitle } from "components/Layout/PageTitle";
import NewsList from "components/NewsManagement/NewsList/NewsList";
import * as React from "react";

export interface Props {
    children?: React.ReactNode
}

export interface State {
}

export default class NewsListPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
        }
    }

    render() {
        return (
            <CenteredFormContent>
                <BannerDiv className='admin'>
                    <PageTitle title={"News"} />
                </BannerDiv>
                <Row type="flex" align="middle" justify="center">
                    <Col span={21} offset={1}>
                        <NewsList />
                    </Col>
                </Row>

            </CenteredFormContent>
        )
    }
}
