import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { FundingType, ProjectListModel } from 'Core/Api/Api';
import SimpleTable from 'components/Forms/DataGrid/SimpleTable';

import { NavLink } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import DetailsMode from 'Core/models/DetailsMode';
import DetailsData from 'Core/models/DetailsData';
import { ProjectsHandler } from 'components/ProjectManagement/ProjectsHandler';
import { startCase } from 'lodash';
import NumberFormat from 'react-number-format';
import { Icon } from 'antd';

export interface Props {
    children?: React.ReactNode;
}

export interface State {
    countries: IFilterSelectOption[];
    jurisdictions: IFilterSelectOption[];
    roles: IFilterSelectOption[];
}

export default class AdminProjectListTable extends React.Component<Props, State> {
    handler: ProjectsHandler;

    constructor(props: Props) {
        super(props);

        this.state = {
            countries: [],
            jurisdictions: [],
            roles: [],
        };
        this.handler = new ProjectsHandler(props);
    }

    componentDidMount() {

    }

    TypeOfFundingText = (props: { typeOfFunding?: FundingType }) => {
        if (props.typeOfFunding === undefined) return <></>;
        return props.typeOfFunding === FundingType.ImpactInvestment ? <>Equity Investment (JIIM)</> :
            <>Donations (JSIM)</>;
    }

    rolesListTableColumns: Array<ColumnProps<ProjectListModel>> = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: 'Company',
            dataIndex: 'company.name',
            sorter: true,
            render: (text: any, record: ProjectListModel, index: number) => {
                return record.companyName;
            }

        },
        {
            title: 'Goal (JMD)',
            dataIndex: 'project',
            render: (text: any, record: ProjectListModel, index: number) => {
                return <NumberFormat value={record.amountReceived} prefix="$" thousandSeparator="," displayType='text' />;
            },
        },
        {
            title: 'Market',
            dataIndex: 'typeOfFunding',
            render: (text: any, record: ProjectListModel, index: number) => {
                return <this.TypeOfFundingText typeOfFunding={record.typeOfFunding} />;
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text: any, record: ProjectListModel, index: number) => {
                return startCase(text);
            },
        },
        {
            title: '',
            render: (text: any, record: ProjectListModel, index: number) => {
                return (
                    <NavLink
                        to={{
                            pathname: 'projects/' + encodeURIComponent(record.id ?? ''),
                            state: new DetailsData(DetailsMode.Edit, record.id),
                        }}>
                        <Icon type="read" title="View" />
                    </NavLink>
                );
            },
        },
    ];

    render() {
        return (
            <SimpleTable<ProjectListModel>
                tableKey="id"
                dataFetcher={this.handler}
                columns={this.rolesListTableColumns}
            />
        );
    }
}



