import * as React from 'react';
import { Layout, Row } from 'antd';
import styled from 'styled-components';
import ScreenHelper from '../../Styled/ScreenHelper';
import logoSvg from '../../../assets/images/logo.png';
import ContactUsImg from 'assets/images/Group_3579.png';
import ProjectListImg from 'assets/images/projectsBanner.png';

const { Header, Content, Footer } = Layout;

export const CenteredContent = styled(Content)`
  height: 100%;
  min-height: 60vh;
  margin-bottom:50px;

  ${ScreenHelper.down('md')} {
    padding-top: 1vh;
  }

  ${ScreenHelper.up('md')} {
    padding-top: 15vh;
  }
`;

export const CenteredFormContent = styled(Content)`
  height: 100%;
  min-height: 60vh;
  margin-bottom:170px;
 

  //${ScreenHelper.down('md')} {
  //  padding-top: 1vh;
  //}

  //${ScreenHelper.up('md')} {
  //  padding-top: 15vh;
  //}
`;

export const BannerDiv = styled.div`
    height: 500px;    
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    margin-bottom: 70px;

    &.contact-us {
        background-image: url(${ContactUsImg});
    }

    &.admin {
        height: 60px;
    }

    &.project-list {
        background-image: url(${ProjectListImg});
        height: 260px;

        .donateHeader {
                position: absolute;
                width: 102px;
                height: 32px;
                background-color: #e36e19;
                display: inline-block;
                color: #ffff;
                right: 184px;
                top: 50px;
            }

        .volunteer {
                position: absolute;
                 width: 116px;
                height: 32px;
                background-color: #269b48;
                color: #ffff;
                right: 42px;
                top: 50px;
            }

        .backToHome {
                position: absolute;
                color: #ffff;
                 left: 42px;
                top: 50px;
            }
    }

@media only screen and (min-width: 992px) {
    /*height: 50em;*/
    /* width: 80%; */
    //margin-left: 160px;
    //margin-right: 160px;
}
`;

export const ForgetPasswordWithBackground = styled(Layout)`
    
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-height:100vh;
`;


export const LoginWithBackground = styled(Layout)`
    
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    min-height:100vh;
`;

export const LoginContent = styled.div`
  background-color: white;
  padding: 20px 40px 10px 40px;
`;

export const MainLogo = styled.div`
  height: 9em;
  /*width: 21em;*/

  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  border: none;
  background: url(${logoSvg});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  ${ScreenHelper.down('sm')} {
    height: 8em;
    width: 10em;
  }
`;

