import * as React from 'react';
import { Layout, Row } from 'antd';
import styled from 'styled-components';
import BannerImg from '../../../assets/images/Banner.png';
import ScreenHelper from '../../Styled/ScreenHelper';
import logoSvg from 'assets/images/logo.png';
import loginBackground from 'assets/images/background_ologin.jpg';
import Title from 'antd/lib/typography/Title';
import Background1 from 'assets/images/background1.png';
import ad1 from 'assets/images/ad.png';
import lessonsLearntImage from 'assets/images/lessonslearnt.jpg';
import socialPartners from 'assets/images/socialPartners.png';

const { Header, Content, Footer } = Layout;

export const Page = styled.div`
    .sectionTitle{
        font-family: HelveticaNeueLTStd-57;
        font-size: 48px;
        font-weight: 700;
        font-stretch: condensed;
        font-style: normal;
        line-height: 0.79;
        letter-spacing: normal;
        display: flex;
        align-items: center;
        justify-items: center;
    }   

    .viewAll{
        background-color: #00aed8;
        left 40vw;
        margin-top: -2vh;
        a{
            color: #ffff;
        }
    }
    .socialPartners{
        text-align: center;
        padding-top: 56px;
    }
    .partnersContainer{
        padding-left: 132px;
        height: 81px;
        max-width:96%;
    }
    .partners{
        background-image: url(${socialPartners});
        height: 81px;
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);

    }
`;

export const BannerDiv = styled.div`
    height: 100%;
    
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    padding-bottom: 2vh;

@media only screen and (min-width: 992px) {
    height: 100%;
    /* width: 80%; */
    //margin-left: 160px;
    //margin-right: 160px;
}
    /*margin-bottom: 50px;*/

  /*width: 21em;*/
`;

export const DashboardTitle = styled.span`
    .dashboard-logo{
        width: 100%;

@media only screen and (max-width: 992px) {
    margin-bottom: 17.5em !important;
}
    }

 
`;

export const NewsClasses = styled.div`
    .center-avatar {
        text-align: center;

        @media only screen and (min-width: 574px) {
            padding-right: 24px;
        }
    }

    .offering-status {
        width: 98px;
        height: 30px;
        border-radius: 70px;

        &.Closed {
            border: solid 1px #e28080;
            text-align: center;
            background-color: #fcf2f2;
            font-weight: bold;
            color: #c40002;
            line-height: 1.71;
        }

        &.Opened {
            border: solid 1px #78c487;
            background-color: #f1f9f2;
            text-align: center;
            color: #128710;
            font-weight: bold;
            line-height: 1.71;        
        }

        &.PreOpen {
            border: solid 1px #80bae2;
            background-color: #f2f8fc;
            text-align: center;
            color: #1174c4;
            font-weight: bold;
            line-height: 1.71;        
        }


        &.Suspended {
            border: solid 1px #d7da17;
            background-color: #fcffcb;
            text-align: center;
            color: #c2c500;
            font-weight: bold;
            line-height: 1.71;
        }
    }
    

    .newsItems{
        max-height: 164.25px;
        overflow: hidden;
        max-width: 100vw;
        padding-left: 1vw;
        background-color: #ffff;
        
    }

    .innerNewsItems{
        -ms-overflow-style: none;
        width: 100%;
        overflow-x: scroll;
        box-sizing: content-box;
        display: inline-flex;
        -ms-overflow-style: none;
        scrollbar-width: none;

        ::-webkit-scrollbar {
            display:none;
        }
    }


    .cardItems{
        max-height: 130px;
        max-width: 23em;
        min-width: 23em;
        width: 23em;
        background-color: #ffff;
        padding: 8px;
        margin-bottom: 17px;
    }
    .item{
        width: 100%;
        height: 12.46vh;
        background-color: #ffff;
        padding-bottom: 14vh;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
    .cardImage{
        width: 6.68vw;
        height: 8.45vh;
    }

    .cardInformation{
        font-size: 14px;
        max-height: 60px;
        overflow: hidden;
        padding-bottom: 70px;
        
        .info{
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .cardDate{
        line-height: 24px;
        font-size: 12px;
        color: #272364;
    }

    .readMore{
        width: 64px;
        height: 24px;
        background-color: #1d95d3;
        font-size: 11px;
        padding: 0px;
        color: #ffff;
        position: absolute;
        right: -1.5vw;
    }
    .cardRight{
        padding: 0px!important;
    }
    .buttonBox{
        position: relative;
    }
    


@media only screen and (max-width: 574px) {
    .xs-no-padding {
        padding-left: 10px!important;
        padding-right: 10px!important;
    }

}

padding-bottom:3vh;
    
`;



export const WhoWeAreClasses = styled.div`
    .background-image {
        height: 100%; 
       background-image: url(${Background1});
        padding-left: 103px;
        padding-top: 66px;
    }

    .who-we-are-text{
        max-width: 568px;
    }
    
`;

export const ProjectClasses = styled.div`

padding-left: 4vw;
padding-top: 36px;
padding-bottom:3vh;
max-width: 90vw;

.projects{
    padding-left: 1vw;
}

.ant-avatar{
    width: 270px;
    height: 230px;
}


.innerProject{
        -ms-overflow-style: none;
        width: 100%;
        overflow-x: scroll;
        box-sizing: content-box;
        display: inline-flex;
        -ms-overflow-style: none;
        scrollbar-width: none;
::-webkit-scrollbar {
            display:none;
        }
}

.projectItems{
    padding-right: 5vw;
    max-width: 23em;
        min-width: 23em;
}

.ant-card-body{
    padding-top: 15px;
}

.projectItem{
    width: 320px;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(39, 35, 100, 0) 28%, rgba(32, 29, 82, 0.31) 45%, #000 83%);
}

.projectTrackerBody{
    height: 321px;
    background-color: #dfebef!important;
    width: 470px;
    padding: 20px;

    .title{
        font-family: HelveticaNeueLTStd-57;
        font-size: 22px;
        font-stretch: condensed;
        color: #000;
        text-align: left;
    }

    .icon{

    }
    
    .amount{
        font-family: HelveticaLTStd-Compressed;
        font-size: 40px;
        font-weight: bolder;
        text-align: center;
        color: #1d95d3;
    }

    .hr{
        background-color: #00aed8;
        height: 3px;
    }
}

.projectTitle{
    padding-top: 1vh;
    line-height: 0.2vh;
}

.projectSubtitle{
    font-size: 12px;
    line-height: 0.2vh;
    font-style: italic;
}

.projectFooter{
    color: #ffff;
}

.green{
    color: #7bc257;
}

.goal{
    font-size: 14px;
}

.viewAllCauses{
    width: 152px;
    height: 33px;
    background-color: #00aed8;
    position: absolute;
    right: 10px;
    a{
        color: #ffff!important;
    }
}

`;

export const EndorsementClasses = styled.div`
padding-top: 82px;

    .endorsements{
        background-color: #1e1e1e;
        padding-top: 30px;
        padding-left: 140px;
        padding-right: 76px;
        padding-bottom: 66px;
        height: 636px;

    }
    .endorsementText{
        color: #ffff;
        font-size: 15px;
    }
    .ad{
        background-image: url(${ad1});
        width: 50%;
        height: 636px;
    }
    
    

`;

export const LessonsLearntClasses = styled.div`
padding-top: 82px;

    .ad{
        background-image: url(${lessonsLearntImage});
        width: 50%;
        height: 460px;
    } 
    .lessonsLearnt{
        text-align: center;
        background-color: #ffff;
        height: 460px;
        padding-top: 40px;
    }
    
    .lessonsItem{
        padding-bottom: 40px;
    }
    .whoAvatar{
        right: 82%;
    }
    .lessonsText{
        padding-left: 133px;
        padding-right: 133px;
        padding-top: 25px;
        font-size: 21px;
        font-style: italic;
    }
    .whoFrom{
        font-weight: bold;
    }
    .who{
        font-size: 14px;
    }
    .whoDetails{
        text-align: left;
        line-height: 5px;
        padding-top: 20px;
    }

`;


export const STabCardDiv = styled.div`


    .ant-tabs-card > .ant-tabs-content {
  margin-top: -16px;
}

.ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  /*border-color: transparent;*/
  /*background: transparent;*/
}

.ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
`;














