import { Button, Descriptions, Divider, Drawer } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import { DocumentItemDisplay } from 'components/Forms/FilesViewer/DocumentItemDisplay';
import { FullWithButton } from 'components/Forms/Form.Styled';
import { ProjectUpdatesHandler } from 'components/ProjectUpdateManagement/ProjectUpdatesHandler';
import ButtonGroup from 'components/Styled/ButtonGroup';

import { IApproveProjectUpdateCommandRequest, IDocumentModel, ProjectUpdate, ProjectUpdateStatus } from 'Core/Api/Api';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { ProjectUpdateApprovalSidebar } from './ProjectUpdateApprovalSidebar';
import ShowIfTrue from 'components/Forms/ShowIfTrue/ShowIfTrue';
import NumberFormat from 'react-number-format';
import moment from 'moment';

export const ActionsFooter = styled.div`
  display: flex;
  margin: auto -24px;
  position: sticky;
  bottom: 0px;
  border-top: 1px solid black;
  background-color: white;
  min-height: 50px;
  padding-top: 20px;
  justify-items: center;


`;

interface Props extends FormComponentProps<IApproveProjectUpdateCommandRequest> {
  projectUpdateId?: string;
  visible: boolean;
  onSubmitted: Function;
  onClose?: (e: SyntheticEvent) => void;
}

export const ProjectUpdateDetailsSidebarWithoutForms = ({ projectUpdateId, form, onSubmitted, visible, onClose }: Props) => {

  let { getFieldDecorator } = form;
  let projectUpdatesHandler = new ProjectUpdatesHandler({});
  let history = useHistory();
  const [projectUpdate, setProjectUpdate] = useState<ProjectUpdate | undefined>(undefined);
  const [showProjectUpdateApprovalDrawer, setShowProjectUpdateApprovalDrawer] = useState(false);
  const [formattedProjectDocument, setformattedProjectDocument] = useState<IDocumentModel[] | undefined>();

  useEffect(() => {
    if (projectUpdateId) {
      projectUpdatesHandler.GetProjectUpdateByIdForAdmin(projectUpdateId).then(x => {
        setProjectUpdate(x.projectUpdate);

        const projectDocuments: IDocumentModel[] = [];

        x.projectUpdate?.projectDocuments?.forEach(document => {
          const documentModel: IDocumentModel = {};
          if (document?.document) {
            documentModel.content = { name: document?.document?.name, url: document?.document?.url } as any;
          }
          documentModel.name = document?.document?.name;
          documentModel.fileType = document?.document?.type;
          documentModel.description = document?.description;
          documentModel.url = document.videoUrl;
          documentModel.id = document.id;
          documentModel.removed = false;
          documentModel.toBeRemoved = document.toBeRemoved;
          projectDocuments.push(documentModel);

        });
        setformattedProjectDocument(projectDocuments);
      })
    }
  }, [projectUpdateId]);

  const handleProjectUpdateApprovalSubmitted = () => {
    setShowProjectUpdateApprovalDrawer(false);
    onSubmitted?.();
  }

  const GetFileNameFromApiUrl = (url: String) => {
    return url.substring(url.lastIndexOf("/") + 1);
  }

  return <Drawer
    title={`Project Update For ${projectUpdate?.project?.name}`}
    width={520}
    onClose={onClose}
    visible={visible}
    bodyStyle={{ paddingBottom: 80 }}
  >
    <Descriptions column={1}>
      <Descriptions.Item label="Project">{projectUpdate?.project?.name}</Descriptions.Item>
      <Descriptions.Item label="Company">{projectUpdate?.project?.company?.name}</Descriptions.Item>
      <Divider />
      <Descriptions.Item><p style={{ fontWeight: 'bold', fontSize: '20px', textDecoration: 'underline' }}>{"Updates"}</p></Descriptions.Item>

      <Descriptions.Item label="Title">{projectUpdate?.name}</Descriptions.Item>
      <Descriptions.Item label="Subtitle">{projectUpdate?.subTitle}</Descriptions.Item>
      <Descriptions.Item label="Category">{projectUpdate?.category}</Descriptions.Item>
      <Descriptions.Item label="Time to Fund">{moment(projectUpdate?.timeToFunding).format('DD-MM-YYYY')}</Descriptions.Item>
      <Descriptions.Item label="Theory of Change">{projectUpdate?.description?.replace( /(<([^>]+)>)/ig, '')}</Descriptions.Item>
      <Descriptions.Item label="Project Life Cycle Stage">{projectUpdate?.fundingPurpose}</Descriptions.Item>
      <Descriptions.Item label="Market">{projectUpdate?.typeOfFunding}</Descriptions.Item>
      <Descriptions.Item label="Target Amount"><NumberFormat thousandSeparator=',' displayType='text' value={projectUpdate?.amountRequested} /></Descriptions.Item>
      <Descriptions.Item label="Video Url">{projectUpdate?.videoUrl !== null ? <text><a href={projectUpdate?.videoUrl} target='_blank'>Link</a></text> : ''}</Descriptions.Item>
      <Descriptions.Item label="Thumbnail">{projectUpdate?.thumbnailFileUrl && <img src={projectUpdate?.thumbnailFileUrl} width="100" height="100" />}</Descriptions.Item>
      <Descriptions.Item label="Dashboard Image">{projectUpdate?.projectDashboardFileUrl && <img src={projectUpdate?.projectDashboardFileUrl} width="100" height="100" />}</Descriptions.Item>
      <Descriptions.Item label="Project Document">{projectUpdate?.projectDocumentFileUrl && <a href={projectUpdate?.projectDocumentFileUrl} target="_blank">{GetFileNameFromApiUrl(projectUpdate?.projectDocumentFileUrl)}</a>}</Descriptions.Item>
      <Descriptions.Item label="Reason For the Update">{projectUpdate?.reason}</Descriptions.Item>
      <Descriptions.Item label="Additional Documents">
        {formattedProjectDocument?.map(document => (
          <div  style={document.toBeRemoved ? { textDecoration:'line-through'} : {}}>
            <DocumentItemDisplay document={document} readonly={true} ></DocumentItemDisplay>
          </div>
        ))}
      </Descriptions.Item>
    </Descriptions>
    <ActionsFooter>
      <ButtonGroup style={{ width: "100%", justifyContent: "center" }}>
        <Button onClick={x => onClose?.(x)} type="default">
          Back
        </Button>
        <ShowIfTrue condition={ projectUpdate?.status === ProjectUpdateStatus.Submitted || projectUpdate?.status === ProjectUpdateStatus.FirstApproved }>
          <FullWithButton
            onClick={x => setShowProjectUpdateApprovalDrawer(true)}
            type="primary"
            htmlType="submit">
            Approve / Need Review
          </FullWithButton>
        </ShowIfTrue>
      </ButtonGroup>
    </ActionsFooter>
    <ProjectUpdateApprovalSidebar
      visible={showProjectUpdateApprovalDrawer}
      onSubmitted={handleProjectUpdateApprovalSubmitted}
      onClose={x => setShowProjectUpdateApprovalDrawer(false)}
      projectUpdate={projectUpdate} />
  </Drawer>;
}

export default Form.create<Props>({
  name: 'project-update-details-sidebar',
})(ProjectUpdateDetailsSidebarWithoutForms)