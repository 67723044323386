import { SecuredClient } from 'Core/Api/SecuredClient';

import Utility from 'Core/Utility';
import ITableDataFetcher from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import ITableDataResponse from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';
import { ApproveProjectUpdateCommandRequest, DataFileType, FundingPurpose, FundingType, GetProjectUpdateByIdForAdminQueryRequest, GetProjectUpdatesByFilterForAdminQueryRequest, IApproveProjectUpdateCommandRequest, OrderBy, ProjectUpdateListModel, ProjectUpdateStatus } from 'Core/Api/Api';

export class ProjectUpdatesHandler implements ITableDataFetcher<ProjectUpdateListModel> {

    props: any;
    constructor(props) {
        this.props = props;
    }

    GetData(tableRequest: TableRequest): Promise<ITableDataResponse<ProjectUpdateListModel>> {
        return this.GetProjectUpdatesByFilterForAdmin(tableRequest);
    }

    GetProjectUpdatesByFilterForAdmin(tableRequest: TableRequest) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        const request = new GetProjectUpdatesByFilterForAdminQueryRequest(tableRequest);
        if (!request.amountPerPage) {
            request.amountPerPage = 12;

        }
        if (!request.orderBy || request.orderBy.length) {
            request.orderBy = [];
            request.orderBy.push(new OrderBy({ field: 'Name', ascending: true }));
        }

        return client.getProjectUpdatesByFilterForAdmin(request);
    }

    GetProjectUpdateByIdForAdmin(id: string) {
        const client = new SecuredClient();
        client.showLoading = true;
        client.handleGeneralError = true;

        const request = new GetProjectUpdateByIdForAdminQueryRequest({ id: id });
        return client.getProjectUpdateByIdForAdmin(request);
    }


    GetFundingPurpose() {
        return Utility.enumToSelectionOptionArray(FundingPurpose, true);
    }

    GetFundingType() {
        return Utility.enumToSelectionOptionArray(FundingType, true);
    }

    GetFileType() {
        return Utility.enumToSelectionOptionArray(DataFileType, true);
    }

    getProjectUpdateStatus() {
        return Utility.enumToSelectionOptionArray(ProjectUpdateStatus, true);
    }


    ApproveProjectUpdate(model: IApproveProjectUpdateCommandRequest) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;
        const request = new ApproveProjectUpdateCommandRequest(model);

        return client.approveProjectUpdate(request);
    }
}










