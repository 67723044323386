import React from 'react';
import { Form, Row, Input, Col, Button, Select, Transfer, Typography, Icon } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import FormControl from 'components/Forms/FormControl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import PaymentHandler from '../PaymentHandler';
import ValidationUtility from '../../../Core/ValidationUtility';
import SimpleSelect from '../../Forms/SimpleSelect';
import {
    RoleStatus,
    Operations,
    GetPaymentDetailsQueryResponse,
    Role,
    UserStatus,
    EditUserModel,
    AddUserModel,
    PaymentStatus
} from '../../../Core/Api/Api';
import Utility from 'Core/Utility';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import MaskedInput from 'components/Forms/MaskedInput';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import DetailsMode from 'Core/models/DetailsMode';
import DetailsData from 'Core/models/DetailsData';
import NotificationService from 'Core/NotificationService';
import TextArea from 'antd/lib/input/TextArea';
import { TransferItem } from 'antd/lib/transfer';
import AuthContext from 'Context/AuthContext';
import { delay } from 'lodash';
import SimpleTransfer from 'components/Forms/SimpleTransfer';
import {
    singleColFormColSize,
    FullWithButton,
    doubleColFormColSize,
} from 'components/Forms/Form.Styled';
import { LeftAlignedRow } from '../../Styled/Layout/LeftAlignedRow';
import moment from 'moment';
import ConfigService from 'Core/ConfigService';
import styled from 'styled-components';
import ShowIfTrue from 'components/Forms/ShowIfTrue';

const { Title } = Typography;

const FormControlStyle = {
    height: '4px',
    width: '400px',
}

const RowStyle = {
    display: 'flex',
    alignItems: 'center',
}

const HeadingRowStyle = {
    padding: '20px 0px 10px 0px'
}

const BackgroundColorStyleBlue = {
    backgroundColor: '#BAE1FF',
    paddingBottom: '10px',
}

const BackgroundColorStyleWhite = {
    backgroundColor: '#fff',
    paddingBottom: '10px',
}

const buttonStyle = {
    width: '80px',
    fontWeight: 600,
};

const FontStyle = {
    color: '#000'
}

export interface Props extends FormComponentProps, RouteComponentProps {
    children?: React.ReactNode;
}

export interface State {
    jurisdictions: ISelectOption[];
    operationsListOptions: TransferItem[];
    operations: string[];
    status: ISelectOption[];
    AllRoles: Role[];
    payment?: GetPaymentDetailsQueryResponse;
    roles: ISelectOption[];
    detailsMode: DetailsMode;
}

export class PaymentDetailsFormWithoutFormAndRouter extends React.Component<Props, State> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    handler: PaymentHandler;
    config = ConfigService.getSync();

    constructor(props: Props) {
        super(props);

        this.state = {
            jurisdictions: [],
            operations: [],
            operationsListOptions: [],
            status: [],
            roles: [],
            AllRoles: [],
            detailsMode: DetailsMode.New,
        };
        this.handler = new PaymentHandler(props);
    }

    componentDidMount() {
        // this.getRoles();
        this.getStatus();
        this.getPaymentDetails();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {

    }

    getPaymentDetails() {
        const urlState = this.props.location.state as DetailsData | undefined;

        if (urlState && urlState.mode == DetailsMode.View) {
            this.handler.GetPaymentById(urlState.id ?? '').then(
                x => {
                    this.setState({ payment: x, detailsMode: DetailsMode.View }, () => {
                        // this.props.form.setFieldsValue({ receiptNo: x.receiptNo });
                        // this.props.form.setFieldsValue({ invoiceNumber: x.invoiceNumber });
                        // this.props.form.setFieldsValue({ transactionId: x.transactionId });
                        // this.props.form.setFieldsValue({ user: x.user });
                        // this.props.form.setFieldsValue({ currency: x.currency });
                        // this.props.form.setFieldsValue({ donations: x.donations });
                        // this.props.form.setFieldsValue({ total: x.total });
                        // this.props.form.setFieldsValue({ date: x.date });
                        // this.props.form.setFieldsValue({ type: x.type });
                        // this.props.form.setFieldsValue({ paymentTypeName: x.paymentTypeName });
                        // this.props.form.setFieldsValue({ authCode: x.authCode });
                        // this.props.form.setFieldsValue({ cardType: x.cardType });
                        // this.props.form.setFieldsValue({ cardNumber: x.cardNumber });
                        // this.props.form.setFieldsValue({ status: x.status });
                    });
                },
                x => NotificationService.error(x.message)
            );
        } else {
            this.setState({ detailsMode: DetailsMode.New });
        }
    }

    updateRoleList = () => {
        let filteredRoles: Role[];
        filteredRoles = this.state.AllRoles;

        const roles: IFilterSelectOption[] =
            filteredRoles?.map(y => ({
                text: y.name,
                value: y.id,
            })) ?? [];
        this.setState({ roles });
    };

    getStatus() {
        var status: ISelectOption[] = [];
        Utility.enumToMap(UserStatus).forEach((x, y) => status.push({ text: x, value: y }));
        this.setState({ status });
    }

    updateOperationsList = () => {
        let operationsList: TransferItem[] = [];

        operationsList = this.state.operations.map(x => ({ key: x, title: x }));

        this.setState({ operationsListOptions: operationsList });
    };

    onJurisdictionChange = (value: any) => {
        // This is done because the value doesn't seem to update on deselect
        delay(this.updateOperationsList, 10, value);
    };

    setRoles(request: { roles?: string[] }, arg1: any) {
        if (arg1.map) {
            request.roles = arg1;
        } else {
            request.roles = [];
            request.roles.push(arg1);
        }
    }

    onReceipt = () => {
        this.handler.GetReceipt({ id: this.state.payment?.id }).then(x => {
            Utility.downloadBase64File(x.fileBase64String, x.fileName);
        })
    }

    render() {
        var { getFieldDecorator } = this.props.form;
        return (
            <Row>
                <Col>
                    <Form {...doubleColFormColSize} layout="horizontal" >
                        <Row>
                            <Col style={{ display: 'flex', justifyContent: 'center' }} span={24} >
                                <h1 style={{ fontSize: 30, color: '#000' }}>Jamaica Social Stock Exchange</h1>
                            </Col>
                        </Row>

                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <Col style={{ border: 'solid 1px black' }} span={12}>
                                <Row>
                                    <Col style={BackgroundColorStyleBlue}>
                                        <Row style={HeadingRowStyle}>
                                            <Col span={21} offset={3}>
                                                <h1 style={{fontWeight: 'bolder', color: '#000'}}>Donation Information</h1>
                                            </Col>
                                        </Row>

                                        <Row style={RowStyle}>
                                            <Col span={9} offset={3}>
                                                <label style={{fontWeight: 'bold', color: '#000'}}>Invoice #:</label>
                                            </Col>
                                            <Col span={12}>
                                                <FormControl
                                                    style={FormControlStyle}
                                                    getFieldDecorator={getFieldDecorator}
                                                    name="invoiceNumber">
                                                    <label style={FontStyle}> {this.state.payment?.invoiceNumber} </label>
                                                </FormControl>
                                            </Col>
                                        </Row>

                                        <Row style={RowStyle}>
                                            <Col span={9} offset={3}>
                                                <label style={{fontWeight: 'bold', color: '#000'}}>Date:</label>
                                            </Col>
                                            <Col span={12}>
                                                <FormControl
                                                    style={FormControlStyle}
                                                    getFieldDecorator={getFieldDecorator}
                                                    name="date">
                                                    {/* <label> { this.displayDateFormatted(this.state.payment?.date) } </label> */}
                                                    {/* <label style={FontStyle}> {this.state.payment?.date} </label> */}
                                                    <label style={FontStyle}> {Utility.renderDateWithReference(moment(this.state.payment?.date), this.config.Display.DateTimeFormat)} </label>
                                                </FormControl>
                                            </Col>
                                        </Row>

                                        <Row style={RowStyle}>
                                            <Col span={9} offset={3}>
                                                <label style={{fontWeight: 'bold', color: '#000'}}>Receipt #:</label>
                                            </Col>
                                            <Col span={12}>
                                                <FormControl
                                                    style={FormControlStyle}
                                                    getFieldDecorator={getFieldDecorator}
                                                    name="receiptNo">
                                                    <label style={FontStyle}> {this.state.payment?.receiptNo} </label>
                                                </FormControl>
                                            </Col>
                                        </Row>

                                        <Row style={RowStyle}>
                                            <Col span={9} offset={3}>
                                                <label style={{fontWeight: 'bold', color: '#000'}}>Transaction ID:</label>
                                            </Col>
                                            <Col span={12}>
                                                <FormControl
                                                    style={FormControlStyle}
                                                    getFieldDecorator={getFieldDecorator}
                                                    name="transactionId">
                                                    <label style={FontStyle}> {this.state.payment?.transactionId} </label>
                                                </FormControl>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col style={BackgroundColorStyleWhite}>
                                        <Row style={HeadingRowStyle}>
                                            <Col span={21} offset={3}>
                                                <h1 style={{fontWeight: 'bolder', color: '#000'}}>Customer Information</h1>
                                            </Col>
                                        </Row>

                                        <Row style={RowStyle}>
                                            <Col span={9} offset={3}>
                                                <label style={{fontWeight: 'bold', color: '#000'}}>Firstname:</label>
                                            </Col>
                                            <Col span={12}>
                                                <FormControl
                                                    style={FormControlStyle}
                                                    getFieldDecorator={getFieldDecorator}
                                                    name="firstName">
                                                    <label style={FontStyle}> {this.state.payment?.user?.firstName} </label>
                                                </FormControl>
                                            </Col>
                                        </Row>

                                        <Row style={RowStyle}>
                                            <Col span={9} offset={3}>
                                                <label style={{fontWeight: 'bold', color: '#000'}}>Lastname:</label>
                                            </Col>
                                            <Col span={12}>
                                                <FormControl
                                                    style={FormControlStyle}
                                                    getFieldDecorator={getFieldDecorator}
                                                    name="lastName">
                                                    <label style={FontStyle}> {this.state.payment?.user?.lastName} </label>
                                                </FormControl>
                                            </Col>
                                        </Row>

                                        <Row style={RowStyle}>
                                            <Col span={9} offset={3}>
                                                <label style={{fontWeight: 'bold', color: '#000'}}>Email:</label>
                                            </Col>
                                            <Col span={12}>
                                                <FormControl
                                                    style={FormControlStyle}
                                                    getFieldDecorator={getFieldDecorator}
                                                    name="email">
                                                    <label style={FontStyle}> {this.state.payment?.user?.email} </label>
                                                </FormControl>
                                            </Col>
                                        </Row>

                                        <Row style={RowStyle}>
                                            <Col span={9} offset={3}>
                                                <label style={{fontWeight: 'bold', color: '#000'}}>Authentication Code:</label>
                                            </Col>
                                            <Col span={12}>
                                                <FormControl
                                                    style={FormControlStyle}
                                                    getFieldDecorator={getFieldDecorator}
                                                    name="authCode">
                                                    <label style={FontStyle}> {this.state.payment?.authCode} </label>
                                                </FormControl>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col style={BackgroundColorStyleBlue}>
                                        <Row style={HeadingRowStyle}>
                                            <Col span={21} offset={3}>
                                                <h1 style={{fontWeight: 'bolder', color: '#000'}}>Billing Information</h1>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col span={9} offset={3}>
                                                <label style={{fontWeight: 'bold', color: '#000'}}>Billing Address</label>
                                            </Col>
                                            <Col span={12}>
                                                <Row>
                                                    <label style={FontStyle}>{this.state.payment?.user?.address?.line1}</label>
                                                </Row>
                                                <Row>
                                                    <label style={FontStyle}>{this.state.payment?.user?.address?.line2}</label>
                                                </Row>
                                                <Row>
                                                    <label style={FontStyle}>{this.state.payment?.user?.address?.city}, {this.state.payment?.user?.address?.stateParish}</label>
                                                </Row>
                                                <Row>
                                                    <label style={FontStyle}>{this.state.payment?.user?.address?.country}</label>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col style={BackgroundColorStyleWhite}>
                                        <Row style={HeadingRowStyle}>
                                            <Col span={21} offset={3}>
                                                <h1 style={{fontWeight: 'bolder', color: '#000'}}>Payment Method</h1>
                                            </Col>
                                        </Row>

                                        <Row style={RowStyle}>
                                            <Col span={9} offset={3}>
                                                <label style={{fontWeight: 'bold', color: '#000'}}>Type:</label>
                                            </Col>
                                            <Col span={12}>
                                                <FormControl
                                                    style={FormControlStyle}
                                                    getFieldDecorator={getFieldDecorator}
                                                    name="paymentTypeName">
                                                    <label style={FontStyle}> {this.state.payment?.paymentTypeName} </label>
                                                </FormControl>
                                            </Col>
                                        </Row>

                                        <Row style={RowStyle}>
                                            <Col span={9} offset={3}>
                                                <label style={{fontWeight: 'bold', color: '#000'}}>Card #:</label>
                                            </Col>
                                            <Col span={12}>
                                                <FormControl
                                                    style={FormControlStyle}
                                                    getFieldDecorator={getFieldDecorator}
                                                    name="cardNumber">
                                                    <label style={FontStyle}> {this.state.payment?.cardNumber} </label>
                                                </FormControl>
                                            </Col>
                                        </Row>

                                        <Row style={RowStyle}>
                                            <Col span={9} offset={3}>
                                                <label style={{fontWeight: 'bold', color: '#000'}}>Card Type:</label>
                                            </Col>
                                            <Col span={12}>
                                                <FormControl
                                                    style={FormControlStyle}
                                                    getFieldDecorator={getFieldDecorator}
                                                    name="cardType">
                                                    <label style={FontStyle}> {this.state.payment?.cardType} </label>
                                                </FormControl>
                                            </Col>
                                        </Row>

                                        <Row style={RowStyle}>
                                            <Col span={9} offset={3}>
                                                <label style={{fontWeight: 'bold', color: '#000'}}>Currency</label>
                                            </Col>
                                            <Col span={12}>
                                                <FormControl
                                                    style={FormControlStyle}
                                                    getFieldDecorator={getFieldDecorator}
                                                    name="currency">
                                                    <label style={FontStyle}> {this.state.payment?.currency} </label>
                                                </FormControl>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Row style={BackgroundColorStyleBlue}>
                                            <Row style={HeadingRowStyle}>
                                                <Col span={9} offset={3}>
                                                    <h1 style={{ fontWeight: 'bolder', color: '#000' }}>Projects</h1>
                                                </Col>
                                                <Col span={12} style={RowStyle}>
                                                    <Col span={4}>
                                                        <label style={{ fontWeight: 'bold', color: '#000' }}>Status: </label>
                                                    </Col>
                                                    <Col span={20}>
                                                        <FormControl
                                                            style={FormControlStyle}
                                                            getFieldDecorator={getFieldDecorator}
                                                            name="status">
                                                            <label style={FontStyle}> {this.state.payment?.status} </label>
                                                        </FormControl>
                                                    </Col>
                                                </Col>
                                            </Row>
                                        </Row>

                                        <Row style={BackgroundColorStyleWhite}>
                                            <Col span={9} offset={3} style={HeadingRowStyle}>
                                                {
                                                    this.state.payment?.donations?.map(donation =>
                                                        <Row>
                                                            <label style={FontStyle}>{donation.project?.company}</label>
                                                        </Row>
                                                    )
                                                }
                                            </Col>
                                            <Col span={12} style={HeadingRowStyle}>
                                                {
                                                    this.state.payment?.donations?.map(donation =>
                                                        <Row>
                                                            <label style={FontStyle}>{this.state.payment?.currency} ${donation.amount}</label>
                                                        </Row>
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col style={BackgroundColorStyleBlue}>
                                        <Row style={HeadingRowStyle}>
                                            <Col span={9} offset={3}>
                                                <h1 style={{fontWeight: 'bold', color: '#000'}}>Subtotal</h1>
                                            </Col>
                                            <Col span={12}>
                                                <FormControl
                                                    style={FormControlStyle}
                                                    getFieldDecorator={getFieldDecorator}
                                                    name="total">
                                                    <label style={FontStyle}> {this.state.payment?.currency + ' $' + this.state.payment?.total} </label>
                                                </FormControl>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row style={{ display: 'flex', justifyContent: 'center'}}>
                            <Col span={12} style={HeadingRowStyle}>
                                <div style={{ display: 'flex', float: 'right', marginRight: '15px' }}>
                                    <Col span={12} style={{ marginRight: '5px'}}>
                                        <Button onClick={() => this.props.history.goBack()} type="danger" style={buttonStyle}>
                                            Back
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        <ShowIfTrue condition={this.state.payment?.status == PaymentStatus.Successful}>
                                            <Button type="primary" title="Download Receipt" style={buttonStyle} onClick={this.onReceipt} >
                                                <Icon title="Download" type="download" />
                                            </Button>
                                        </ShowIfTrue>
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        );
    }
}

const PaymentDetailsFormWithRouter = Form.create<Props>({
    name: 'PaymentDetails',
})(PaymentDetailsFormWithoutFormAndRouter);

const PaymentDetailsForm = withRouter(PaymentDetailsFormWithRouter);

export default PaymentDetailsForm;
