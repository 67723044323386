import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { User, SlimUser, Role, PaymentListItem, PaymentStatus } from 'Core/Api/Api';
import SimpleTable from 'components/Forms/DataGrid/SimpleTable';
import UserHandler from '../../UserHandler';
import { NavLink } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import DetailsMode from 'Core/models/DetailsMode';
import RoleHandler from 'components/Admin/RoleHandler';
import DetailsData from 'Core/models/DetailsData';
import PaymentHandler from '../../PaymentHandler';
import { Button, Icon } from 'antd';
import Utility from 'Core/Utility';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import moment from 'moment';
import ConfigService from 'Core/ConfigService';
import NumberFormat from 'react-number-format';
export interface Props {
    children?: React.ReactNode;
}

export interface State {
    countries: IFilterSelectOption[];
    jurisdictions: IFilterSelectOption[];
    roles: IFilterSelectOption[];
}

export default class PaymentListTable extends React.Component<Props, State> {
    handler: PaymentHandler;
    config = ConfigService.getSync();
    constructor(props: Props) {
        super(props);

        this.state = {
            countries: [],
            jurisdictions: [],
            roles: [],
        };
        this.handler = new PaymentHandler(props);
    }

    onReceipt = (payment: PaymentListItem) => {
        this.handler.GetReceipt({ id: payment.id }).then(x => {
            Utility.downloadBase64File(x.fileBase64String, x.fileName);
        })
    }

    componentDidMount() {

    }

    rolesListTableColumns: Array<ColumnProps<PaymentListItem>> = [
        {
            title: 'Receipt #',
            dataIndex: 'id',
            sorter: true,
            render: (text: any, record: PaymentListItem, index: number) => {
                return record.invoiceId
            }
        },
        {
            title: 'Payer',
            dataIndex: 'user.firstName',
            sorter: true,
            render: (text: any, record: PaymentListItem, index: number) => {
                return record.userName;
            }
        },
        {
            title: 'Total',
            dataIndex: 'total',
            sorter: true,
            render: (text: any, record: PaymentListItem, index: number) => {
                return <NumberFormat value={record.total} prefix="$" thousandSeparator="," displayType='text' />;
            },
        },
        {
            title: 'Items',
            dataIndex: 'itemCount',
            sorter: true,
        },
        {
            title: 'Date',
            dataIndex: 'paymentDate',
            sorter: true,
            render:(text: any, record: PaymentListItem, index: number) => {
                return Utility.renderDateWithReference(moment(record.date), this.config.Display.DateTimeFormat);
            },
            
        },
        {
            title: 'Type',
            dataIndex: 'paymentType',
            sorter: true,
            render: (text: any, record: PaymentListItem, index: number) => {
                return record.type;
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text: any, record: PaymentListItem, index: number) => {
                console.log(record);
                return text;
            },
        },
        {
            title: '',
            render: (text: any, record: PaymentListItem, index: number) => {
                return (
                    <>
                        <NavLink
                            to={{
                                pathname: 'payments/' + encodeURIComponent(record.id ?? ''),
                                state: new DetailsData(DetailsMode.View, record.id),
                            }}>
                            <Icon type="read" title="View" />
                        </NavLink>
                        <ShowIfTrue condition={record.status == PaymentStatus.Successful}>
                            <Button type="link" title="Download Receipt" onClick={x => this.onReceipt(record)} >
                                <Icon title="Download" type="download" />
                            </Button>
                        </ShowIfTrue>
                    </>
                );
            },
        },
    ];

    render() {
        return (
            <SimpleTable<PaymentListItem>
                tableKey="id"
                dataFetcher={this.handler}
                columns={this.rolesListTableColumns}
            />
        );
    }
}



