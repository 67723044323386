import { Row, Col, Collapse, Icon } from 'antd';
import React from 'react';

const { Panel } = Collapse;

interface Props {
}

export default ({ }: Props) => {
    return (<Row >

        <Col md={16} offset={4}>
      
        </Col>

    </Row>);
}









