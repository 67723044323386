import React from 'react';
import { SlimUser, SwaggerException } from '../Api/Api';
import _, { startCase } from 'lodash';
import AuthUser from 'Core/models/AuthUser';
import { matchPath } from 'react-router';
import AppRoute from 'Core/models/AppRoute';
import asFormData from 'json-form-data';
import { UploadFile } from 'antd/lib/upload/interface';
import { Moment } from 'moment';
import { Tooltip } from 'antd';

export default class Utility {
  static renderDateWithReference(createDate?: Moment, dateFormat?: string): React.ReactNode {
    return (
      <Tooltip title={createDate?.fromNow()}>
        <span>{createDate?.format(dateFormat)}</span>
      </Tooltip>
    );
  }

  static VoidValue: void;

  static replaceChildProps(children: React.ReactNode, newProps: any) {
    let elements = React.Children.toArray(children);
    return React.cloneElement(elements[0] as any, newProps);
  }

  static ConvertToFormData(jsonData: any) {
    return asFormData(jsonData as any, {
      mapping: (value: any) => {
        if ((value as UploadFile)?.originFileObj) {
          return (value as UploadFile).originFileObj as File;
        } else if (value?._isAMomentObject) {
          return (value as Moment).toISOString();
        } else {
          return value;
        }
      },
    });
  }

  static ClipTextLength(text: string, maxLength: number): string {
    if (!text) return text;

    if (text.length >= maxLength ?? 0) {
      return text.substring(0, maxLength - 3) + '...';
    } else {
      return text;
    }
  }

  public static downloadURI(uri, name) {
    let link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  static convertToFullPath(routes?: AppRoute[], basePath: string = ''): AppRoute[] | undefined {
    return routes?.map(x => {
      const path = this.combinePath(basePath, x.path as string);
      return { ...x, path, routes: this.convertToFullPath(x.routes, path) };
    });
  }
  public static combinePath(...args: string[]) {
    let path = args[0];

    if (!args[0]) {
      return args[1] || args[0];
    }

    return (
      args
        .reduce((previousPath: string, nextpath: string) => {
          return nextpath.indexOf('/') === 0 && nextpath === '/'
            ? previousPath
            : previousPath + '/' + nextpath;
        }, '')
        // this is not an ideal solutions but it should work for now
        .replace('//', '/')
    );
  }

  public static isPathOrSubpathAMatch(path: string, match: AppRoute): boolean {
    return (
      !!matchPath(path, match) ||
      (match.routes?.some(x => Utility.isPathOrSubpathAMatch(path, x)) ?? false)
    );
  }

  public static downloadBase64File(link, fileName) {
    const downloadLink = document.createElement("a");
    downloadLink.href = link;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  public static HasOperations(user?: AuthUser, requiredOperations?: string[]) {
    // if no operations are supplied, it is a public page
    if (!requiredOperations || requiredOperations.length === 0) {
      return true;
    }

    // if operations are supplied and the user is not logged in, they need to login
    if ((user === null || user === undefined) && requiredOperations.length > 0) {
      return false;
    }

    // search through all required operations to see if the user has all of them

    for (const requiredOperation of requiredOperations) {
      if (user && user.operations && user.operations.length > 0) {
        const foundOperation = user.operations.filter(x => x.toString() === requiredOperation);

        // an operation that was required was not in the users list, they don't have permission
        if (!foundOperation || foundOperation.length === 0) {
          return false;
        }
      } else {
        // the user has no operations so it must be that the need permission
        return false;
      }
    }

    // to reach this point means that all operations needed where found in the users operations
    return true;
  }

  public static RandomNumber(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  public static getErrorMessage(error: SwaggerException) {
    if (error.response) {
      return JSON.parse(error.response).message;
    } else return undefined;
  }

  /**
   * Converts the given enum to a map of the keys to the values.
   * @param enumeration The enum to convert to a map.
   */
  public static enumToMap(enumeration: any): Map<any, any | any> {
    const map = new Map<string, string | number>();
    for (let key in enumeration) {
      //TypeScript does not allow enum keys to be numeric
      if (!isNaN(Number(key))) continue;

      const val = enumeration[key] as string | number;

      //TypeScript does not allow enum value to be null or undefined
      if (val !== undefined && val !== null) map.set(key, val);
    }
    return map;
  }

  static getImageDimensions(file): Promise<{ width: number, height: number }> {
    return new Promise(function (resolved, rejected) {
      var i = new Image()
      i.onload = function () {
        resolved({ width: i.width, height: i.height })
      };
      i.src = file
    })
  }


  public static enumToSelectionOptionArray(enumeration: any, fixCasing: boolean = false): { text: string; value: any }[] {
    const list: { text: string; value: any }[] = [];
    this.enumToMap(enumeration).forEach((x, y) => list.push({ text: fixCasing ? startCase(x) : x, value: y }));
    return list;
  }

  /**
   * https://gist.github.com/nicbell/6081098#file-object-compare-js
   *
   * @static
   * @param {*} obj1
   * @param {*} obj2
   * @returns
   * @memberof Utility
   */
  public static Compare(obj1, obj2) {
    return _.isEqual(obj1, obj2);
    // Loop through properties in object 1
    // tslint:disable-next-line:forin
    for (let p in obj1) {
      // Check property exists on both objects
      if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) {
        return false;
      }

      switch (typeof obj1[p]) {
        // Deep compare objects
        case 'object':
          if (!Utility.Compare(obj1[p], obj2[p])) {
            return false;
          }
          break;
        // Compare function code
        case 'function':
          if (
            // tslint:disable-next-line:triple-equals
            typeof obj2[p] === 'undefined' ||
            // tslint:disable-next-line:triple-equals
            (p != 'compare' && obj1[p].toString() != obj2[p].toString())
          ) {
            return false;
          }
          break;
        // Compare values
        default:
          // tslint:disable-next-line:triple-equals
          if (obj1[p] !== obj2[p]) {
            return false;
          }
      }
    }

    // Check object 2 for any extra properties
    for (let p in obj2) {
      if (!obj2.hasOwnProperty(p)) {
        continue;
      }
      if (!obj1.hasOwnProperty(p)) {
        return false;
      }
    }
    return true;
  }

  constructor() { }
}










