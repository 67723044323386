import * as React from 'react';
import { Col, Row } from 'antd';
import { RouteProps, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import ResetPasswordForm from 'components/UserManagement/ResetPasswordForm';
import NotificationService from 'Core/NotificationService';
import UserProfileForm from 'components/UserManagement/UserProfileForm';
import { CenteredFormContent, BannerDiv } from 'components/Layout/LoginLayout/LoginLayout.Style';

export interface Props extends RouteProps {
  children?: React.ReactNode;
}

export interface State { }

const TallCol = styled(Col)`
  height: 100%;
  min-height: 200px;
`;

export default class UserProfilePage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (<CenteredFormContent>
      <BannerDiv className='admin'>
        <Row style={{ height: '100%' }}>
          <Col xs={{ span: 10, offset: 11 }} md={{ span: 8, offset: 14 }} lg={{ span: 7, offset: 15 }} xxl={{ span: 7, offset: 16 }} style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <div>

            </div>
          </Col>
        </Row>
      </BannerDiv>
      <Row type="flex" align="middle" justify="center">
        <Col span={21} offset={1}>
          <UserProfileForm />
        </Col>
      </Row>

    </CenteredFormContent>);
  }
}










