import * as React from 'react';
import { Layout } from 'antd';
import { AppRoutes } from '../../../../Core/AppRoutes';
import logo from '../../../assets/images/logo.svg';
import AppRoute from '../../../../Core/models/AppRoute';
import AppMenu from '../../AppMenu';
import Logo from '../../../Styled/Logo';
const { Sider } = Layout;
export interface Props {
  children?: React.ReactNode;
  menu: AppRoute[];
}

export interface State { }

export default class Sidebar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Sider collapsible={true} style={{ backgroundColor: "#262626" }} theme="dark">
        <Logo linkUrl="/Dashboard" />
        <AppMenu mode="inline" onlySubMenuNavigates={true} menu={this.props.menu} />
      </Sider>
    );
  }
}













