import { config } from '@storybook/addon-actions';
import { Divider, Typography } from 'antd';
import { InternalDocumentLink } from 'components/Forms/InternalDocumentLink';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import { IProjectFullDetailsModel, ISmallAddress, ISmallCompanyModel } from 'Core/Api/Api';
import ConfigService from 'Core/ConfigService';
import Utility from 'Core/Utility';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  flex-direction:column;
  align-content: flex-start;
  gap: 10px;

`;

interface Props {
  company?: ISmallCompanyModel | undefined;
}

export default ({ company }: Props) => {

  let config = ConfigService.getSync();

  const AddressInfo = ({ address }: { address: ISmallAddress | undefined }) => {
    return <p>
      {address?.line1 && <> {address?.line1}, <br /></>}
      {address?.line2 && <> {address?.line2}, <br /></>}
      {address?.city && <> {address?.city}, <br /></>}
      {address?.stateParish && <>{address?.stateParish}, <br /></>}
      {address?.postalCode && <>{address?.postalCode},<br /></>}
      {address?.country && <>{address?.country} </>}
    </p>
  }

  return <Wrap>
    <h3>Company</h3>
    <Typography.Text><b>Name:</b> {company?.name}</Typography.Text>
    <Typography.Text><b>Address:</b><AddressInfo address={company?.address} /> </Typography.Text>
    <Typography.Text><b>Company Phone Number:</b> {company?.phoneNumber}</Typography.Text>
    <Typography.Text><b>Chief Accountable Officer/Focal Point:</b> {company?.contactPerson}</Typography.Text>
    <Typography.Text><b>Contact Phone:</b> {company?.contactPersonPhone} </Typography.Text>
  </Wrap>;
}