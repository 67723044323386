import { Col, Row } from 'antd';
import Utility from 'Core/Utility';
import React from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';

const Wrap = styled.div``;

interface Props {
  title: string;
}

export default ({ title }: Props) => {


  return <Row style={{ height: '100%' }}>
    <Col style={{ height: '100%', fontSize: "35px", display: 'flex', alignItems: 'stretch', justifyContent: "space-around" }}>
      <h1>{title}</h1>
    </Col>
  </Row>;
}