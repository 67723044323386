import * as React from 'react';
import FilterBar from 'components/Forms/DataGrid/FilterBar';
import { Row, Col, Form, Button } from 'antd';
import FilterTextbox from 'components/Forms/DataGrid/FilterTextbox';
import InfoIcon from 'components/Forms/InfoIcon';
import { FilterType, JoinType, PaymentStatus, PledgeStatus } from '../../../../Core/Api/Api';
import FilterSelectbox from 'components/Forms/DataGrid/FilterSelectbox';
import Utility from 'Core/Utility';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import PledgeHandler from '../../PledgeHandler';
import { request } from 'https';

export interface Props {
    children?: React.ReactNode;
}

export interface State {
    countries: ISelectOption[];
    jurisdictions: ISelectOption[];
    roles: ISelectOption[];
    roleStatus: ISelectOption[];
}
export default class PledgeListFilterBar extends React.Component<Props, State> {
    handler: PledgeHandler;

    constructor(props: Props) {
        super(props);

        this.state = {
            countries: [],
            jurisdictions: [],
            roles: [],
            roleStatus: [],
        };
        this.handler = new PledgeHandler(props);
    }

    componentDidMount() {
        this.getRoleStatus();
    }
    getRoleStatus() {
        const roleStatus = Utility.enumToSelectionOptionArray(PledgeStatus);
        this.setState({ roleStatus });
    }

    render() {
        return (
            <FilterBar>
                <Row>

                    <Col offset={1} span={6}>
                        <Form.Item labelCol={{ span: 4 }} label="Search" labelAlign="left">
                            <FilterTextbox
                                id="PledgeSearchBox"
                                placeholder=""
                                title="PledgeSearchBox"
                                filterFields="name,createdBy,lastName,pledgeItems.Project.Name"
                                filterJoin={JoinType.And}
                                suffix={<InfoIcon text="Search By Project Name or Pledger" />}
                            />
                        </Form.Item>
                    </Col>

                    <Col offset={1} span={6}>
                        <Form.Item labelCol={{ span: 4 }} label="Status" labelAlign="left">
                            <FilterSelectbox
                                filterFields="status"
                                filterType={FilterType.Equal}
                                showSearch={true}
                                allowClear={true}
                                filterJoin={JoinType.And}
                                placeholder={'Select Status'}
                                options={this.state.roleStatus}
                            />
                        </Form.Item>
                    </Col>
                    
                </Row>
            </FilterBar>
        );
    }
}



