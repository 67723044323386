import { RegisterFormModel } from './Models/RegisterFormModel';
import { SecuredClient } from '../../../Core/Api/SecuredClient';
import { RegisterInvestorOrEntrepreneurCommandRequest, SlimUser,  } from '../../../Core/Api/Api';

export class RegisterFormHandler {
  props: any;
  constructor(props) {
    this.props = props;
  }

  RequestRegister(RegisterformModel: RegisterFormModel): Promise<SlimUser | undefined> {
    const client = new SecuredClient();
      const request = new RegisterInvestorOrEntrepreneurCommandRequest();
    request.init(RegisterformModel);
    client.showLoading = true;
    client.handleGeneralError = true;
    return client.register(request).then(x => {
      return x.newUser;
    });
  }
}
















