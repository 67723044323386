import * as React from 'react';
import FilterBar from 'components/Forms/DataGrid/FilterBar';
import { Row, Col, Form } from 'antd';
import FilterTextbox from 'components/Forms/DataGrid/FilterTextbox';
import InfoIcon from 'components/Forms/InfoIcon';
import { FilterType, JoinType, NewsStatus } from '../../../../Core/Api/Api';
import FilterSelectbox from 'components/Forms/DataGrid/FilterSelectbox';
import Utility from 'Core/Utility';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';

import FilterDateRangePicker from 'components/Forms/DataGrid/FilterDateRangePicker';
import { NewsHandler } from '../../NewsHandler';

export interface Props {
  children?: React.ReactNode;
}

export interface State {
  status: ISelectOption[];
}
export default class NewsListFilterBar extends React.Component<Props, State> {
  handler: NewsHandler;

  constructor(props: Props) {
    super(props);

    this.state = {
      status: [],
    };
    this.handler = new NewsHandler(props);
  }

  componentDidMount() {
    this.getNewsStatus();
  }
  getNewsStatus() {
    const status = Utility.enumToSelectionOptionArray(NewsStatus);
    this.setState({ status });
  }

  render() {
    return (
      <FilterBar>
        <Row>
          <Col span={12}>
            <Form.Item label="Search" labelAlign="left">
              <FilterTextbox
                filterFields="name,title,overview"
                filterJoin={JoinType.Or}
                suffix={<InfoIcon text="Search name" />}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Status" labelAlign="left">
              <FilterSelectbox
                filterFields="status"
                filterType={FilterType.Equal}
                showSearch={true}
                allowClear={true}
                filterJoin={JoinType.And}
                placeholder={'Select Status'}
                options={this.state.status}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label="Published" labelAlign="left">
              <FilterDateRangePicker
                filterFields="publishDate"
                filterType={FilterType.Equal}
                allowClear={true}
                filterJoin={JoinType.And}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Created" labelAlign="left">
              <FilterDateRangePicker
                filterFields="creationDate"
                filterType={FilterType.Equal}
                allowClear={true}
                filterJoin={JoinType.And}
              />
            </Form.Item>
          </Col>
        </Row>
      </FilterBar>
    );
  }
}



