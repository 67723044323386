import ITableDataFetcher from '../../components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import ITableDataResponse from '../../components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';
import {
    AddRoleModel,
    EditRoleModel,
    GetAllRolesByFilterModel,
    GetAllUsersByFilterModel,
  GetRoleByIdModel,
  OrderBy,
  Role,
} from '../../Core/Api/Api';
import { SecuredClient } from '../../Core/Api/SecuredClient';
import TableRequest from '../../Core/TableUtility/Models/TableRequest';

export default class RoleHandler implements ITableDataFetcher<Role> {
  constructor(props?: any) {}

    GetData(tableRequest: TableRequest): Promise<ITableDataResponse<Role>> {
    return this.GetAllRolesByFilter(tableRequest);
  }

    GetAllRolesByFilter(tableRequest: TableRequest): Promise<ITableDataResponse<Role>> {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
        const request = new GetAllRolesByFilterModel(tableRequest);
    return client.getAllRolesByFilter(request);
  }

  GetAllUserByFilter(request: TableRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
      const getFilteredMealsForOwnerRequest = new GetAllUsersByFilterModel(request);
    return client.getAllUsersByFilter(getFilteredMealsForOwnerRequest);
  }

  GetOperations() {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    return client.getOperations();
  }

  GetRoleById(roleId?: string) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
      const request = new GetRoleByIdModel();
    request.roleId = roleId;
    return client.getRoleById(request);
  }

    EditRole(request: EditRoleModel) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;

    return client.editRole(request);
  }

    AddRole(request: AddRoleModel) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;

    return client.addRole(request);
  }
}
