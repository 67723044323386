import { Button, Col, Descriptions, Drawer, Popconfirm, Row } from 'antd';
import { FullWithButton } from 'components/Forms/Form.Styled';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import { ActionsFooter } from 'components/ProjectUpdateManagement/ProjectUpdateDetailsSidebar/ProjectUpdateDetailsSidebar';

import ButtonGroup from 'components/Styled/ButtonGroup';
import { IChangePledgeModel, IPledge, PledgeStatus } from 'Core/Api/Api';
import ConfigService from 'Core/ConfigService';
import NotificationService from 'Core/NotificationService';
import Utility from 'Core/Utility';
import moment from 'moment';
import React, { SyntheticEvent, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router';
import PledgeHandler from '../PledgeHandler';
import { AcknowledgePledgeFormSidebar } from './AcknowledgePledgeFormSidebar';

interface Props {
  pledge?: IPledge;
  visible: boolean;
  onSubmitted?: Function;
  onClose?: (e: SyntheticEvent) => void;
}

export const PledgeViewSidebar = ({ pledge: pledge, onSubmitted, visible, onClose }: Props) => {

  let pledgeHandler = new PledgeHandler({});
  let history = useHistory();
  const config = ConfigService.getSync();
  const [showAcknowledgePledgeSideBar, setShowAcknowledgePledgeSideBar] = useState(false);

  const reversePledge = () => {
    const request: IChangePledgeModel = {};
    request.id = pledge?.id;
    request.status = PledgeStatus.Reversed;
    pledgeHandler.AcknowledgePledge(request).then(x => {
      NotificationService.success(`Pledge has been updated`, "", 10, true);
      onSubmitted?.();
    });
  }

  const rejectPledge = () => {
    const request: IChangePledgeModel = {};
    request.id = pledge?.id;
    request.status = PledgeStatus.Rejected;
    pledgeHandler.AcknowledgePledge(request).then(x => {
      NotificationService.success(`Pledge has been updated`, "", 10, true);
      onSubmitted?.();
    });
  }

  return <Drawer
    title={`${pledge?.fullName} To ${pledge?.pledgeItems?.map(x => x.project?.name).reduce((x, y) => `${x}, ${y}`)}`}
    width={720}
    onClose={onClose}
    visible={visible}
    bodyStyle={{ paddingBottom: 80, display: "flex", flexDirection: "column" }}
  >
    <Row style={{ flexGrow: 1 }}>
      <Col>
        <Descriptions column={1}>
          <Descriptions.Item label="Pledger">{pledge?.fullName}</Descriptions.Item>
          <Descriptions.Item label="Phone Number">{pledge?.phoneNumber}</Descriptions.Item>
          <Descriptions.Item label="Email">{pledge?.email}</Descriptions.Item>
          <Descriptions.Item label="Project(s)">{pledge?.pledgeItems?.map(x => x.project?.name).reduce((x, y) => `${x}, ${y}`)}</Descriptions.Item>
          <Descriptions.Item label="Pledged Amount"><NumberFormat value={pledge?.totalPledge} prefix="$" thousandSeparator="," displayType='text' />
          </Descriptions.Item>
          {!!pledge?.amountReceived && <Descriptions.Item label="Total Received"><NumberFormat value={pledge?.amountReceived} prefix="$" thousandSeparator="," displayType='text' /></Descriptions.Item>}
          <Descriptions.Item label="Date">{Utility.renderDateWithReference(moment(pledge?.creationDate), config.Display.DateTimeFormat)}</Descriptions.Item>
          <Descriptions.Item label="Status">{pledge?.status}</Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
    <Row>
      <Col>
        <ActionsFooter>
          <ButtonGroup style={{ width: "100%", justifyContent: "center" }}>
            <Button onClick={x => onClose?.(x)} type="default">
              Back
            </Button>
            <ShowIfTrue condition={pledge?.status != PledgeStatus.Fulfilled}>
              <FullWithButton
                onClick={x => setShowAcknowledgePledgeSideBar(true)}
                type="primary">
                Acknowledge
              </FullWithButton>
            </ShowIfTrue>
            <ShowIfTrue condition={pledge?.status == PledgeStatus.Fulfilled}>
              <Popconfirm onConfirm={reversePledge} title="Are you sure you wish to reverse this pledge?">
                <FullWithButton
                  type="danger">
                  Reverse
                </FullWithButton>
              </Popconfirm>
            </ShowIfTrue>
            <ShowIfTrue condition={pledge?.status == PledgeStatus.Unfulfilled}>
              <Popconfirm onConfirm={rejectPledge} title="Are you sure you wish to reject this pledge?">
                <FullWithButton
                  type="danger">
                  Reject
                </FullWithButton>
              </Popconfirm>
            </ShowIfTrue>
          </ButtonGroup>
        </ActionsFooter>
      </Col>
    </Row>
    <AcknowledgePledgeFormSidebar pledge={pledge} onSubmitted={onSubmitted} visible={showAcknowledgePledgeSideBar} onClose={x => setShowAcknowledgePledgeSideBar(false)} />
  </Drawer>;
}

export default PledgeViewSidebar;