import * as React from 'react';
import FilterBar from 'components/Forms/DataGrid/FilterBar';
import { Row, Col, Form } from 'antd';
import FilterTextbox from 'components/Forms/DataGrid/FilterTextbox';
import InfoIcon from 'components/Forms/InfoIcon';
import FilterDatePicker from 'components/Forms/DataGrid/FilterDatePicker';
import { FilterType, JoinType, RoleStatus } from '../../../../Core/Api/Api';
import FilterDateRangePicker from 'components/Forms/DataGrid/FilterDateRangePicker';
import FilterSelectbox from 'components/Forms/DataGrid/FilterSelectbox';
import UserHandler from '../../UserHandler';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import RoleHandler from 'components/Admin/RoleHandler';
import Utility from 'Core/Utility';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';

export interface Props {
  children?: React.ReactNode;
}

export interface State {
  countries: ISelectOption[];
  jurisdictions: ISelectOption[];
  roles: ISelectOption[];
  roleStatus: ISelectOption[];
}
export default class RolesListFilterBar extends React.Component<Props, State> {
  handler: RoleHandler;

  constructor(props: Props) {
    super(props);

    this.state = {
      countries: [],
      jurisdictions: [],
      roles: [],
      roleStatus: [],
    };
    this.handler = new RoleHandler(props);
  }

  componentDidMount() {
    this.getJurisdictions();
    this.getRoleStatus();
  }
  getRoleStatus() {
    const roleStatus = Utility.enumToSelectionOptionArray(RoleStatus);
    this.setState({ roleStatus });
  }

  getJurisdictions() {

  }

  render() {
    return (
      <FilterBar>
        <Row>
          <Col span={12}>
            <Form.Item label="Search" labelAlign="left">
              <FilterTextbox
                filterFields="name,description"
                filterJoin={JoinType.Or}
                suffix={<InfoIcon text="Search name" />}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label="Status" labelAlign="left">
              <FilterSelectbox
                filterFields="status"
                filterType={FilterType.Equal}
                showSearch={true}
                allowClear={true}
                filterJoin={JoinType.And}
                placeholder={'Select Status'}
                options={this.state.roleStatus}
              />
            </Form.Item>
          </Col>
        </Row>
      </FilterBar>
    );
  }
}



