import { Button, Col, Row } from "antd";
import Search from "antd/lib/input/Search";
import TableFilterContextProvider from "components/Forms/DataGrid/TableFilterContextProvider";
import SimpleSelect from "components/Forms/SimpleSelect";
import { Page } from "components/News/NewsStyled";
import { RightAlignedRow } from "components/Styled/Layout/RightAlignedRow";
import DetailsData from "Core/models/DetailsData";
import DetailsMode from "Core/models/DetailsMode";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import NewsListFilterBar from "./NewsListFilterBar";
import NewsListTable from "./NewsListTable";

export interface Props extends RouteComponentProps {
    children?: React.ReactNode
}

export interface State {
}

export class NewsListWithoutRouter extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
        }
    }

    AddNewNews = () => {
        const newDetailsData = new DetailsData();
        newDetailsData.mode = DetailsMode.New;
        this.props.history.push('news/new', newDetailsData);
    };

    render() {
        return (
            <TableFilterContextProvider>
                <RightAlignedRow>
                    <Col>
                        <Button size="large" type="primary" onClick={this.AddNewNews}>
                            Create Article
                        </Button>
                    </Col>
                </RightAlignedRow>
                <Row>
                    <Col md={24}>
                        <NewsListFilterBar />
                    </Col>
                </Row>
                <Row>
                    <Col md={24}>
                        <NewsListTable />
                    </Col>
                </Row>
            </TableFilterContextProvider>

        )
    }
}

const NewsList = withRouter(NewsListWithoutRouter);

export default NewsList;
