import React from 'react';
import LoginPage from '../Pages/LoginPage';
import ConfirmEmailPage from '../Pages/ConfirmEmailPage';
import RegisterPage from '../Pages/RegisterPage';
import AppRoute, { LayoutType } from './models/AppRoute';
import {
    AdminLayout,
    ClassicLayout,
    LoginLayout,
    LandingLayout,
    ResetLayout,
    RegisterLayout,
} from '../components/Layout';
import LogoutPage from 'Pages/LogoutPage';
import { Operations } from './Api/Api';
import NotFoundView from 'components/Error/NotFoundView';
import EmptyLayout from 'components/Layout/EmptyLayout';
//import JsixDetailsPage from 'Pages/JsixDetailsPage';
import DashboardPage from 'Pages/DashboardPage';
import FAQPage from 'Pages/Information/FAQPage';
import ContactUsPage from 'Pages/Information/ContactUsPage';
import AboutUsPage from 'Pages/Information/AboutUsPage';
import NewsPage from 'Pages/NewsPage';
import ManagementTeam from 'Pages/ManagementTeamPage';
import CenteredLandingLayout from 'components/Layout/CenteredLandingLayout';
import ResetPasswordPage from 'Pages/UserProfile/ResetPasswordPage';
import RequestPasswordResetPage from 'Pages/RequestPasswordResetPage';
import UserProfilePage from '../Pages/UserProfile/UserProfilePage';
import EditUserProfilePage from '../Pages/UserProfile/EditUserProfilePage';
import ChangePasswordPage from '../Pages/UserProfile/ChangePasswordPage';
import UpdateSecurityQuestionPage from '../Pages/UserProfile/UpdateSecurityQuestionPage';
import ResendActivationPage from '../Pages/ResendActivationPage';
import AdminProjectListPage from '../Pages/Admin/ProjectManagement/AdminProjectListPage';
import AuditListPage from '../Pages/Admin/AuditListPage';
import DonationListPage from '../Pages/Admin/DonationListPage';
import PaymentsListPage from '../Pages/Admin/PaymentsListPage';
import PledgeListPage from '../Pages/Admin/PledgeListPage';
import RolesListPage from '../Pages/Admin/RolesListPage';
import RolesDetailsPage from '../Pages/Admin/RolesDetailsPage';
import UserListPage from '../Pages/Admin/UserListPage';
import UserDetailsPage from '../Pages/Admin/UserDetailsPage';

import PaymentDetailsPage from '../Pages/Admin/PaymentDetailsPage/PaymentDetailsPage';
import DashboardImagePage from '../Pages/Admin/DashboardImagePage/DashboardImagePage';
import dashboardImagePng from '../assets/images/dashboardImageIcon.png'
import DonationDetailsPage from '../Pages/Admin/DonationDetailsPage/DonationDetailsPage';
import PledgeDetailsPage from 'Pages/Admin/PledgeDetailsPage/PledgeDetailsPage';
import NewsListPage from 'Pages/NewsManagement/NewsListPage';
import { AddNewsPage } from 'Pages/NewsManagement/AddNewsPage';
import { EditNewsPage } from 'Pages/NewsManagement/EditNewsPage';
import { ViewNewsPage } from 'Pages/NewsManagement/ViewNewsPage';
import AdminProjectDetailPage from 'Pages/Admin/ProjectManagement/AdminProjectDetailPage';
import AdminProjectUpdateListPage from 'Pages/Admin/ProjectUpdateManagement/AdminProjectUpdateListPage';

import AuditIconPng from '../assets/images/icons/Audit.png';
import DashboardUpdateIconPng from '../assets/images/icons/DashboardUpdate.png';
import DonationIconPng from '../assets/images/icons/Donation.png';
import HomeIconPng from '../assets/images/icons/Home.png';
import NewsIconPng from '../assets/images/icons/News.png';
import PaymentIconPng from '../assets/images/icons/Payment.png';
import PledgeIconPng from '../assets/images/icons/Pledge.png';
import ProjectManagementIconPng from '../assets/images/icons/ProjectManagement.png';
import RolesIconPng from '../assets/images/icons/Roles.png';
import UsersIconPng from '../assets/images/icons/User.png';
import WelcomeUsersIconPng from '../assets/images/icons/WelcomeUser.png';


export const AppRoutes: AppRoute[] = [
    {
        title: 'Home',
        component: () => <h1> Home !</h1>,
        path: '/',
        redirect: '/login',
        icon: 'star',
        hideInMainMenu: true,
        exact: true,
        skip: true,
        routes: [
            {
                title: 'Home',
                hideInMainMenu: false,
                icon: 'area-chart',
                layout: (props: any) => <AdminLayout {...props} />,
                path: 'dashboard',
                component: DashboardPage,
            },
            {
                title: 'Dashboard',
                icon: 'area-chart',
                hideInMainMenu: true,

                layout: (props: any) => <AdminLayout {...props} />,
                path: 'welcome',
                component: DashboardPage,
            },
            // {
            //     title: 'My Accounts',
            //     icon: 'wallet',
            //     layout: (props: any) => <LandingLayout {...props} />,
            //     requiredOperations: [Operations.Is_Authenticated],
            //     path: 'jcsd-accounts',
            //     component: JcsdAccountListPage,
            // },
            // {
            //     title: 'My Applications',
            //     icon: 'wallet',
            //     layout: (props: any) => <LandingLayout {...props} />,
            //     requiredOperations: [Operations.Is_Authenticated],
            //     path: 'applications',
            //     component: OfferingApplicationListPage,
            // },

            {
                title: 'Settings',
                icon: 'cog',
                hideInMainMenu: true,
                path: 'settings',
                component: () => <h1> settings </h1>,
                routes: [
                    {
                        title: 'Account',
                        hideInMainMenu: true,
                        icon: 'user',
                        layout: (props: any) => <LandingLayout {...props} />,
                        path: 'settings/account',
                        component: () => <h1> Account </h1>,
                    },
                ],
            },

            {
                title: 'Change Password',
                path: '/change-password',
                icon: 'folder',
                layout: (props: any) => <AdminLayout {...props} />,
                requiredOperations: [Operations.Is_Authenticated],
                hideInMainMenu: true,
                exact: true,
                component: ChangePasswordPage
            },
            {
                title: 'Update Security Questions',
                path: '/update-security',
                icon: 'folder',
                layout: (props: any) => <AdminLayout {...props} />,
                requiredOperations: [Operations.Is_Authenticated],
                hideInMainMenu: true,
                exact: true,
                component: UpdateSecurityQuestionPage
            },
            {
                title: 'User Profile',
                path: '/user-profile',
                icon: 'folder',
                layout: (props: any) => <AdminLayout {...props} />,
                requiredOperations: [Operations.Is_Authenticated],
                hideInMainMenu: true,
                exact: true,
                component: UserProfilePage
            },
            {
                title: 'Edit User Profile',
                path: '/edit-user-profile',
                icon: 'folder',
                layout: (props: any) => <AdminLayout {...props} />,
                requiredOperations: [Operations.Is_Authenticated],
                hideInMainMenu: true,
                exact: true,
                component: EditUserProfilePage
            },
        ],
    },
    {
        title: 'Admin',
        path: '/admin',
        icon: 'user',
        exact: true,
        skip: true,
        layout: (props: any) => <AdminLayout {...props} />,
        redirect: '/admin/users',
        requiredOperations: [Operations.Is_Authenticated],
        routes: [
            {
                title: 'Dashboard Management',
                path: 'dashboard-management',
                icon: <img style={{ width: "16px", height: "16px" }} src={dashboardImagePng} />,
                exact: true,
                requiredOperations: [Operations.Edit_Landing_Images],
                component: DashboardImagePage,
            },
            {
                title: 'Projects',
                path: 'projects',
                icon: <img style={{ width: "16px", height: "16px" }} src={ProjectManagementIconPng} />,
                exact: true,
                requiredOperations: [Operations.View_Project_Details],
                component: AdminProjectListPage,
            },
            {
                title: 'Projects',
                path: 'projects/:id',
                icon: 'user',
                hideInMainMenu: true,
                requiredOperations: [Operations.View_Project_Details],
                exact: true,
                component: AdminProjectDetailPage,
            },
            {
                title: 'Project Updates',
                path: 'project-updates',
                icon: <img style={{ width: "16px", height: "16px" }} src={ProjectManagementIconPng} />,
                exact: true,
                requiredOperations: [Operations.View_Project_Details],
                component: AdminProjectUpdateListPage,
                routes: [
                    {
                        title: 'Project Update Management',
                        path: ':id',
                        icon: 'user',
                        hideInMainMenu: true,
                        requiredOperations: [Operations.View_Project_Details],
                        exact: true,
                        component: AdminProjectUpdateListPage,
                    },
                ]
            },
            {
                title: 'Donations',
                path: 'donation',
                icon: <img style={{ width: "16px", height: "16px" }} src={DonationIconPng} />,
                exact: true,
                requiredOperations: [Operations.View_all_donations],
                component: DonationListPage,
            },

            {
                title: 'Donations',
                path: 'donation/:name',
                icon: 'user',
                hideInMainMenu: true,
                requiredOperations: [Operations.View_all_donations],
                exact: true,
                component: DonationDetailsPage,
            },
            {
                title: 'Payments',
                path: 'payments',
                icon: <img style={{ width: "16px", height: "16px" }} src={PaymentIconPng} />,
                exact: true,
                requiredOperations: [Operations.View_all_payments],
                component: PaymentsListPage,
            },
            {
                title: 'Payments',
                path: 'payments/:name',
                icon: 'user',
                hideInMainMenu: true,
                requiredOperations: [Operations.View_all_payments],
                exact: true,
                component: PaymentDetailsPage,
            },
            {
                title: 'Pledges',
                path: 'pledges',
                icon: <img style={{ width: "16px", height: "16px" }} src={PledgeIconPng} />,
                exact: true,
                requiredOperations: [Operations.View_all_payments],
                component: PledgeListPage,
            },
            {
                title: 'Pledges',
                path: 'pledges/:id',
                icon: 'user',
                hideInMainMenu: true,
                requiredOperations: [Operations.View_all_payments],
                exact: true,
                component: PledgeListPage,
            },
            {
                title: 'News',
                path: 'news',
                icon: <img style={{ width: "16px", height: "16px" }} src={NewsIconPng} />,
                exact: true,
                requiredOperations: [Operations.View_News],
                component: NewsListPage,
                routes: [
                    {
                        title: 'Add News',
                        path: 'new',
                        icon: 'newspaper',
                        exact: true,
                        hideInMainMenu: true,
                        requiredOperations: [Operations.Create_News],
                        component: AddNewsPage,

                    },
                    {
                        title: 'Edit News',
                        path: 'edit/:id',
                        icon: 'newspaper',
                        exact: true,
                        hideInMainMenu: true,
                        requiredOperations: [Operations.Edit_News],
                        component: EditNewsPage,

                    },
                    {
                        title: 'View News',
                        path: 'view/:id',
                        icon: 'newspaper',
                        exact: true,
                        hideInMainMenu: true,
                        requiredOperations: [Operations.View_News],
                        component: ViewNewsPage,

                    }
                ]
            },
            {
                title: 'Users',
                path: 'users',
                icon: <img style={{ width: "16px", height: "16px" }} src={UsersIconPng} />,
                exact: true,
                requiredOperations: [Operations.View_users],
                component: UserListPage,
            },
            {
                title: 'User Details',
                path: 'users/:name',
                icon: 'user',
                hideInMainMenu: true,
                requiredOperations: [Operations.View_users],
                exact: true,
                component: UserDetailsPage,
            },
            {
                title: 'Roles',
                path: 'roles',
                icon: <img style={{ width: "16px", height: "16px" }} src={RolesIconPng} />,
                exact: true,
                requiredOperations: [Operations.View_roles],
                component: RolesListPage,
            },
            {
                title: 'Role Details',
                path: 'roles/:name',
                icon: 'group',
                hideInMainMenu: true,
                requiredOperations: [Operations.View_roles],
                exact: true,
                component: RolesDetailsPage,
            },
            {
                title: 'Audit',
                path: 'audits',
                icon: <img style={{ width: "16px", height: "16px" }} src={AuditIconPng} />,
                hideInMainMenu: true,
                exact: true,
                requiredOperations: [Operations.View_audit],
                component: AuditListPage,
            },
            //{
            //    title: 'Audit',
            //    path: 'audits/:id',
            //    icon: 'time',
            //    hideInMainMenu: true,
            //    exact: true,
            //    requiredOperations: [Operations.View_audit],
            //    component: AuditDetailsPage,
            //},
        ],
    },
    {
        title: 'Logout',
        path: '/logout',
        icon: 'power',
        exact: true,
        hideInMainMenu: true,
        layout: (props: any) => <LoginLayout {...props} />,
        component: LogoutPage,
    },
    {
        title: 'Login',
        path: '/login',
        icon: 'up',
        exact: true,
        hideInMainMenu: true,
        layout: (props: any) => <LoginLayout {...props} />,
        component: LoginPage,
    },
    {
        title: 'About Us',
        path: '/about-us',
        hideInMainMenu: true,
        layout: (props: any) => <LandingLayout {...props} />,
        component: AboutUsPage,
    },
   
    {
        title: 'News',
        path: '/news',
        hideInMainMenu: true,
        layout: (props: any) => <LandingLayout {...props} />,
        component: NewsPage,
    },
    {
        title: 'Management Team',
        path: '/management-team',
        hideInMainMenu: true,
        layout: (props: any) => <LandingLayout {...props} />,
        component: ManagementTeam,
    },
    {
        title: 'Contact Us',
        path: '/contact-us',
        hideInMainMenu: true,
        layout: (props: any) => <LandingLayout {...props} />,
        component: ContactUsPage,
    },
    //{
    //    title: 'View JCSD Accounts',
    //    path: '/view-jcsd-accounts',
    //    icon: 'up',
    //    exact: true,
    //    hideInMainMenu: false,
    //    layout: (props: any) => <ClassicLayout {...props} />,
    //    component: ConfirmEmailPage, /* JcsdAccountListPage,*/
    //},
    {
        title: 'Confirm Email',
        path: '/confirm',
        icon: 'up',
        exact: true,
        hideInMainMenu: true,
        layout: (props: any) => <LoginLayout {...props} />,
        component: ConfirmEmailPage,
    },


    {
        title: 'Terms',
        path: '/terms',
        exact: true,
        hideInMainMenu: true,
    },
    {
        title: 'Privacy',
        path: '/privacy',
        exact: true,
        hideInMainMenu: true,
    },
    {
        title: 'Register',
        path: '/register',
        icon: 'up',
        exact: true,
        hideInMainMenu: true,
        layout: (props: any) => <RegisterLayout {...props} />,
        component: RegisterPage,
    },
    {
        title: 'Reset Password',
        path: '/reset-password',
        hideInMainMenu: true,
        layout: (props: any) => <ResetLayout {...props} />,
        component: RequestPasswordResetPage,
    },
    {
        title: 'Resend Activation',
        path: '/resend-activation',
        hideInMainMenu: true,
        layout: (props: any) => <ResetLayout {...props} />,
        component: ResendActivationPage,
    },
    {
        title: 'Reset Password',
        path: '/reset-password-confirm',
        hideInMainMenu: true,
        layout: (props: any) => <ResetLayout {...props} />,
        component: ResetPasswordPage,
    },
    {
        title: '404',
        path: '/*',
        hideInMainMenu: true,
        layout: (props: any) => <EmptyLayout {...props} />,
        component: NotFoundView,
    },
];










