import * as React from 'react';
import styled from 'styled-components';
import { Layout, Menu, Row } from 'antd';
import ScreenHelper from '../../Styled/ScreenHelper';


export const AntdIconMenu = styled(Menu)`
.ant-menu-item, 
.ant-menu-sub.ant-menu-inline > .ant-menu-item {
  margin-top: 0px;
  height: fit-content; 
  line-height:20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  vertical-align: middle;
  span {
    margin: auto;
  }
  ${ScreenHelper.down('sm')} {
      height: 50px;
    }
  img {
    display: block;
    height: 40px;
    width: 40px;
    margin: auto;
    ${ScreenHelper.down('sm')} {
      height: 32px;
      width: 32px;
    }
  }
  span {
    
  }
}

`;

export const TitleSpan = styled.span`
    margin: auto;
    text-align: center;
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; 
  `

export const MobileTitleSpan = styled.span`
    display:table;
    text-align: left;
    white-space: pre-wrap; /* css-3 */
    white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
    white-space: -pre-wrap; /* Opera 4-6 */
    white-space: -o-pre-wrap; /* Opera 7 */
    word-wrap: break-word; 
  `
