import { Row, Col } from 'antd';
import { CenteredFormContent, BannerDiv } from 'components/Layout/LoginLayout/LoginLayout.Style';
import EditNewsForm from 'components/NewsManagement/EditNewsForm';
import { ReactNode } from 'react';
import * as React from 'react';
import { PageTitle } from 'components/Layout/PageTitle';

interface EditNewsPageProps {
  children?: ReactNode;
}

export function EditNewsPage({ children }: EditNewsPageProps) {
  return (
    <CenteredFormContent>
      <BannerDiv className='admin'>
        <PageTitle title={"News"} />
      </BannerDiv>
      <Row type="flex" align="middle" justify="center">
        <Col span={21} offset={1}>
          <EditNewsForm />
        </Col>
      </Row>

    </CenteredFormContent>
  );
}
