import * as React from 'react';

import { RouteComponentProps, NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { startCase } from 'lodash';
import { RightAlignedRow } from '../Styled/Layout/RightAlignedRow';
import { Page, NewsListing, Banner } from './NewsStyled';
import TableFilterContextProvider from '../Forms/DataGrid/TableFilterContextProvider';
import { Avatar, Button, Card, Col, Icon, Input, Progress, Row } from 'antd';
import ISelectOption from '../Forms/SimpleSelect/ISelectOption';
import SimpleSelect from '../Forms/SimpleSelect';

const { Search } = Input;



export interface Props extends RouteComponentProps {
    children?: React.ReactNode;
}

export interface State {
    news?: any[];

}

export class NewsWithoutRouter extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        this.getNews();
    }


    getNews() {
        this.setState({
            news: [{ title: 'The Jamaica Stock Exchange & You podcast is now available', img: "#", date: 'April 9, 2022', text: 'The Jamaica Stock Exchange & You Podcast is a podcast of the Jamaica Stock Exchange helping its listeners to demystify the capita market...' },
                { title: 'The Jamaica Stock Exchange & You podcast is now available', img: "#", date: 'April 9, 2022', text: 'The Jamaica Stock Exchange & You Podcast is a podcast of the Jamaica Stock Exchange helping its listeners to demystify the capita market...' },
                { title: 'The Jamaica Stock Exchange & You podcast is now available', img: "#", date: 'April 9, 2022', text: 'The Jamaica Stock Exchange & You Podcast is a podcast of the Jamaica Stock Exchange helping its listeners to demystify the capita market...' },
                { title: 'The Jamaica Stock Exchange & You podcast is now available', img: "#", date: 'April 9, 2022', text: 'The Jamaica Stock Exchange & You Podcast is a podcast of the Jamaica Stock Exchange helping its listeners to demystify the capita market...' },
                { title: 'The Jamaica Stock Exchange & You podcast is now available', img: "#", date: 'April 9, 2022', text: 'The Jamaica Stock Exchange & You Podcast is a podcast of the Jamaica Stock Exchange helping its listeners to demystify the capita market...' },
                { title: 'The Jamaica Stock Exchange & You podcast is now available', img: "#", date: 'April 9, 2022', text: 'The Jamaica Stock Exchange & You Podcast is a podcast of the Jamaica Stock Exchange helping its listeners to demystify the capita market...' },
            ]
        });
    }

    render() {
        return (
            <Page>
                <TableFilterContextProvider>
                    <Banner className={"news"}>
                        <Row style={{ height: '100%' }}>
                            <Col>
                                <div>
                                    <a className="backToHome" target="">Back to Home</a>
                                    <Button className={'donateHeader'}>Donate</Button>
                                    <Button className={'volunteer'}>Volunteer</Button>
                                </div>
                            </Col>
                        </Row>
                    </Banner>
                    <NewsListing>
                        <Row>
                            <Col span={18} className={'items'}>
                                {this.state.news?.map(item => {
                                    return <Col className={'newsItem'}>
                                        <Col className={'newsImage'} xs={{ span: 24 }}>
                                            <div className="center-avatar"> <Avatar src={item.img} shape="square" />
                                            </div>
                                        </Col>
                                        <Col className={'newsBody'} xs={{ span: 24 }}>
                                            <p className={'itemTitle'}>{item.title}</p>
                                            <p className={'itemDate'}>{item.date}</p>
                                            <p className={'itemText'}>{item.text}</p>
                                        </Col>
                                        <Col className={'newsButtons'} xs={{ span: 24 }}>
                                            <Col style={{ display: 'inline-block', paddingRight: '1px' }}><Button className={'readMore'}>Read More</Button></Col>
                                            <Col style={{ display: 'inline-block', paddingRight: '1px' }}><Button className={'share'}>Share</Button></Col>
                                        </Col>
                                    </Col>
                                })}
                            </Col>
                        </Row>
                    </NewsListing>
                </TableFilterContextProvider>
            </Page>
        );
    }
}

const News = withRouter(NewsWithoutRouter);

export default News;











