import * as React from 'react';
import FilterBar from 'components/Forms/DataGrid/FilterBar';
import { Row, Col, Form } from 'antd';
import FilterTextbox from 'components/Forms/DataGrid/FilterTextbox';
import InfoIcon from 'components/Forms/InfoIcon';
import { FilterType, JoinType, PaymentStatus } from '../../../../Core/Api/Api';
import FilterSelectbox from 'components/Forms/DataGrid/FilterSelectbox';
import Utility from 'Core/Utility';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import PaymentHandler from '../../PaymentHandler';
import FilterDateRangePicker from 'components/Forms/DataGrid/FilterDateRangePicker';

export interface Props {
  children?: React.ReactNode;
}

export interface State {
  countries: ISelectOption[];
  jurisdictions: ISelectOption[];
  roles: ISelectOption[];
  roleStatus: ISelectOption[];
}
export default class PaymentListFilterBar extends React.Component<Props, State> {
  handler: PaymentHandler;

  constructor(props: Props) {
    super(props);

    this.state = {
      countries: [],
      jurisdictions: [],
      roles: [],
      roleStatus: [],
    };
    this.handler = new PaymentHandler(props);
  }

  componentDidMount() {
    this.getRoleStatus();
  }
  getRoleStatus() {
    const roleStatus = Utility.enumToSelectionOptionArray(PaymentStatus, true);
    this.setState({ roleStatus });
  }

  render() {
    return (
      <FilterBar>
        <Row>
          {/* <Col span={8}>
            <Form.Item labelCol={{ span: 3 }} label="Invoice #" labelAlign="left">
              <FilterTextbox
                filterFields="id"
                filterType={FilterType.Equal}
                filterJoin={JoinType.Or}
                suffix={<InfoIcon text="Enter Invoice Number" />}
              />
            </Form.Item>
          </Col> */}
          <Col span={5}>
            <Form.Item labelCol={{ span: 6 }} label="Search" labelAlign="left">
              <FilterTextbox
                filterFields="user.firstName, user.LastName"
                filterJoin={JoinType.Or}
                suffix={<InfoIcon text="Search name or project" />}
              />
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item labelCol={{ span: 6 }} label="Status" labelAlign="left">
              <FilterSelectbox
                filterFields="paymentStatus"
                filterType={FilterType.Equal}
                showSearch={true}
                allowClear={true}
                filterJoin={JoinType.And}
                placeholder={'Select Status'}
                options={this.state.roleStatus}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item labelCol={{ span: 6 }} label="Payment Date" labelAlign="left">
              <FilterDateRangePicker
                filterFields="paymentDate"
                filterType={FilterType.Equal}

                allowClear={true}
                filterJoin={JoinType.And}

              />
            </Form.Item>
          </Col>
        </Row>
      </FilterBar>
    );
  }
}



