import { Row, Col } from 'antd';
import { CenteredFormContent, BannerDiv } from 'components/Layout/LoginLayout/LoginLayout.Style';
import { ReactNode } from 'react';
import * as React from 'react';
import ViewNewsView from 'components/NewsManagement/ViewNewsView';
import { PageTitle } from 'components/Layout/PageTitle';

interface ViewNewsPageProps {
  children?: ReactNode;
}

export function ViewNewsPage({ children }: ViewNewsPageProps) {
  return (
    <CenteredFormContent>
      <BannerDiv className='admin'>
        <PageTitle title={"News"} />
      </BannerDiv>
      <Row type="flex" align="middle" justify="center">
        <Col span={21} offset={1}>
          <ViewNewsView />
        </Col>
      </Row>

    </CenteredFormContent>
  );
}
