import * as React from 'react';
import { Menu, Icon } from 'antd';
import { NavLink } from 'react-router-dom';
import MenuAvatar from '../MenuAvatar';
import styled from 'styled-components';
import AuthContext from '../../../Context/AuthContext';

export interface Props {
    children?: React.ReactNode;
}

export interface State { }

export default class UserBar extends React.Component<Props, State> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <Menu mode="horizontal" theme="dark">
                <Menu.SubMenu
                    title={
                        <span style={{
                            fontWeight : 500
                        }}>
                            <MenuAvatar shape="circle" icon="user" />
                            &nbsp;

              {this.context.data ? "Welcome " + this.context.data.firstName : ''}
                        </span>
                    }>
                   
                    <Menu.Item>
                        <NavLink to="/user-profile">
                            <Icon type="folder" style={{ color: '#F0403D' }}/>
                            User Profile
            </NavLink>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item>
                        <NavLink to="/logout">
                            <Icon type="poweroff" style={{ color: '#F0403D' }}/>
                            Logout
            </NavLink>
                    </Menu.Item>
                </Menu.SubMenu>
            </Menu>
        );
    }
}
















