import { Layout, Row, Col, Icon } from 'antd';
import * as React from 'react';
import Sidebar from './Sidebar';
import { AppRoutes } from '../../../Core/AppRoutes';
import ILayout from '../../../Core/models/ILayout';
import AuthBar from '../AuthBar';
import { LightHeader } from '../ClassicLayout/TopBar/TopBar.Styled';
import { AdminContent } from './AdminLayout.Style';
import { SFooter } from '../Footer.Styled'
import Title from 'antd/lib/typography/Title';
import { NavLink } from 'react-router-dom';
import Paragraph from 'antd/lib/typography/Paragraph';

const { Header, Footer } = Layout;

export interface Props extends ILayout {
  children?: React.ReactNode;
}

export interface State { }

export default class AdminLayout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <Layout
        className="react-transition fade-in"
        style={{ minHeight: '100vh', animationDuration: '750ms' }}>
        <Sidebar menu={AppRoutes} />
        <Layout>
          <LightHeader style={{ height: '46px', color: 'green', backgroundColor: "#262626" }}>
            <Row type="flex" justify="end">
              <Col md={4}>
                <AuthBar />
              </Col>
            </Row>
          </LightHeader>
          <AdminContent>
            {this.props.children}
          </AdminContent>
          <SFooter>
            <Row>
              <Col xs={12}>
                <Paragraph style={{ color: 'white' }}>
                  <Icon type="copyright" /> Copyright 2023 </Paragraph>
              </Col>
              <Col xs={12}>
              </Col>
            </Row>
          </SFooter>
        </Layout>
      </Layout>
    );
  }
}
















