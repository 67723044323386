import { ReactNode, useContext, useEffect, useState } from 'react';
import * as React from 'react';
import { Button, Col, Form, Row, Typography } from 'antd';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { FormComponentProps } from 'antd/lib/form';
import { NavLink, useHistory } from 'react-router-dom';
import { NewsHandler } from '../NewsHandler';
import { NewsModel, News, NewsStatus, Operations } from 'Core/Api/Api';
import NotificationService from 'Core/NotificationService';
import { useParams } from 'react-router';
import { startCase } from 'lodash';
import ButtonGroup from 'components/Styled/ButtonGroup';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import AuthContext from 'Context/AuthContext';
import Utility from 'Core/Utility';
import { debug } from 'console';
import { NewsApprovalSidebar } from './NewsApprovalSidebar';
import { NewsPublishSidebar } from './NewsPublishSidebar';
import { NewsArchiveSidebar } from './NewsArchiveSidebar';
import styled from 'styled-components';
import moment from 'moment';
import ConfigService from 'Core/ConfigService';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import { ApprovalsDisplay } from './ApprovalsDisplay';
import { ColoredPill } from 'components/Forms/ColoredPill';
import { RichTextViewer } from 'components/Forms/RichTextViewer';

interface ViewNewsViewProps extends FormComponentProps<NewsModel> {
  children?: ReactNode;
}

const ArticleContainerGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr max-content;
  grid-template-rows: min-content min-content min-content 1fr;
  gap: 10px;
  column-gap: 20px;
  grid-template-areas: 
    "title title title title"
    "info info info side"
    "image image image side"
    "body body body side"
  ;

`;

const ArticleTitle = styled.div`
  grid-area:  title ;

`;

const ArticleInfo = styled.div`
  grid-area:  info ;

  display: flex;
  flex-direction:column;
  align-content: flex-start;
  gap: 10px;
`;

const ArticleImage = styled.div`
  grid-area: image;

 img {
  max-width: min(500px, 100%);
  height: auto;
 }
`;

const ArticleBody = styled.div`
  grid-area:  body ;

  
`;

const ArticleSide = styled.div`
  grid-area: side ;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction:column;
  align-content: flex-start;
  gap: 10px;
`;

const ArticleActions = styled.div`
  grid-area:  footer ;

`



export function ViewNewsView({ children, form }: ViewNewsViewProps) {

  let getFieldDecorator = form?.getFieldDecorator;
  let history = useHistory();
  let params = useParams<{ id?: string }>();
  let newsHandler = new NewsHandler({});
  let { data: userInfo } = useContext(AuthContext);
  let config = ConfigService.getSync();

  const [canApproveArticle, setCanApproveArticle] = useState(false);
  const [canPublishArticle, setCanPublishArticle] = useState(false);
  const [canArchiveArticle, setCanArchiveArticle] = useState(false);
  const [canEditArticle, setCanEditArticle] = useState(false);
  const [showApprovalSidebar, setShowApprovalSidebar] = useState(false);
  const [showPublishSidebar, setshowPublishSidebar] = useState(false);
  const [showArchiveSidebar, setshowArchiveSidebar] = useState(false);

  const [news, setNews] = useState<News | undefined>(undefined);

  useEffect(() => {
    if (!news) { return; }
    if (userInfo?.id !== news?.createdById
      && (news?.status !== NewsStatus.Published && news?.status !== NewsStatus.Archived)
      && Utility.HasOperations(userInfo, [Operations.Approve_News])) {
      setCanApproveArticle(true);
    } else {
      setCanApproveArticle(false);

    }

    if ((news?.status == NewsStatus.Approved || news.status == NewsStatus.Archived)
      && Utility.HasOperations(userInfo, [Operations.Publish_News])) {
      setCanPublishArticle(true);
    } else {
      setCanPublishArticle(false);

    }

    if (news?.status == NewsStatus.Published
      && Utility.HasOperations(userInfo, [Operations.Archive_News])) {
      setCanArchiveArticle(true);
    } else {
      setCanArchiveArticle(false);

    }

    if (Utility.HasOperations(userInfo, [Operations.Edit_News])) {
      setCanEditArticle(true);
    } else {
      setCanEditArticle(false);

    }
  }, [news])

  useEffect(() => {
    if (params.id) {
      newsHandler.GetNewsByIdForAdmin({ id: params.id }).then(response => {
        setNewsForm(response.news);
      })
    }
  }, []);

  const setNewsForm = (news: News | undefined) => {
    setNews(news);
    form.setFieldsValue(news ?? {});

  }

  const EditNews = () => {
    const editDetailsData = new DetailsData(DetailsMode.Edit, news?.id)
    history.push('../edit/' + encodeURIComponent(news?.id ?? ''), editDetailsData);
  };




  return (<>

    <LeftAlignedRow >
      <Col>
        <ArticleContainerGrid>
          <ArticleTitle>
            <Typography.Title>{news?.title}</Typography.Title>
          </ArticleTitle>
          <ArticleInfo>
            <Typography.Text type='secondary'><b>name:</b> {news?.name}</Typography.Text>
            <Typography.Text><b>Overview:</b> {news?.overview} </Typography.Text>
            <ShowIfTrue condition={news?.link}>
              <Typography.Text><b>Link:</b> {news?.link}</Typography.Text>

            </ShowIfTrue>
          </ArticleInfo>
          <ArticleImage>
            <img src={news?.mainImageLink != null ? news?.mainImageLink : news?.newsDataFile?.url} />
          </ArticleImage>
          <ArticleSide>
            <Typography.Text><b>Author:</b> {news?.createdBy}</Typography.Text>
            <Typography.Text><b>Created On:</b> {Utility.renderDateWithReference(moment(news?.creationDate), config.Display.DateTimeFormat)}</Typography.Text>
            <ShowIfTrue condition={news?.creationDate !== news?.modificationDate}>
              <Typography.Text><b>Last Updated By:</b> {news?.modifiedBy}</Typography.Text>
              <Typography.Text><b>Last Updated On:</b> {Utility.renderDateWithReference(moment(news?.modificationDate), config.Display.DateTimeFormat)} </Typography.Text>
            </ShowIfTrue>
            <Typography.Text><b>Status:</b> <ColoredPill
              value={startCase(news?.status)}
              options={[{ color: "green", value: "Approved" },
              { color: "red", value: "Rejected" },
              { color: "#87d068", value: "Published" }]} />  </Typography.Text>
            <p>
              <Typography.Title level={4} underline><b>Approvals:</b></Typography.Title>
              <ApprovalsDisplay approvals={news?.newsApprovals} />
            </p>
            <ArticleActions>
              <Col span={20}>
                <Form.Item
                  colon={false}>
                  <ButtonGroup>
                    <Button onClick={() => history.goBack()} type="default">
                      Back
                    </Button>
                    <ShowIfTrue condition={canApproveArticle}>
                      <Button onClick={() => setShowApprovalSidebar(true)}>Reject / Approve Article</Button>
                    </ShowIfTrue>
                    <ShowIfTrue condition={canPublishArticle}>
                      <Button onClick={() => setshowPublishSidebar(true)}>Publish</Button>
                    </ShowIfTrue>
                    <ShowIfTrue condition={canArchiveArticle}>
                      <Button onClick={() => setshowArchiveSidebar(true)}>Archive</Button>
                    </ShowIfTrue>
                    <ShowIfTrue condition={canEditArticle}>

                      <Button onClick={EditNews}>Edit</Button>

                    </ShowIfTrue>
                  </ButtonGroup>
                </Form.Item>
              </Col>
            </ArticleActions>
          </ArticleSide>
          <ArticleBody>
            <RichTextViewer value={news?.description} />
          </ArticleBody>


        </ArticleContainerGrid>
      </Col>
    </LeftAlignedRow >
    <NewsApprovalSidebar news={news} visible={showApprovalSidebar} onClose={() => setShowApprovalSidebar(false)} onSubmitted={(news) => { setNews(news); setShowApprovalSidebar(false) }} />
    <NewsPublishSidebar news={news} visible={showPublishSidebar} onClose={() => setshowPublishSidebar(false)} onSubmitted={(news) => { setNews(news); setshowPublishSidebar(false) }} />
    <NewsArchiveSidebar news={news} visible={showArchiveSidebar} onClose={() => setshowArchiveSidebar(false)} onSubmitted={(news) => { setNews(news); setshowArchiveSidebar(false) }} />
  </>
  );
}

export default Form.create({ name: "view-news-view" })(ViewNewsView);


