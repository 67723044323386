import { ColumnProps } from "antd/lib/table";
import SimpleTable from "components/Forms/DataGrid/SimpleTable";
import { News, NewsListModel, Operations } from "Core/Api/Api";
import ConfigService from "Core/ConfigService";
import DetailsData from "Core/models/DetailsData";
import DetailsMode from "Core/models/DetailsMode";
import Utility from "Core/Utility";
import moment from "moment";
import * as React from "react";
import { NavLink } from "react-router-dom";
import { NewsHandler } from "../../NewsHandler";
import { startCase } from 'lodash/';
import ShowIfHavePermission from "components/Forms/ShowIfHavePermission";
import { Icon } from "antd";
export interface Props {
    children?: React.ReactNode
}

export interface State {
}

export default class NewsListTable extends React.Component<Props, State> {

    handler = new NewsHandler({});
    config = ConfigService.getSync();
    constructor(props: Props) {
        super(props)

        this.state = {
        }
    }

    columns: Array<ColumnProps<NewsListModel>> = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: true
        },
        {
            title: "Author",
            dataIndex: "createdBy",
            sorter: true,
        },
        {
            title: "Status",
            dataIndex: "status",
            sorter: true,
            render: (text) => startCase(text),

        },
        {
            title: "Created",
            dataIndex: "creationDate",
            sorter: true,
            render: (text) => !text ? "" : Utility.renderDateWithReference(moment(text), this.config.Display.DateTimeFormat),
        },
        {
            title: "Published",
            dataIndex: "publishDate",
            sorter: true,
            render: (text) => !text ? "" : Utility.renderDateWithReference(moment(text), this.config.Display.DateTimeFormat),
        },
        {
            title: '',
            dataIndex: "id",
            render: (text: any, record: News, index: number) => {
                return (
                    <div style={{ display: "flex", gap: "5px" }}>
                        <ShowIfHavePermission operations={[Operations.View_News]}>

                            <NavLink
                                to={{
                                    pathname: 'news/view/' + encodeURIComponent(record.id ?? ''),
                                    state: new DetailsData(DetailsMode.View, record.id),
                                }}>
                                <Icon type="read" title="View" />
                            </NavLink>
                        </ShowIfHavePermission>
                        <ShowIfHavePermission operations={[Operations.Edit_News]}>
                            <NavLink
                                to={{
                                    pathname: 'news/edit/' + encodeURIComponent(record.id ?? ''),
                                    state: new DetailsData(DetailsMode.Edit, record.id),
                                }}>
                                <Icon type="edit" title="Edit" />
                            </NavLink>
                        </ShowIfHavePermission>

                    </div>
                );
            },
        },

    ]

    render() {
        return (
            <SimpleTable<NewsListModel> columns={this.columns} dataFetcher={this.handler} />
        )
    }
}
