import React from 'react';
import { Form, Row, Input, Col, Button, Select, Transfer, Typography } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import FormControl from 'components/Forms/FormControl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import DonationHandler from '../DonationHandler';
import ValidationUtility from '../../../Core/ValidationUtility';
import SimpleSelect from '../../Forms/SimpleSelect';
import {
    RoleStatus,
    Operations,
    User,
    Role,
    UserStatus,
    EditUserModel,
    AddUserModel,
    GetProjectByIdForAdminQueryResponse,
} from '../../../Core/Api/Api';
import Utility from 'Core/Utility';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import MaskedInput from 'components/Forms/MaskedInput';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import DetailsMode from 'Core/models/DetailsMode';
import DetailsData from 'Core/models/DetailsData';
import NotificationService from 'Core/NotificationService';
import TextArea from 'antd/lib/input/TextArea';
import { TransferItem } from 'antd/lib/transfer';
import AuthContext from 'Context/AuthContext';
import { delay } from 'lodash';
import SimpleTransfer from 'components/Forms/SimpleTransfer';
import {
    singleColFormColSize,
    FullWithButton,
    doubleColFormColSize,
} from 'components/Forms/Form.Styled';
import { LeftAlignedRow } from '../../Styled/Layout/LeftAlignedRow';

const { Title } = Typography;

export interface Props extends FormComponentProps, RouteComponentProps {
    children?: React.ReactNode;
}

export interface State {
    jurisdictions: ISelectOption[];
    operationsListOptions: TransferItem[];
    operations: string[];
    status: ISelectOption[];
    AllRoles: Role[];
    donation?: GetProjectByIdForAdminQueryResponse;
    roles: ISelectOption[];
    detailsMode: DetailsMode;
}

export class DonationFormWithoutFormAndRouter extends React.Component<Props, State> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    handler: DonationHandler;

    constructor(props: Props) {
        super(props);

        this.state = {
            jurisdictions: [],
            operations: [],
            operationsListOptions: [],
            status: [],
            roles: [],
            AllRoles: [],
            detailsMode: DetailsMode.New,
        };
        this.handler = new DonationHandler(props);
    }

    componentDidMount() {
        this.getStatus();
        this.getDonaitonDetails();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {

    }

    getDonaitonDetails() {
        const urlState = this.props.location.state as DetailsData | undefined;
        const removeHtmlRegex = /&nbsp;|(<([^>]+)>)/ig;

        if (urlState && urlState.mode == DetailsMode.Edit) {
            this.handler.GetDonationById(urlState.id ?? '').then(
                x => {
                    this.setState({ donation: x, detailsMode: DetailsMode.Edit }, () => {
                        // this.props.form.setFieldsValue({ name: x.name });
                        // this.props.form.setFieldsValue({ description: x.description?.replace(removeHtmlRegex, '') });
                        // this.props.form.setFieldsValue({ amountRequested: x.amountRequested });
                        // this.props.form.setFieldsValue({ amountReceived: x.amountReceived });
                        // this.props.form.setFieldsValue({ typeOfFunding: x.typeOfFunding });
                        // this.props.form.setFieldsValue({ fundingPurpose: x.fundingPurpose });
                        // this.props.form.setFieldsValue({ timeToFunding: x.timeToFunding });
                        // this.props.form.setFieldsValue({ companyName: x.companyName });
                        // this.props.form.setFieldsValue({ status: x.status });
                    });
                },
                x => NotificationService.error(x.message)
            );
        } else {
            this.setState({ detailsMode: DetailsMode.New });
        }
    }


    //getRoles() {
    //    this.handler.GetFilteredRoles().then(x => {
    //        this.setState({ AllRoles: x.results ?? [] }, () => {
    //            this.updateRoleList();

    //            this.props.form.setFieldsValue({
    //                roles: this.state.user?.userRoles ?? [],
    //            });
    //        });
    //    });
    //}


    //updateRoleList = () => {
    //    let filteredRoles: Role[];
    //    filteredRoles = this.state.AllRoles;

    //    const roles: IFilterSelectOption[] =
    //        filteredRoles?.map(y => ({
    //            text: y.name,
    //            value: y.id,
    //        })) ?? [];
    //    this.setState({ roles });
    //};

    getStatus() {
        var status: ISelectOption[] = [];
        Utility.enumToMap(UserStatus).forEach((x, y) => status.push({ text: x, value: y }));
        this.setState({ status });
    }

    //getOperations() {
    //    this.handler.GetOperations().then(x => {
    //        this.setState({ operations: x.operations ?? [] }, () => {
    //            this.props.form.setFieldsValue({ operations: this.state.role?.operations } ?? {});
    //            this.updateOperationsList();
    //        });
    //    });
    //}

    updateOperationsList = () => {
        let operationsList: TransferItem[] = [];

        operationsList = this.state.operations.map(x => ({ key: x, title: x }));

        this.setState({ operationsListOptions: operationsList });
    };

    onJurisdictionChange = (value: any) => {
        // This is done because the value doesn't seem to update on deselect
        delay(this.updateOperationsList, 10, value);
    };

    //onSubmit = (event: React.FormEvent) => {
    //    event.preventDefault();

    //    this.props.form.validateFieldsAndScroll((error, values: any) => {
    //        if (error) {
    //            return;
    //        }

    //        if (this.state.detailsMode == DetailsMode.Edit) {
    //            const request = new EditUserModel();
    //            request.id = this.state.user?.id;
    //            request.firstName = values.firstName;
    //            request.lastName = values.lastName;
    //            request.phoneNumber = (values as any).phoneNumber;
    //            request.emailAddress = values.emailAddress;
    //            request.userName = values.username;

    //            this.setRoles(request, values.roles as any);

    //            console.log(values, request);
    //            this.handler.EditUser(request).then(x => {
    //                this.props.history.goBack();
    //                NotificationService.success(`Updated ${x.user?.username}`);
    //            });
    //        } else {
    //            const request = new AddUserModel();
    //            request.emailAddress = values.emailAddress;
    //            request.userName = values.username;
    //            request.firstName = values.firstName;
    //            request.lastName = values.lastName;
    //            request.phoneNumber = (values as any).phoneNumber;
    //            this.setRoles(request, values.roles as any);
    //            console.log(request);
    //            this.handler.AddUser(request).then(x => {
    //                this.props.history.goBack();
    //                NotificationService.success(`Added ${x.newUser?.username}`);
    //            });
    //        }
    //    });
    //};

    setRoles(request: { roles?: string[] }, arg1: any) {
        if (arg1.map) {
            request.roles = arg1;
        } else {
            request.roles = [];
            request.roles.push(arg1);
        }
    }

    render() {
        var { getFieldDecorator } = this.props.form;
        return (
            <Row>
                <Col>
                    <Form {...doubleColFormColSize} layout="horizontal">
                        <Row>
                            <Col span={24}>

                            </Col>
                            <Col span={11}>
                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    rules={[...ValidationUtility.required('name is required')]}
                                    name="name"
                                    label="Name">
                                    <Input />
                                </FormControl>
                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    rules={[...ValidationUtility.required('description is required')]}
                                    name="description"
                                    label="Description">
                                    <TextArea />
                                </FormControl>
                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    rules={[...ValidationUtility.required('amount received is required')]}
                                    name="amountReceived"
                                    label="Amount Received">
                                    <Input />
                                </FormControl>
                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    rules={[...ValidationUtility.required('type of funding is required')]}
                                    name="typeOfFunding"
                                    label="Type of Funding">
                                    <Input />
                                </FormControl>
                            </Col>

                            <Col span={11}>
                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    rules={[...ValidationUtility.required('funding purpose is required')]}
                                    name="fundingPurpose"
                                    label="Funding Purpose">
                                    <Input />
                                </FormControl>
                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    rules={[...ValidationUtility.required('time to funding is required')]}
                                    name="timeToFunding"
                                    label="Time to Funding">
                                    <Input />
                                </FormControl>
                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    rules={[...ValidationUtility.required('company name is required')]}
                                    name="companyName"
                                    label="Company Name">
                                    <Input />
                                </FormControl>
                                <FormControl
                                    getFieldDecorator={getFieldDecorator}
                                    rules={[...ValidationUtility.required('project status is required')]}
                                    name="status"
                                    label="Project Status">
                                    <Input />
                                </FormControl>

                            </Col>
                        </Row>

                        <LeftAlignedRow>
                            <Col span={10}>
                                <Form.Item
                                    colon={false}
                                    label={
                                        <Button onClick={() => this.props.history.goBack()} type="default">
                                            Back
                                        </Button>
                                    }>
                                    <FullWithButton type="primary" htmlType="submit">
                                        Submit
                                    </FullWithButton>
                                </Form.Item>
                            </Col>
                        </LeftAlignedRow>
                    </Form>
                </Col>
            </Row>
        );
    }
}

const DonationFormWithRouter = Form.create<Props>({
    name: 'Donaiton',
})(DonationFormWithoutFormAndRouter);

const DonationForm = withRouter(DonationFormWithRouter);

export default DonationForm;
