import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { User, SlimUser, Role, PaymentListItem, Pledge, IPledgeListModel } from 'Core/Api/Api';
import SimpleTable from 'components/Forms/DataGrid/SimpleTable';
import { NavLink } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import DetailsMode from 'Core/models/DetailsMode';
import RoleHandler from 'components/Admin/RoleHandler';
import DetailsData from 'Core/models/DetailsData';
import PledgeHandler from '../../PledgeHandler';
import Utility from 'Core/Utility';
import moment from 'moment';
import ConfigService from 'Core/ConfigService';
import NumberFormat from 'react-number-format';
import { Icon } from 'antd';
export interface Props {
    children?: React.ReactNode;
}

export interface State {
    countries: IFilterSelectOption[];
    jurisdictions: IFilterSelectOption[];
    roles: IFilterSelectOption[];
}

export default class PledgeListTable extends React.Component<Props, State> {
    handler: PledgeHandler;
    config = ConfigService.getSync();
    constructor(props: Props) {
        super(props);

        this.state = {
            countries: [],
            jurisdictions: [],
            roles: [],
        };
        this.handler = new PledgeHandler(props);
    }

    componentDidMount() {

    }

    rolesListTableColumns: Array<ColumnProps<IPledgeListModel>> = [
        {
            title: 'Pledger',
            dataIndex: 'name',
            sorter: true,
            render: (text: any, record: IPledgeListModel, index: number) => {
                return record.pledgersName;
            }
        },
        {
            title: 'Projects',
            dataIndex: 'projects',
            sorter: true,
            render: (text: any, record: IPledgeListModel, index: number) => {
                return record.projects?.join(",")
            },
        },

        {
            title: 'Pledged Amount',
            dataIndex: 'totalPledge',
            sorter: true,
            render: (text: any, record: IPledgeListModel, index: number) => {
                return <NumberFormat value={record.totalPledge} prefix="$" thousandSeparator="," displayType='text' />;
            },
        },
        {
            title: 'Phone',
            dataIndex: 'phoneNumber',
            sorter: true,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: true,
        },
        {
            title: 'Date',
            dataIndex: 'creationDate',
            sorter: true,
            render: (text: any, record: IPledgeListModel, index: any) => {
                return Utility.renderDateWithReference(moment(record.pledgeDate), this.config.Display.DateTimeFormat);
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            sorter: true,
            render: (text: any, record: IPledgeListModel, index: number) => {
                //console.log(record);
                return text;
            },
        },
        {
            title: '',
            render: (text: any, record: IPledgeListModel, index: number) => {
                return (
                    <NavLink
                        to={{
                            pathname: 'pledges/' + encodeURIComponent(record.id ?? ''),
                            state: new DetailsData(DetailsMode.View, record.id),
                        }}>
                        <Icon type="read" title="View" />
                    </NavLink>
                );
            },
        },
    ];

    render() {
        return (
            <SimpleTable<IPledgeListModel>
                tableKey="id"
                dataFetcher={this.handler}
                columns={this.rolesListTableColumns}
            />
        );
    }
}



