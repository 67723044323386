import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import debounce from 'lodash/debounce';
import React, { ChangeEvent } from 'react';
import FilterFieldWrapper from '../FilterFieldWrapper';
import { FilterFieldWrapperProps } from '../FilterFieldWrapper/FilterFieldWrapper';
import IFilterSelectOption from './IFilterSelectOption';
import { IFilter, FilterType, JoinType } from 'Core/Api/Api';
const { Option } = Select;
export interface SelectFilterFieldWrapperProps
  extends FilterFieldWrapperProps,
    SelectProps<string> {
  options: IFilterSelectOption[];
}

export default class FilterSelectbox extends FilterFieldWrapper<SelectFilterFieldWrapperProps> {
  static defaultProps: SelectFilterFieldWrapperProps = {
    filterType: FilterType.Equal,
    filterJoin: JoinType.Or,
    options: [],
    filterFields: '',
    optionFilterProp: 'label',
  };

  constructor(props: SelectFilterFieldWrapperProps) {
    super(props);
    this.UpdateFilter = debounce(this.UpdateFilter.bind(this), 700);
  }

  /**
   * Create a list of filters based on the controls properties
   * and the value(s) that have been passed in
   *
   * @protected
   * @param {*} filterValue
   * @returns {IFilter[]}
   * @memberof FilterFieldWrapper
   */
  CreateFilterList(filterValue: any): IFilter[] {
    const filterValueList =
      typeof filterValue == 'string' ? [filterValue] : (filterValue as string[]);

    const filters: IFilter[] = [];
    const filterFields = this.props.filterFields.split(',').map(x => x.trim());
    for (const value of filterValueList) {
      for (const field of filterFields) {
        const markedFilter = {
          field,
          filterId: this.state.filterId,
          filterType: this.props.filterType,
          joinType: this.props.filterJoin,
          value,
        };
        filters.push(markedFilter);
      }
    }
    return filters;
  }

  onChange = (values: string[] | string) => {
    this.UpdateFilter(values);
  };

  render() {
    const { filterFields, filterJoin, filterType, onFilterUpdated, ...inputProps } = this.props;
    return (
      <Select onChange={this.onChange} {...inputProps}>
        {this.props.options.map(x => (
          <Option key={x.value || x.text} label={x.text || x.value} value={x.value || x.text}>
            {x.text || x.value}
          </Option>
        ))}
      </Select>
    );
  }
}













