import {
    Form,
    Icon,
    Input,
    Button,
    Checkbox,
    Spin,
    InputNumber,
    Row,
    Col,
    Typography,
    Descriptions,
    Divider,
    Avatar,
    Modal,
    Popover,
    Upload,
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React from 'react';
import { Redirect, RouteProps, NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import AuthContext from '../../../Context/AuthContext';
import FormControl from '../../Forms/FormControl';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import ValidationUtility from 'Core/ValidationUtility';
import queryString from 'query-string';
import SimpleSelect from '../../Forms/SimpleSelect';
import IFilterSelectOption from '../../Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import Utility from 'Core/Utility/Utility';
import NotificationService from 'Core/NotificationService';
import DetailsMode from 'Core/models/DetailsMode';
import { PaymentMethod } from '../../../Core/Api/Api';
import SimpleUpload from 'components/Forms/SimpleUpload';
import DetailsData from 'Core/models/DetailsData';
import { startCase } from 'lodash';
import { LeftAlignedRow } from '../../Styled/Layout/LeftAlignedRow';
import moment from 'moment';
import ConfigService from '../../../Core/ConfigService';
import Config from '../../../Core/Config.json';
import renderHTML from 'react-render-html';
import { WhiteBackgroundCard, singleColFormColSize } from '../../Forms/Form.Styled';

import NumberFormat from 'react-number-format';
import { RightAlignedRow } from '../../Styled/Layout/RightAlignedRow';
import { BrandedButton } from '../../Styled/Branded/BrandedButton';
import TextArea from 'antd/lib/input/TextArea';
import numeral from 'numeral';
import { ContactUsHandler } from './ContactUsHandler';
import { CenterAlignedRow } from '../../Styled/Layout/CenterAlignedRow';


export interface Props extends FormComponentProps, RouteComponentProps {
    children?: React.ReactNode;
}

export interface State {


}

export class ContactUsFormWithoutFormAndRouter extends React.Component<Props, State> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    handler: ContactUsHandler;

    constructor(props: Props) {
        super(props);

        this.state = {

        };
        this.handler = new ContactUsHandler(props);
        this.onSubmit = this.onSubmit.bind(this);
    }


    componentDidMount() {

    }

    onChange = e => {

        this.setState({

        });
        //this.state.jointHolders.
    };

    onSubmit(e: React.FormEvent) {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            console.log(err);
            console.log(values);
            if (!err) {
                this.handler.SendContactRequest(values).then(user => {
                    NotificationService.success(
                        'Success',
                        <>

                        </>, undefined, true
                    );
                });
            }
        });
    }


    // setRoles(request: { roles?: string[] }, arg1: any) {
    //   if (arg1.map) {
    //     request.roles = arg1;
    //   } else {
    //     request.roles = [];
    //     request.roles.push(arg1);
    //   }
    // }



    render() {
        var { getFieldDecorator } = this.props.form;

        return (

            <Row >
                <Col xs={24}>
                    <CenterAlignedRow style={{ backgroundColor: 'transparent'}}>
                        <Col span={24}>
                            <Title level={2}>Leave us your info</Title>
                            <Divider/>
                        </Col>
                    </CenterAlignedRow>
                </Col>
                <Col xs={18} offset={3}>
                    <Form onSubmit={this.onSubmit} style={{ backgroundColor: 'white', padding: '20px' }} layout="horizontal"  >
                        <CenterAlignedRow>
                            <Col span={24}>
                                <Title level={4}>We are always willing to hear from you. <br/> Give us a call.</Title>
                                <p style={{ textAlign: 'center', color: '#269b48' }}>
                                    (876) 967-3271 ext. 2363
                                    <br />
                                    (876) 618-1118 ext. 2363
                                </p>
                            </Col>
                        </CenterAlignedRow>
                        <Row>
                            <Col span={24}>

                                <Row>
                                    <Col span={23}>
                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            rules={[...ValidationUtility.required('Full name is required')]}
                                            name="name"
                                            label="">
                                            <Input placeholder={'Full name*'} />
                                        </FormControl>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col span={23}>
                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            rules={[...ValidationUtility.required('Email address is required')]}
                                            name="email"
                                            label="">
                                            <Input placeholder={'Email address*'} />
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={23}>
                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}

                                            name="phoneNumber"
                                            label="">
                                            <Input placeholder={'Phone Number'} />
                                        </FormControl>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col span={23}>
                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            rules={[...ValidationUtility.required('Category is required')]}
                                            name="category"
                                            label="">
                                            <Input placeholder={'Category*'} />
                                        </FormControl>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col span={23}>
                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            rules={[...ValidationUtility.required('Message is required')]}
                                            name="message"
                                            label="">
                                            <TextArea placeholder={'Message*'} />
                                        </FormControl>
                                    </Col>

                                </Row>

                                <RightAlignedRow>
                                    <Col span={23}>
                                        <Button type="primary" htmlType='submit'>Send Message</Button>
                                    </Col>
                                </RightAlignedRow>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row >
        );
    }
}

const ContactUsFormWithoutRouter = Form.create<Props>({
    name: 'ContactUsForm',
})(ContactUsFormWithoutFormAndRouter);

const ContactUsForm = withRouter(ContactUsFormWithoutRouter);

export default ContactUsForm;


