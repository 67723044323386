import * as React from 'react';
import { Layout, Col, Row, Input, Icon } from 'antd';
import AppMenu from '../../AppMenu';
import LandingPageMenu from '../../LandingPageMenu';
import AppRoute from '../../../../Core/models/AppRoute';
import Logo, { DarkLogo } from '../../../Styled/Logo';
import UserBar from '../../UserBar';
import { logoLayoutSizes, menuLayoutSizes, authBarLayoutSizes, LightHeader, DarkHeader, loggedInAuthBarLayoutSizes, loggedInMenuLayoutSizes } from './TopBar.Styled';
import AuthBar from 'components/Layout/AuthBar';
import { RightAlignedRow } from '../../../Styled/Layout/RightAlignedRow';
import AuthContext from '../../../../Context/AuthContext';
import { CenterAlignedRow } from '../../../Styled/Layout/CenterAlignedRow';

const { Header } = Layout;
const { Search } = Input;

export interface Props {
    children?: React.ReactNode;
    menu: AppRoute[];
}

export interface State { }

export default class TopBar extends React.Component<Props, State> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <DarkHeader>
                <Row style={{ margin: '0px'}} type="flex" align="middle" gutter={24}>

                    {this.context.data ?
                        <>  <Col {...loggedInMenuLayoutSizes}>
                            <CenterAlignedRow type="flex" justify="center">
                                <Col span={24} >
                                    <AppMenu mode="horizontal" menu={this.props.menu} />
                                </Col>
                            </CenterAlignedRow>
                        </Col>

                            <Col {...loggedInAuthBarLayoutSizes}>
                                <RightAlignedRow  >
                                    <Col span={24}>
                                        <AuthBar />
                                    </Col>
                                </RightAlignedRow>
                            </Col>
                        </>
                        :
                        <>  <Col span={3} offset={1}>
                            <CenterAlignedRow type="flex" justify="center" className="social-media">
                                <Col span={6}>
                                    <a href="#"><Icon style={{}} type="instagram" /></a>
                                </Col>
                                <Col span={6}>
                                    <a href="#"><Icon style={{}} type="linkedin" theme='filled' /></a>
                                </Col>
                                <Col span={6}>
                                    <a href="#"><Icon style={{}} type="twitter" /></a>
                                </Col>
                                <Col span={6}>
                                    <a href="#"><Icon style={{}} type="facebook" /></a>
                                </Col>
                            </CenterAlignedRow>
                        </Col>
                            <Col span={5}>
                                <CenterAlignedRow type="flex" justify="center">
                                    <Col span={24} style={{ color: 'white', fontWeight:300 }} >
                                        <Icon type="phone" theme="filled" rotate={90} /> <span>+1 (876) 967-3271</span>
                                    </Col>
                                </CenterAlignedRow>
                            </Col>

                            <Col span={10}>
                                <CenterAlignedRow type="flex" justify="center">
                                    <Col span={24} >
                                        <AppMenu mode="horizontal" menu={this.props.menu} />
                                    </Col>
                                </CenterAlignedRow>
                            </Col>

                            <Col span={4} style={{ paddingLeft: '10px', maxWidth: '100vw' }} >
                                <RightAlignedRow >
                                    <Col span={24}>
                                        <AuthBar />
                                    </Col>
                                </RightAlignedRow>
                            </Col>
                        </>}

                </Row>
                <Row style={{ backgroundColor: 'white' }}>
                    <Col span={7} offset={1} >
                        <Logo disableLinking={true} />
                    </Col>
                    <Col span={7} offset={7} >
                        <Search placeholder="SEARCH" style={{ marginTop: '20px' }} className='search-textbox' />
                    </Col>
                </Row>
            </DarkHeader >
        );
    }
}
















