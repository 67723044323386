import React from 'react';
import { Form, Row, Input, Col, Button, Select, Transfer, Typography } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import FormControl from 'components/Forms/FormControl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import RoleHandler from '../RoleHandler';
import ValidationUtility from '../../../Core/ValidationUtility';
import SimpleSelect from '../../Forms/SimpleSelect';
import {
  RoleStatus,
  Operations,
  RoleDetailsModel,
} from '../../../Core/Api/Api';
import Utility from 'Core/Utility';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import { singleColFormColSize, FullWithButton } from '../../Forms/Form.Styled';
import MaskedInput from 'components/Forms/MaskedInput';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import DetailsMode from 'Core/models/DetailsMode';
import DetailsData from 'Core/models/DetailsData';
import NotificationService from 'Core/NotificationService';
import TextArea from 'antd/lib/input/TextArea';
import { TransferItem } from 'antd/lib/transfer';
import AuthContext from 'Context/AuthContext';
import { delay } from 'lodash';
import SimpleTransfer from 'components/Forms/SimpleTransfer';

const { Title } = Typography;

export interface Props extends FormComponentProps, RouteComponentProps {
  children?: React.ReactNode;
}

export interface State {
  jurisdictions: ISelectOption[];
  operationsListOptions: TransferItem[];
  operations: string[];
  status: ISelectOption[];
  role?: RoleDetailsModel;
  detailsMode: DetailsMode;
}

export class RoleDetailsFormWithoutFormAndRouter extends React.Component<Props, State> {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  handler: RoleHandler;

  constructor(props: Props) {
    super(props);

    this.state = {
      jurisdictions: [],
      operations: [],
      operationsListOptions: [],
      status: [],
      detailsMode: DetailsMode.New,
    };
    this.handler = new RoleHandler(props);
  }

  componentDidMount() {
    this.getOperations();
    this.getStatus();
    this.getRoleDetails();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {

  }

  getRoleDetails() {
    const urlState = this.props.location.state as DetailsData | undefined;

    if (urlState && urlState.mode == DetailsMode.Edit) {
      this.handler.GetRoleById(urlState.id).then(
        x => {
          this.setState({ role: x.role, detailsMode: DetailsMode.Edit }, () => {
            this.props.form.setFieldsValue(x.role ?? {});
          });
        },
        x => NotificationService.error(x.message)
      );
    } else {
      this.setState({ detailsMode: DetailsMode.New });
    }
  }

  getStatus() {
    var status: ISelectOption[] = [];
    Utility.enumToMap(RoleStatus).forEach((x, y) => status.push({ text: x, value: y }));
    this.setState({ status });
  }

  getOperations() {
    this.handler.GetOperations().then(x => {
      this.setState({ operations: x.operations ?? [] }, () => {
        this.props.form.setFieldsValue({ operations: this.state.role?.operations } ?? {});
        this.updateOperationsList();
      });
    });
  }

  updateOperationsList = () => {
    let operationsList: TransferItem[] = [];

    operationsList = this.state.operations.map(x => ({ key: x, title: x }));

    this.setState({ operationsListOptions: operationsList });
  };

  onJurisdictionChange = (value: any) => {
    // This is done because the value doesn't seem to update on deselect
    delay(this.updateOperationsList, 10, value);
  };

  onSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll(
      (error, values: any) => {
        if (error) {
          return;
        }
        console.log(values);

        if (this.state.detailsMode == DetailsMode.Edit) {
          values.id = this.state.role?.id;
          console.log(values.id, this.state.role?.id);
          this.handler.EditRole(values).then(x => {
            this.props.history.goBack();
            NotificationService.success(`Updated ${x.role?.name}`, "", 10, true);
          });
        } else {
          values.jurisdictionId = this.props.form.getFieldValue('jurisdictionId');
          this.handler.AddRole(values).then(x => {
            this.props.history.goBack();
            NotificationService.success(`Added ${x.role?.name}`, "", 10, true);
          });
        }
      }
    );
  };

  render() {
    var { getFieldDecorator } = this.props.form;
    return (
      <Row>
        <Col>
          <Form {...singleColFormColSize} layout="horizontal" onSubmit={this.onSubmit}>

            <Row>
              <Col xs={24} lg={20} xl={16}>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[
                    ...ValidationUtility.If(this.state.detailsMode == DetailsMode.New, [
                      ...ValidationUtility.required('Name is required'),
                    ]),
                  ]}
                  name="name"
                  label="Name">
                  <Input disabled={this.state.detailsMode == DetailsMode.Edit} />
                </FormControl>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[...ValidationUtility.required('Description is required')]}
                  name="description"
                  label="Description">
                  <TextArea />
                </FormControl>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[...ValidationUtility.required('Operations is required')]}
                  name="operations"
                  // labelCol={{ span: 24 }}
                  //wrapperCol={{ span: 24 }}
                  label="Operations">
                  <SimpleTransfer

                    dataSource={this.state.operationsListOptions}
                    render={x => x.title!}
                  />
                </FormControl>

                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[...ValidationUtility.required('Status is required')]}
                  name="status"
                  label="Status">
                  <SimpleSelect options={this.state.status}></SimpleSelect>
                </FormControl>
              </Col>
            </Row>

            <Row>
              <Col span={16}>
                <Form.Item
                  colon={false}
                  label={
                    <Button onClick={() => this.props.history.goBack()} type="default">
                      Back
                    </Button>
                  }>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  }
}

const RoleDetailsFormWithRouter = Form.create<Props>({
  name: 'RoleDetails',
})(RoleDetailsFormWithoutFormAndRouter);

const RoleDetailsForm = withRouter(RoleDetailsFormWithRouter);

export default RoleDetailsForm;
