import * as React from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';

export const logoLayoutSizes = {
  xs: { span: 9, order: 1, offset: 8  },
  sm: { span: 5, order: 1, offset: 0 },
  md: { span: 5, order: 1, offset: 0 },
  lg: { span: 5, order: 1, offset: 0 },
  xl: { span: 5, order: 1, offset: 0 },
};

export const menuLayoutSizes = {
  xs: { span: 2, order: 2 },
  sm: { span: 6, order: 2 },
  md: { span: 6, order: 2 },
  lg: { span: 6, order: 2 },
  xl: { span: 6, order: 2 },
};

export const authBarLayoutSizes = {
  xs: { span: 22, order: 3 },
  sm: { span: 18, order: 3 },
  md: { span: 18, order: 3 },
  lg: { span: 18, order: 3 },
  xl: { span: 18, order: 3 },
};

export const loggedInMenuLayoutSizes = {
    xs: { span: 6, order: 3, offset: 18 },
    sm: { span: 16, order: 2 , offset: 0},
    md: { span: 12, order: 2 , offset: 0},
    lg: { span: 12, order: 2 , offset: 0},
    xl: { span: 12, order: 2, offset: 0},
};

export const loggedInAuthBarLayoutSizes = {
    xs: { span: 18, order: 2 },
    sm: { span: 3, order: 3 },
    md: { span: 7, order: 3 },
    lg: { span: 7, order: 3 },
    xl: { span: 7, order: 3 },
};

export const LightHeader = styled(Layout.Header)`
  &.ant-layout-header {
    background-color: #fff;
    max-width: 100vw;
    margin 0px!important;
  }
`;

export const DarkHeader = styled(Layout.Header)`
  &.ant-layout-header {
    background-color: #262626;
    max-width: 100vw;
    margin 0px!important;
  }

  .search-textbox input {
    background-color: #ebebeb; 
  }
`;















