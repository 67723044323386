import { Layout } from 'antd';
import * as React from 'react';
import styled from 'styled-components';
import topBarImage1 from 'assets/images/news.png';
import topBarImage2 from 'assets/images/managementTeam.png';
import topBarImage3 from 'assets/images/boardOfDirectors.png';

const { Header, Content, Footer } = Layout;

export const Page = styled.div`
    
`;

export const Banner = styled.div`
    height: 260px;
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;

    &.news {
        background: url(${topBarImage1});
    }
    &.management{
        background: url(${topBarImage2});
    }
    &.board{
        background: url(${topBarImage2});
    }

@media only screen and (min-width: 992px) {
    /*height: 50em;*/
    /* width: 80%; */
    //margin-left: 160px;
    //margin-right: 160px;
}

.donateHeader{
            position: absolute;
            width: 102px;
            height: 32px;
            background-color: #e36e19;
            display: inline-block;
            color: #ffff;
            right: 184px;
            top: 50px;
        }
    .volunteer{
            position: absolute;
             width: 116px;
            height: 32px;
            background-color: #269b48;
            color: #ffff;
            right: 42px;
            top: 50px;
        }
    .backToHome{
            position: absolute;
            color: #ffff;
             left: 42px;
            top: 50px;
        }
`;


export const NewsListing = styled.div`
    padding-left: 120px;
    padding-top: 63px;
    padding-right: 120px;
    display: block;


    .newsItem{
        width: 400px;
        height: 460px;
        display: inline-block;
        line-height: 20px;
        padding-left: 20px;
        padding-right: 74px;
        
        .ant-avatar{
            width: 300px;
            height: 230px;
        }

        .itemTitle{
            padding-top: 10px;
            font-family: HelveticaNeueLTStd-57;
            font-size: 18px;
            font-weight: 600;
            font-stretch: condensed;
            text-align: left;
            color: #1c1919;
            height: 16px;
        }

        .itemDate{
            font-size: 10px;
            color: #269b48;
            line-height: 7px;
            padding-top: 26px;
        }
        
        .itemText{
            font-size: 14px;
            color: #343434;
            line-height: 1.21;
        }

        .readMore{
            background-color: #00aed8;
            color: #ffff;
        }
    
    }

        
`;