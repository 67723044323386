import { Row, Col } from 'antd';
import { CenteredFormContent, BannerDiv } from 'components/Layout/LoginLayout/LoginLayout.Style';
import AddNewsForm from 'components/NewsManagement/AddNewsForm';
import { ReactNode } from 'react';
import * as React from 'react';
import { PageTitle } from 'components/Layout/PageTitle';

interface AddNewsPageProps {
  children?: ReactNode;
}

export function AddNewsPage({ children }: AddNewsPageProps) {
  return (
    <CenteredFormContent>
      <BannerDiv className='admin'>
        <PageTitle title={"News"} />
      </BannerDiv>
      <Row type="flex" align="middle" justify="center">
        <Col span={21} offset={1}>
          <AddNewsForm />
        </Col>
      </Row>

    </CenteredFormContent>
  );
}
