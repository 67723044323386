import * as React from 'react';
import { TableFilterContext } from '../TableFilterContextProvider';
import debounce from 'lodash/debounce';
import { IFilter, JoinType, FilterType } from 'Core/Api/Api';
import TableRequest from 'Core/TableUtility/Models/TableRequest';


/**
 * Used to uniquely identify filters that were created by this
 * control
 * @interface MarkedFilter
 * @extends {IFilter}
 */
export interface MarkedFilter extends IFilter {
  filterId?: string | number;
  orginalValue?: any;
}

export interface FilterFieldWrapperProps {
  children?: React.ReactNode;
  filterFields: string;
  filterJoin?: JoinType;
  filterType?: FilterType;
  valueTransform?: (filterValue: string | any) => string | any;
  ignore?: boolean;
  onFilterUpdated?: (filters: IFilter[]) => void,
  splitValue?: boolean,
  splitvaluedelimeter?: string
  // [key: `data-${string}`]: string;
}

export interface State {
  filterId?: string | number;
  intialValue?: any;
  intialValueSet: boolean;
}

export default class FilterFieldWrapper<
  extendablePropsType extends FilterFieldWrapperProps
> extends React.Component<extendablePropsType, State> {
  static defaultProps: FilterFieldWrapperProps = {
    filterType: FilterType.Contains,
    filterJoin: JoinType.And,
    filterFields: '',
    ignore: false,
    splitValue: false,
    splitvaluedelimeter: ',',
  };
  static contextType = TableFilterContext;
  context!: React.ContextType<typeof TableFilterContext>;


  constructor(props: extendablePropsType) {
    super(props);

    this.state = {
      filterId: this.props.filterFields,
      intialValueSet: false
    };
  }

  UpdateFilter(filterValue: string | any) {
    // remove filters that were added by this control
    const filters = this.GetCleanContextFilters();
    const orginalValue = filterValue;
    if (this.props.valueTransform) {
      filterValue = this.props.valueTransform(filterValue);
    }

    // add filters that pertain to this control
    const newFilters = filterValue ? this.CreateFilterList(filterValue, orginalValue) : [];
    filters.unshift(...newFilters);

    this.context.data!.filters = filters;
    this.context.Update(this.context.data);
  }

  render() {
    return <>{this.props.children}</>;
  }

  componentDidUpdate(prevProps: extendablePropsType) {
    if (prevProps.filterJoin != this.props.filterJoin) {
      if (this.context.data?.filters?.length) {
        (this.context.data.filters as MarkedFilter[])
          .filter(x => x.filterId == this.state.filterId)
          .forEach(x => x.joinType = this.props.filterJoin);
        this.context.Update(this.context.data);
      }
    }
    if (this.context.data && !this.state.intialValueSet) {
      this.getInitalValueAndSetfield(this.context.data);
    }
  }

  /**
   * 
   */
  protected getInitalValueAndSetfield(tableRequest: TableRequest) {

    const foundFilters = tableRequest.filters?.filter((x: MarkedFilter) => x.filterId == this.props.filterFields);

    if (foundFilters && foundFilters.length) {
      const foundFilter: MarkedFilter = foundFilters[0];
      this.setState({ intialValue: foundFilter.orginalValue, intialValueSet: true })
    } else {
      this.setState({ intialValueSet: true })
    }

  }

  componentWillUnmount() {
    const filters = this.GetCleanContextFilters();
    this.context.data!.filters = filters;

    this.context.Update(this.context.data);
  }

  /**
   * Get the filter from the context object with any previous filters that this
   * control would have added (but keep other filters)
   *
   * @protected
   * @returns {IFilter[]}
   * @memberof FilterFieldWrapper
   */
  protected GetCleanContextFilters(): IFilter[] {
    const tableRequest = this.context.data || { filters: [] };
    const filters = this.RemoveOldFilters(tableRequest.filters || []);
    return filters;
  }

  /**
   * Remove filters that this control would have added.
   *
   * @protected
   * @param {IFilter[]} filters
   * @returns {IFilter[]}
   * @memberof FilterFieldWrapper
   */
  protected RemoveOldFilters(filters: IFilter[]): IFilter[] {
    return filters.filter(x => (x as MarkedFilter).filterId !== this.state.filterId);
  }

  protected getValues(filterValue: any): Array<any> {
    if (this.props.splitValue) {
      return (filterValue as string).split(this.props.splitvaluedelimeter!).filter(x => x && x.trim() != "").map(x => x.trim());
    } else {
      return [(filterValue as string)?.trim()];
    }
  }

  /**
   * Create a list of filters based on the controls properties
   * and the value(s) that have been passed in
   *
   * @protected
   * @param {*} filterValue
   * @returns {IFilter[]}
   * @memberof FilterFieldWrapper
   */
  protected CreateFilterList(filterValue: any, orginalValue: any = undefined): IFilter[] {
    const filters: IFilter[] = [];
    const filterFields = this.props.filterFields.split(',').map(x => x.trim()).filter(x => x);
    const values = this.getValues(filterValue);
    for (const value of values) {
      for (const field of filterFields) {
        const markedFilter = {
          field,
          filterId: this.state.filterId,
          filterType: this.props.filterType,
          joinType: JoinType.Or,
          value: value,
          ignore: this.props.ignore,
        };
        filters.push(markedFilter);
      }
    }

    const markedFilterGroup: MarkedFilter = {};
    markedFilterGroup.filterType = FilterType.Group;
    markedFilterGroup.groupFilters = filters;
    markedFilterGroup.orginalValue = orginalValue;
    markedFilterGroup.joinType = this.props.filterJoin;
    markedFilterGroup.filterId = this.state.filterId;
    markedFilterGroup.ignore = this.props.ignore;

    return [markedFilterGroup];
  }
}
