import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { User, SlimUser, Operations, ISlimUser } from 'Core/Api/Api';
import SimpleTable from 'components/Forms/DataGrid/SimpleTable';
import UserHandler from '../../UserHandler';
import { NavLink } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import DetailsMode from '../../../../Core/models/DetailsMode';
import { Button, Icon } from 'antd';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import DetailsData from 'Core/models/DetailsData';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import NotificationService from 'Core/NotificationService';
export interface Props {
  children?: React.ReactNode;
}

export interface State {
  countries: IFilterSelectOption[];
  jurisdictions: IFilterSelectOption[];
  roles: IFilterSelectOption[];
}

export default class UserListTable extends React.Component<Props, State> {
  handler: UserHandler;

  constructor(props: Props) {
    super(props);

    this.state = {
      countries: [],
      jurisdictions: [],
      roles: [],
    };
    this.handler = new UserHandler(props);
  }

  componentDidMount() {

  }

  resendActivationEmail = (user: ISlimUser) => {
    this.handler.ResendActivationEmail({ emailAddress: user.emailAddress }).then(x => {
      NotificationService.success(`Reactivation Email sent for ${user.username}`)
    });
  }

  userListTableColumns: Array<ColumnProps<SlimUser>> = [
    {
      title: 'First Name',
      dataIndex: 'firstname',
      sorter: true,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastname',
      sorter: true,
    },
    {
      title: 'Network Id',
      dataIndex: 'username',
      sorter: true,
    },
    {
      title: 'Roles',
      render: (text: any, record: SlimUser, index: number) => {
        if (record != null) {
          var a = record as any;
          if (a.roles?.length > 0) {
            return record.roles?.reduce((x, y) => x + ',' + y);
          }
        }
        return "";

      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
    },
    {
      title: 'Activated',
      dataIndex: 'EmailConfirmed',
      render: (text: any, record: SlimUser, index: number) => {
        return record?.isActivated ? "Yes" : "No";
      }

      ,
      sorter: true,
    },
    {
      title: '',
      render: (text: any, record: SlimUser, index: number) => {
        console.log(record);
        return (

          <>
            <ShowIfTrue condition={!record.isActivated}>
              <ShowIfHavePermission operations={[Operations.Create_users]}>

                <Button type={"link"} onClick={() => this.resendActivationEmail(record)} >
                  <Icon title={"Re-Send Activation Email"} type="mail" />
                </Button>
              </ShowIfHavePermission>
            </ShowIfTrue>
            <ShowIfHavePermission operations={[Operations.Modify_users]}>
              <NavLink
                to={{
                  pathname: 'users/' + record.id,
                  state: { id: record.id, mode: DetailsMode.Edit },
                }}>
                <Icon type="edit" title="Edit" />
              </NavLink>
            </ShowIfHavePermission>
          </>
        );
      },
    },
  ];

  render() {
    return (
      <SimpleTable<SlimUser>
        tableKey="id"
        dataFetcher={this.handler}
        columns={this.userListTableColumns}
      />
    );
  }
}



