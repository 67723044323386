import * as React from 'react';
import FilterBar from 'components/Forms/DataGrid/FilterBar';
import { Row, Col, Form } from 'antd';
import FilterTextbox from 'components/Forms/DataGrid/FilterTextbox';
import InfoIcon from 'components/Forms/InfoIcon';
import FilterDatePicker from 'components/Forms/DataGrid/FilterDatePicker';
import { FilterType, JoinType } from 'Core/Api/Api';
import FilterDateRangePicker from 'components/Forms/DataGrid/FilterDateRangePicker';
import FilterSelectbox from 'components/Forms/DataGrid/FilterSelectbox';
import UserHandler from '../../UserHandler';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';

export interface Props {
    children?: React.ReactNode;
}

export interface State {
    countries: IFilterSelectOption[];
    jurisdictions: IFilterSelectOption[];
    roles: IFilterSelectOption[];
}
export default class UserListFilterBar extends React.Component<Props, State> {
    handler: UserHandler;

    constructor(props: Props) {
        super(props);

        this.state = {
            countries: [],
            jurisdictions: [],
            roles: [],
        };
        this.handler = new UserHandler(props);
    }

    componentDidMount() {
        this.getRoles();
    }

    getRoles() {
        this.handler.GetFilteredRoles().then(x => {
            const roles: IFilterSelectOption[] =
                x.results?.map(y => ({
                    text: y.name,
                    value: y.id,
                })) ?? [];
            this.setState({ roles });
        });
    }

    render() {
        return (
            <FilterBar>
                <Row>
                    <Col xs={12} lg={8} xl={6}>
                        <Form.Item label="Search" labelAlign="left">
                            <FilterTextbox
                                filterFields="firstname,lastname,username,emailAddress"
                                filterJoin={JoinType.And}
                                suffix={<InfoIcon text="Search name" />}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={12} lg={8} xl={6}>
                        <Form.Item label="Roles" labelAlign="left">
                            <FilterSelectbox
                                filterFields="userRoles.roleId"
                                filterType={FilterType.Equal}
                                showSearch={true}
                                allowClear={true}
                                filterJoin={JoinType.And}
                                placeholder={'Select Role'}
                                options={this.state.roles}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </FilterBar>
        );
    }
}



