import * as React from 'react';
import { Col, Descriptions, Row } from 'antd';
import { useHistory } from 'react-router-dom';


import styled from 'styled-components';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import { FundingType, IDocumentModel, ProjectFullDetailsModel } from 'Core/Api/Api';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import Utility from 'Core/Utility';
import ConfigService from 'Core/ConfigService';
import { RichTextViewer } from 'components/Forms/RichTextViewer';
import { DocumentItemDisplay } from 'components/Forms/FilesViewer/DocumentItemDisplay';
import { InternalDocumentLink } from 'components/Forms/InternalDocumentLink';



export interface ProjectDetailsDisplayProps {
  categories?: ISelectOption[] | undefined;
  fundingTypes?: ISelectOption[] | undefined;
  fundingPurposes?: ISelectOption[] | undefined;
  project?: ProjectFullDetailsModel | undefined;
  otherDocuments?: IDocumentModel[] | undefined;
}

export const ButtonGroup = styled.div`
  
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(100px,200px));
    gap: 5px;
  `;

export function ProjectDetailsDisplay({ categories, fundingPurposes, fundingTypes, project, otherDocuments }: ProjectDetailsDisplayProps) {

  let history = useHistory();
  let config = ConfigService.getSync();

  const TypeOfFundingText = (props: { typeOfFunding?: FundingType }) => {
    if (props.typeOfFunding === undefined) return <></>;
    return props.typeOfFunding === FundingType.ImpactInvestment ? <>Equity Investment (Jamaica Impact Investment Market-JIIM)</> :
      <>Donations (Jamaica Social Investment Market-JSIM)</>;
  }

  const GetFileNameFromApiUrl = (url: String) => {
    return url.substring(url.lastIndexOf("/") + 1);
  }

  return (
    <Row>
      <Col span={23}>

        <Descriptions column={{ md: 3, lg: 3, xl: 3, sm: 3, xxl: 3 }} >
          <Descriptions.Item span={3} label="Title">{project?.name}</Descriptions.Item>
          <Descriptions.Item span={3} label="Sub-Title">{project?.subTitle}</Descriptions.Item>
          <Descriptions.Item span={1} label="Category">{categories?.find(x => x?.value == project?.category)?.text}</Descriptions.Item>
          <Descriptions.Item span={1} label="Raised"> <NumberFormat thousandSeparator="," value={project?.amountReceived} displayType="text" /></Descriptions.Item>
          <Descriptions.Item span={1} label="Funding Target"> <NumberFormat thousandSeparator="," value={project?.amountRequested} displayType="text" /></Descriptions.Item>
          <Descriptions.Item span={1} label="Time To Fund">{project?.timeToFunding && Utility.renderDateWithReference(moment(project?.timeToFunding), config.Display.DateFormat)}</Descriptions.Item>
          <Descriptions.Item span={1} label="Project Life Cycle Stage">{project?.fundingPurpose}</Descriptions.Item>
          <Descriptions.Item span={2} label="Market"><TypeOfFundingText typeOfFunding={project?.typeOfFunding} /></Descriptions.Item>
          <Descriptions.Item span={3} label="Theory Of Change"><RichTextViewer fontSize={16} value={project?.description} /></Descriptions.Item>
          <Descriptions.Item span={3} label={<span style={{ marginRight: "56px" }}>Thumbnail</span>}> {project?.thumbnailFileUrl && <img src={project?.thumbnailFileUrl} width="200" height="200" />}</Descriptions.Item>
          <Descriptions.Item span={3} label="Dashboard Picture"> {project?.projectDashboardFileUrl && <img src={project?.projectDashboardFileUrl} width="356" height="246" />}</Descriptions.Item>
          <Descriptions.Item span={3} label="Video Url"> {project?.videoUrl && <a href={project?.videoUrl} target="_blank">{project?.videoUrl}</a>}</Descriptions.Item>
          <Descriptions.Item span={3} label="Business Plan"> {project?.projectDocumentFileUrl && <a href={project?.projectDocumentFileUrl} target="_blank">{GetFileNameFromApiUrl(project?.projectDocumentFileUrl)}</a>}</Descriptions.Item>
          <Descriptions.Item span={3} label="Articles of Incorporation"> {project?.company?.articlesOfIncorporationUrl && <InternalDocumentLink url={project?.company?.articlesOfIncorporationUrl} />}</Descriptions.Item>
          <Descriptions.Item span={3} label="Proof Of Tax Compliance"> {project?.company?.proofOfTaxComplianceUrl && <InternalDocumentLink url={project?.company?.proofOfTaxComplianceUrl} />}</Descriptions.Item>
          <Descriptions.Item span={3} label="Others Documents">{otherDocuments?.map(document => (
            <DocumentItemDisplay document={document} readonly={true} ></DocumentItemDisplay>
          ))}
          </Descriptions.Item>

        </Descriptions>
      </Col>
    </Row>
  );
}

export default ProjectDetailsDisplay;