import {
    ChangeBannerImageWebModel, IChangeBannerImageWebModel,
} from '../../Core/Api/Api';
import { SecuredClient } from '../../Core/Api/SecuredClient';

export default class DashboardImageHandler {
    props: any;
    constructor(props) {
        this.props = props;
    }

    GetBanner() {
        const client = new SecuredClient();
        client.showLoading = true;
        client.handleGeneralError = true;

        return client.getBanner();
    }


    ChangeBanner(model: IChangeBannerImageWebModel) {
        const client = new SecuredClient();
        const request = new ChangeBannerImageWebModel(model);
        request.init(model)
        client.showLoading = true;
        client.handleGeneralError = true;

        return client.changeBanner(request);
    }
}
