import { Button, Col, Drawer, Icon, Input, Radio, Row } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import { doubleColFormColSize, FullWithButton } from 'components/Forms/Form.Styled';
import FormControl from 'components/Forms/FormControl';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import PledgeHandler from 'components/PledgeManagement/PledgeHandler';
import { ActionsFooter } from 'components/ProjectUpdateManagement/ProjectUpdateDetailsSidebar/ProjectUpdateDetailsSidebar';
import ButtonGroup from 'components/Styled/ButtonGroup';

import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { IChangePledgeModel, IPledge, PledgeStatus } from 'Core/Api/Api';
import NotificationService from 'Core/NotificationService';
import ValidationUtility from 'Core/ValidationUtility';
import React, { SyntheticEvent } from 'react';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router';

interface Props extends FormComponentProps<IChangePledgeModel> {
  pledge?: IPledge;
  visible: boolean;
  onSubmitted?: Function;
  onClose?: (e: SyntheticEvent) => void;
}

export const AcknowledgePledgeFormSidebarWithoutForms = ({ pledge, form, onSubmitted, visible, onClose }: Props) => {

  let { getFieldDecorator } = form;
  let pledgeHandler = new PledgeHandler({});
  let history = useHistory();

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    form.validateFieldsAndScroll((errors, values) => {
      if (errors) { return; }

      values.id = pledge?.id;
      values.status = PledgeStatus.Fulfilled;

      pledgeHandler.AcknowledgePledge(values).then(response => {
        NotificationService.success(`Pledge has been updated`, "", 10, true);
        onSubmitted?.();
      })
    });
  }

  return <Drawer
    title="Acknowledge Pledge"
    width={520}
    onClose={onClose}
    visible={visible}
    bodyStyle={{ paddingBottom: 80 }}
  >
    <Form {...doubleColFormColSize} layout="vertical" onSubmit={onSubmit}>
      <Row>
        <Col>
          <FormControl
            getFieldDecorator={getFieldDecorator}
            rules={[...ValidationUtility.required('Please enter amount that has been received')]}
            name="amountReceived"
            label="Amount Received:">
            <NumberFormat
              thousandSeparator="," customInput={Input} />
          </FormControl>
        </Col>
      </Row>
      <LeftAlignedRow>
        <ButtonGroup>
          <Button onClick={(e) => onClose?.(e)} type="default">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" >
            Submit
          </Button>
        </ButtonGroup>
      </LeftAlignedRow>
    </Form>
  </Drawer>;
}

export default Form.create<Props>({
  name: 'acknowledge-pledge',
})(AcknowledgePledgeFormSidebarWithoutForms)