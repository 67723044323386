import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { User, SlimUser, Role } from 'Core/Api/Api';
import SimpleTable from 'components/Forms/DataGrid/SimpleTable';
import UserHandler from '../../UserHandler';
import { NavLink } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import DetailsMode from 'Core/models/DetailsMode';
import RoleHandler from 'components/Admin/RoleHandler';
import DetailsData from 'Core/models/DetailsData';
export interface Props {
  children?: React.ReactNode;
}

export interface State {
  countries: IFilterSelectOption[];
  jurisdictions: IFilterSelectOption[];
  roles: IFilterSelectOption[];
}

export default class RolesListTable extends React.Component<Props, State> {
  handler: RoleHandler;

  constructor(props: Props) {
    super(props);

    this.state = {
      countries: [],
      jurisdictions: [],
      roles: [],
    };
    this.handler = new RoleHandler(props);
  }

  componentDidMount() {

  }

  rolesListTableColumns: Array<ColumnProps<Role>> = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: '',
      render: (text: any, record: Role, index: number) => {
        console.log(record);
        return (
          <NavLink
            to={{
              pathname: 'roles/' + encodeURIComponent(record.id ?? ''),
              state: new DetailsData(DetailsMode.Edit, record.id),
            }}>
            Edit
          </NavLink>
        );
      },
    },
  ];

  render() {
    return (
      <SimpleTable<Role>
        tableKey="id"
        dataFetcher={this.handler}
        columns={this.rolesListTableColumns}
      />
    );
  }
}



