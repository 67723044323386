import { SecuredClient } from 'Core/Api/SecuredClient';
import {
    ArchiveNewsQueryRequest,
    ContactUsCommandRequest,
    GetAllNewsByFilterForAdminsQueryRequest,
    GetAllNewsByFilterQueryRequest,
    GetAllUsersByFilterModel,
    GetNewsByIdForAdminQueryRequest,
    IArchiveNewsQueryRequest,
    IGetNewsByIdForAdminQueryRequest,
    INewsModel,
    IPublishNewsQueryRequest,
    ISetNewsApprovalStatusQueryRequest,
    NewsListModel,
    NewsModel,
    OrderBy, PublishNewsQueryRequest, SetNewsApprovalStatusQueryRequest, SlimUser, User,
} from 'Core/Api/Api';
import Utility from 'Core/Utility';
import ITableDataFetcher from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import ITableDataResponse from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';

export class NewsHandler implements ITableDataFetcher<NewsListModel> {

    props: any;
    constructor(props) {
        this.props = props;
    }

    GetData(tableRequest: TableRequest): Promise<ITableDataResponse<NewsListModel>> {
        return this.GetNews(tableRequest);
    }


    GetNews(tableRequest: TableRequest) {
        const client = new SecuredClient();
        client.showLoading = false;
        client.handleGeneralError = true;

        const request = new GetAllNewsByFilterForAdminsQueryRequest(tableRequest);

        if (request.orderBy == undefined) {
            request.orderBy = [];
            request.orderBy.push(new OrderBy({ field: "name", ascending: false }));
        }

        return client.getAllNewsByFilterForAdmins(request);
    }



    AddNews(request: INewsModel) {
        const client = new SecuredClient();
        client.showLoading = true;
        client.handleGeneralError = true;

        return client.addNews(new NewsModel(request));
    }

    GetNewsByIdForAdmin(request: IGetNewsByIdForAdminQueryRequest) {
        const client = new SecuredClient();
        client.showLoading = true;
        client.handleGeneralError = true;

        return client.getNewsByIdForAdmin(new GetNewsByIdForAdminQueryRequest(request));
    }

    EditNews(request: INewsModel) {
        const client = new SecuredClient();
        client.showLoading = true;
        client.handleGeneralError = true;

        return client.editNews(new NewsModel(request));
    }

    ArchiveNews(request: IArchiveNewsQueryRequest) {
        const client = new SecuredClient();
        client.showLoading = true;
        client.handleGeneralError = true;

        return client.archiveNews(new ArchiveNewsQueryRequest(request));
    }

    PublishNews(request: IPublishNewsQueryRequest) {
        const client = new SecuredClient();
        client.showLoading = true;
        client.handleGeneralError = true;

        return client.publishNews(new PublishNewsQueryRequest(request));
    }

    SetNewsApprovalStatus(request: ISetNewsApprovalStatusQueryRequest) {
        const client = new SecuredClient();
        client.showLoading = true;
        client.handleGeneralError = true;

        return client.setNewsApprovalStatus(new SetNewsApprovalStatusQueryRequest(request));
    }
}










