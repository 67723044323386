import * as React from 'react';
import TableFilterContextProvider from 'components/Forms/DataGrid/TableFilterContextProvider';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { Col, Button, Row } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import DonationListFilterBar from './DonationListFilterBar';
import DonationListTable from './DonationListTable';

export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
}

export interface State {}

export class DonationListViewWithoutRouter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <TableFilterContextProvider>   
        <Row>
          <Col md={24}>
                    <DonationListFilterBar />
          </Col>
        </Row>
        <Row>
          <Col md={24}>
                    <DonationListTable />
          </Col>
        </Row>
      </TableFilterContextProvider>
    );
  }
}

const DonationListView = withRouter(DonationListViewWithoutRouter);

export default DonationListView;



