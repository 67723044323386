import { Divider, Typography } from 'antd';
import { ColoredPill } from 'components/Forms/ColoredPill';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import { ProjectApproval } from 'Core/Api/Api';
import ConfigService from 'Core/ConfigService';
import Utility from 'Core/Utility';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';

interface Props {
  approvals?: ProjectApproval[]
}

const ApprovalContainer = styled.div`
  display: flex;
  flex-direction:column;
  align-content: flex-start;
  gap: 3px;
`

export default ({ approvals }: Props) => {
  let config = ConfigService.getSync();

  return <>
    <Divider />

    {approvals?.map(approval => (
      <>
        <ApprovalContainer>
          <Typography.Text><b>Action: </b><ColoredPill value={approval.status} options={[{ color: "green", value: "Approved" }, { color: "red", value: "Rejected" }]} /></Typography.Text>
          <Typography.Text><b>by:</b> {approval.createdBy}</Typography.Text>
          <Typography.Text><b>on: </b> {Utility.renderDateWithReference(moment(approval?.creationDate), config.Display.DateTimeFormat)}</Typography.Text>
          <ShowIfTrue condition={!!approval.reason}>
            <Typography.Text><b>Reason: </b> {approval?.reason}</Typography.Text>
          </ShowIfTrue>
          <Divider />
        </ApprovalContainer>

      </>
    ))}
  </>;
}