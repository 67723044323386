import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { ProjectUpdateListModel } from 'Core/Api/Api';
import SimpleTable from 'components/Forms/DataGrid/SimpleTable';

import { NavLink } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import DetailsMode from 'Core/models/DetailsMode';
import DetailsData from 'Core/models/DetailsData';
import { ProjectUpdatesHandler } from 'components/ProjectUpdateManagement/ProjectUpdatesHandler';
import { startCase } from 'lodash';
import NumberFormat from 'react-number-format';
import { Icon } from 'antd';
import Utility from 'Core/Utility';
import moment from 'moment';
import ConfigService from 'Core/ConfigService';

export interface Props {
    children?: React.ReactNode;
}

export interface State {
    countries: IFilterSelectOption[];
    jurisdictions: IFilterSelectOption[];
    roles: IFilterSelectOption[];
}

export default class AdminProjectUpdateListTable extends React.Component<Props, State> {
    handler: ProjectUpdatesHandler;
    config = ConfigService.getSync();
    constructor(props: Props) {
        super(props);

        this.state = {
            countries: [],
            jurisdictions: [],
            roles: [],
        };
        this.handler = new ProjectUpdatesHandler(props);
    }

    componentDidMount() {

    }

    rolesListTableColumns: Array<ColumnProps<ProjectUpdateListModel>> = [
        {
            title: 'Project',
            dataIndex: 'projectName',
            sorter: false,
        },
        {
            title: 'Company',
            dataIndex: 'companyName',
            sorter: false,

        },
        {
            title: "Submitted",
            render: (text: any, record: ProjectUpdateListModel, index: number) => {
                return (
                    record.lastSubmittedDate ? Utility.renderDateWithReference(moment(record.lastSubmittedDate), this.config.Display.DateTimeFormat) :
                        Utility.renderDateWithReference(moment(record.creationDate), this.config.Display.DateTimeFormat)
                );
            },
        },
        {
            title: 'Documents',
            dataIndex: 'documentCount',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text: any, record: ProjectUpdateListModel, index: number) => {
                return startCase(text);
            },
        },
        {
            title: '',
            render: (text: any, record: ProjectUpdateListModel, index: number) => {
                return (
                    <NavLink
                        to={{
                            pathname: 'project-updates/' + encodeURIComponent(record.id ?? ''),
                            state: new DetailsData(DetailsMode.Edit, record.id),
                        }}>
                        <Icon type="read" title="View" />
                    </NavLink>
                );
            },
        },
    ];

    render() {
        return (
            <SimpleTable<ProjectUpdateListModel>
                tableKey="id"
                dataFetcher={this.handler}
                columns={this.rolesListTableColumns}
            />
        );
    }
}



