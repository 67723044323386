import * as React from 'react';
import TableFilterContextProvider from 'components/Forms/DataGrid/TableFilterContextProvider';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { Col, Button, Row, Spin, Modal } from 'antd';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import PledgeListFilterBar from './PledgeListFilterBar';
import PledgeListTable from './PledgeListTable';
import { PledgeViewSidebar } from '../PledgeViewSidebar';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import PledgeHandler from '../PledgeHandler';
import { IPledge } from 'Core/Api/Api';
import NotificationService from '../../../Core/NotificationService';

export interface Props extends RouteComponentProps {
    children?: React.ReactNode;
}

export interface State { }


export const PledgeListViewWithoutRouter = ({ }: Props) => {

    const params = useParams<{ id }>();
    const history = useHistory();
    const handler = new PledgeHandler();
    const [currentPledge, setCurrentPledge] = React.useState<IPledge | undefined>(undefined);
    const [isExportModalVisible, setIsExportModalVisible] = React.useState(false);
    const [isLoading, setLoading] = React.useState(false);

    useEffect(() => {
        if (params?.id) {
            handler.GetPledgeById(params?.id).then(x => {
                setCurrentPledge(x.pledge);
            });
        }
    }, [])

    function formatCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');

        return `${month}-${day}-${year} ${hours}-${minutes}-${seconds}`;
    }

    async function exportPledges(){
        setLoading(true);
        try {
            var response = await handler.ExportPledges();
            console.log(response);
            if (response.status == 200) {
                NotificationService.success('Exported pledges');
                const FileSaver = require('file-saver');
                let fileName = response.fileName;
                if (fileName === "undefined") {
                    fileName = "Pledges-" + formatCurrentDate();
                }
                FileSaver.saveAs(response.data, fileName + ".xlsx");
            }
            else {
                NotificationService.error('An error occurred while exporting pledges');
            }
        }
        catch (error) {
            console.error('Error unlocking parents:', error);
            NotificationService.error('An error occurred while exporting pledges');

        }
        finally {
            setLoading(false);
            setIsExportModalVisible(false);
        }
    }

    return (
        <Row>
            <Modal
                title={"Export Pledges"}
                visible={isExportModalVisible}
                onCancel={() =>  setIsExportModalVisible(false) }
                footer={[
                    <>
                        <Spin delay={200} spinning={isLoading} />&nbsp;&nbsp;
                        <Button key="submit" type="primary" onClick={exportPledges}>
                            Export
                        </Button>
                        <Button key="back" onClick={() => setIsExportModalVisible(false)}>
                            Cancel
                        </Button>
                    </>]} width='fit-content'>
                <Row>
                    <Col>
                            <label>
                              Are you sure you want to export pledges?
                            </label>
                    </Col>
                </Row>
            </Modal>
            <Col span={6}>
                <Button style={{ zIndex: 1000 }} type="primary" onClick={() => setIsExportModalVisible(true)} >Export</Button>
            </Col>
            <TableFilterContextProvider>
                <Row>
                    <Col md={24}>
                        <PledgeListFilterBar />
                    </Col>
                </Row>
                <Row>
                    <Col md={24}>
                        <PledgeListTable />
                    </Col>
                </Row>
                <PledgeViewSidebar pledge={currentPledge} onSubmitted={x => history.goBack()} onClose={x => history.goBack()} visible={!!params?.id} />

            </TableFilterContextProvider>
        </Row>
    );

}

const PledgeListView = withRouter(PledgeListViewWithoutRouter);

export default PledgeListView;



