import { Button, Col, Drawer, Icon, Input, Radio, Row } from 'antd';
import Form, { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import { doubleColFormColSize, FullWithButton } from 'components/Forms/Form.Styled';
import FormControl from 'components/Forms/FormControl';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import { NewsHandler } from 'components/NewsManagement/NewsHandler';
import ButtonGroup from 'components/Styled/ButtonGroup';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { IArchiveNewsQueryRequest, News } from 'Core/Api/Api';
import NotificationService from 'Core/NotificationService';
import ValidationUtility from 'Core/ValidationUtility';
import React, { SyntheticEvent } from 'react';
import { useHistory } from 'react-router';

interface Props extends FormComponentProps<IArchiveNewsQueryRequest> {
  news?: News;
  visible: boolean;
  onSubmitted: Function;
  onClose?: (e: SyntheticEvent) => void;
}

export const NewsArchiveSidebarWithoutForms = ({ news, form, onSubmitted, visible, onClose }: Props) => {

  let newsHandler = new NewsHandler({});
  let history = useHistory();

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    form.validateFieldsAndScroll((errors, values) => {
      if (errors) { return; }

      values.id = news?.id;
      newsHandler.ArchiveNews(values).then(response => {
        NotificationService.success(`${response.news?.name} Has been archived`);
        onSubmitted?.(response.news);
      })
    });
  }

  return <Drawer
    title="Archive"
    width={520}
    onClose={onClose}
    visible={visible}
    bodyStyle={{ paddingBottom: 80 }}
  >
    <Form {...doubleColFormColSize} layout="vertical" onSubmit={onSubmit}>
      <Row>
        <Col>
          <p>Are you sure you wish to archive this article:</p>
          <p>
            <ul>
              <li>It will be not be visible to the public</li>
              <li>You can still find it as an administrator</li>
              <li>It can be re-published</li>
            </ul>
          </p>
        </Col>
      </Row>
      <LeftAlignedRow>
        <Col span={20}>
          <Form.Item
            colon={false}>
            <ButtonGroup>
              <Button onClick={(e) => onClose?.(e)} type="default">
                cancel
              </Button>
              <FullWithButton type={"primary"} htmlType="submit">
                Archive
              </FullWithButton>
            </ButtonGroup>
          </Form.Item>
        </Col>
      </LeftAlignedRow>
    </Form>
  </Drawer>;
}

export default Form.create<Props>({
  name: 'NewsArchiveForm',
})(NewsArchiveSidebarWithoutForms)