import * as React from 'react';
import { Row, Col, Button } from 'antd';
import TableFilterContextProvider from 'components/Forms/DataGrid/TableFilterContextProvider';
import { UserListFilterBar, UserListTable } from '.';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';

export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
}

export interface State {}

export class UserListViewWithoutRouter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  AddNewUser = () => {
    const newDetailsData = new DetailsData();
    newDetailsData.mode = DetailsMode.New;
    this.props.history.push('users/new', newDetailsData);
  };

  render() {
    return (
      <TableFilterContextProvider>
        <LeftAlignedRow>
          <Col>
            <Button size="large" type="primary" onClick={this.AddNewUser}>
              Add User
            </Button>
          </Col>
        </LeftAlignedRow>
        <Row>
          <Col md={24}>
            <UserListFilterBar />
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <UserListTable />
          </Col>
        </Row>
      </TableFilterContextProvider>
    );
  }
}

const UserListView = withRouter(UserListViewWithoutRouter);

export default UserListView;



