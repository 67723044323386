import * as React from 'react';
import { Row, Col, Card, Form } from 'antd';
import FilterTextbox from '../FilterTextbox';
import InfoIcon from 'components/Forms/InfoIcon';
import FilterDatePicker from '../FilterDatePicker';
import { FilterType } from 'Core/Api/Api';
import FilterDateRangePicker from '../FilterDateRangePicker';
import FilterSelectbox from '../FilterSelectbox';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { RounedForm } from './FilterBar.Styled';

export interface Props {
  children?: React.ReactNode;
  title?: React.ReactNode;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 8 },
    sm: { span: 5 },
    xl: { span: 9 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    xl: { span: 14 },
  },
};

export interface State {}

export default class FilterBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <LeftAlignedRow>
        <Col span={24}>
          <RounedForm {...formItemLayout} layout="inline">
            {this.props.children}
          </RounedForm>
        </Col>
      </LeftAlignedRow>
    );
  }
}













