import React, { FunctionComponent } from 'react';
import { Avatar, Badge, Menu, Icon, Button } from 'antd';
import { NavLink } from 'react-router-dom';
import MenuAvatar from '../MenuAvatar';

const LoginBar: FunctionComponent = () => {
    return (
        <Menu style={{ fontWeight: 500 }} mode="horizontal" theme="dark">
            <Menu.Item>
                <NavLink to={'/login'} ><Button type={'primary'} style={{ fontWeight: 400 }}>
                    Login </Button></NavLink>
            </Menu.Item>
        </Menu>
    );
};

export default LoginBar;
















