import { ReactNode, useEffect, useState } from 'react';
import * as React from 'react';
import { Button, Col, Form, Input, Popconfirm, Row, Typography } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { doubleColFormColSize, fullWidthSingleColFormColSize, FullWithButton, singleColFormColSize } from 'components/Forms/Form.Styled';
import FormControl from 'components/Forms/FormControl';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import ValidationUtility from 'Core/ValidationUtility';
import { FormComponentProps, FormProps } from 'antd/lib/form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { NewsHandler } from '../NewsHandler';
import { INewsModel, News, NewsStatus } from 'Core/Api/Api';
import NotificationService from 'Core/NotificationService';
import { useParams } from 'react-router';
import { RichTextEditor } from 'components/Forms/RichTextEditor';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import { Base64FileUploader } from 'components/Forms/Base64FileUploader';

interface EditNewsFormProps extends FormComponentProps<INewsModel> {
    children?: ReactNode;
}

const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(100px,200px));
  gap: 5px;
`;

export function EditNewsForm({ children, form }: EditNewsFormProps) {

    let getFieldDecorator = form?.getFieldDecorator;
    let history = useHistory();
    let params = useParams<{ id?: string }>();
    let newsHandler = new NewsHandler({});

    const [news, setNews] = useState<News | undefined>(undefined);

    useEffect(() => {
        if (params.id) {
            newsHandler.GetNewsByIdForAdmin({ id: params.id }).then(response => {
                setNewsForm(response.news);
            })
        }
    }, []);

    const setNewsForm = (news: News | undefined) => {
        setNews(news);
        form.setFieldsValue(news ?? {});
        news?.newsDataFile?.url && form.setFieldsValue({ "newsPicture": { name: news.newsDataFile.url.substring(news.newsDataFile.url.lastIndexOf("\/") + 1), url: news.newsDataFile.url } });

    }

    const onSubmit = (e?: React.SyntheticEvent) => {
        e?.preventDefault();

        form?.validateFieldsAndScroll((errors, values) => {
            if (errors != null) { return }

            values.id = news?.id;
            newsHandler.EditNews(values).then(response => {
                history.goBack();
                NotificationService.success(`Updated ${response.news?.name}`);
            })
        })
    }

    const onNewsImageLinkChange = () => {
        if (form.getFieldValue("newsPicture") != null) {
            form.setFieldsValue({ "newsPicture": null });
        }
    }

    const onNewsImageChange = () => {
        if (form.getFieldValue("mainImageLink") != null) {
            form.setFieldsValue({ "mainImageLink": null });
        }
    }


    return (
        <LeftAlignedRow >
            <Col>
                <Form {...fullWidthSingleColFormColSize} layout="horizontal" onSubmit={onSubmit}>
                    <LeftAlignedRow type='flex' justify='start'>
                        <Col span={20}>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                rules={[...ValidationUtility.required('Title is required')]}
                                name="title"
                                label="Title">
                                <Input size='large' />
                            </FormControl>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                rules={[...ValidationUtility.required('Name is required')]}
                                name="name"
                                labelAlign='left'
                                label="Name">
                                <Input size='small' />
                            </FormControl>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                name="mainImageLink"
                                label="Main Image Link">
                                <Input onChange={() => { onNewsImageLinkChange() }} />
                            </FormControl>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                name="ignoreOrText"
                                label=" "
                                colon={false}>
                                <Typography.Text>Or</Typography.Text>
                            </FormControl>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                name="newsPicture"
                                // rules={[...ValidationUtility.Base64ImageHasValidImageSizes([{ width: 300, height: 300 }], "Image size is not correct")]}
                                // help={"Image must be 300x300 pixels"}
                                // validateTrigger="onChange"
                                label="Main Image">
                                <Base64FileUploader accept='image/*' previewSize={{ width: "200", height: "150" }} onChange={() => { onNewsImageChange() }} />
                            </FormControl>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                rules={[...ValidationUtility.required('Overview is required')]}
                                name="overview"
                                label="Overview">
                                <TextArea rows={2} />
                            </FormControl>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                name="description"
                                rules={
                                    [
                                        {
                                            transform: ValidationUtility.TransformEditorState
                                        },
                                        {
                                            required: true,
                                            message: 'Instructional Materials required'
                                        },
                                        ...ValidationUtility.EditorStateIsEmpty()
                                    ]
                                }
                                validateTrigger="onBlur"
                                label="Body">
                                <RichTextEditor />
                            </FormControl>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                name="link"
                                label="Link">
                                <Input />
                            </FormControl>
                        </Col>
                    </LeftAlignedRow>


                    <LeftAlignedRow>
                        <Col span={20}>
                            <Form.Item
                                colon={false}>
                                <ButtonGroup>
                                    <Button onClick={() => history.goBack()} type="default">
                                        Back
                                    </Button>
                                    <ShowIfTrue condition={news?.status == NewsStatus.Published} >
                                        <Popconfirm okText="Yes" title={
                                            <p>
                                                This article has already been published. <br />
                                                Editing will unpublished it and require approval. <br />
                                                Are you sure you wish to continue? <br />
                                            </p>
                                        } onConfirm={onSubmit}>
                                            <FullWithButton type="primary" htmlType="submit">
                                                Submit
                                            </FullWithButton>
                                        </Popconfirm>
                                    </ShowIfTrue>
                                    <ShowIfTrue condition={news?.status != NewsStatus.Published} >
                                        <FullWithButton type="primary" htmlType="submit">
                                            Submit
                                        </FullWithButton>
                                    </ShowIfTrue>
                                </ButtonGroup>
                            </Form.Item>
                        </Col>
                    </LeftAlignedRow>
                </Form>
            </Col>
        </LeftAlignedRow>
    );
}

export default Form.create({ name: "edit-news-form" })(EditNewsForm);


