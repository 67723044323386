import * as React from 'react';
import ConfigService from 'Core/ConfigService';
import setupLogRocketReact from 'logrocket-react';
import LogRocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer';
import LogRocket from 'logrocket';
import AuthContext from 'Context/AuthContext';
import AuthUser from 'Core/models/AuthUser';

export interface Props {
  children?: React.ReactNode;
}

export interface State {
  currentUser?: AuthUser;
}

export default class LogRocketWrapper extends React.Component<Props, State> {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  componentDidUpdate() {
    if (this.context.data && this.context.data != this.state.currentUser) {
      const currentUser = this.context.data;
      LogRocket.identify(this.context.data?.email!, {
        userName: currentUser.username!,
        sessionId: currentUser.sessionId!,
      });

      this.setState({ currentUser });
    }
  }

  componentDidMount() {
    ConfigService.get().then(x => {
      if (x.logRocket.enabled) {
        const { requestSanitizer, responseSanitizer } = LogRocketFuzzySanitizer.setup(
          x.logRocket.sanitiziedParameters
        );

        LogRocket.init(x.logRocket.appName, {
          network: {
            requestSanitizer,
            responseSanitizer,
          },
        });

        // Hock into react
        setupLogRocketReact(LogRocket);
      }
    });
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}
















