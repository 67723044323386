import * as React from 'react';
import styled from 'styled-components';
import { Layout, Form } from 'antd';

export const RounedForm = styled(Form)`
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-top: 30px;
  margin-bottom: 30px;

  .ant-form-item {
    display: flex;
  }

  .ant-form-item-control-wrapper {
    flex: 1;
  }
`;













