import * as React from 'react';
import { CenterAlignedRow } from 'components/Styled/Layout/CenterAlignedRow';
import { Col, Form, Input, Row, Button } from 'antd';
import FormControl from 'components/Forms/FormControl';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps, NavLink } from 'react-router-dom';
import ValidationUtility from 'Core/ValidationUtility';
import PasswordResetHandler from '../RequestPasswordResetForm/PasswordResetHandler';
import { IResetPasswordCommandRequest, IChangePasswordCommandRequest } from 'Core/Api/Api';
import FormItem from 'antd/lib/form/FormItem';
import NotificationService from 'Core/NotificationService';
import ConfigService from 'Core/ConfigService';
import Title from 'antd/lib/typography/Title';
import styled from 'styled-components';
import { BrandedButton } from '../../Styled/Branded/BrandedButton';

export interface Props extends FormComponentProps, RouteComponentProps {
    children?: React.ReactNode;
}

export interface State {
    token?: string;
    emailAddress?: string;
}

const EditAdminChangePassword = styled.div`
.edit-password-admin{
    padding: 2vw;
    justify-content: center;
    display: flex;
    flex-direction:column;
}
`;

export class ChangePasswordFormWithoutFormsAndRouter extends React.Component<Props, State> {
    handler: PasswordResetHandler;

    constructor(props: Props) {
        super(props);

        this.state = {};
        this.handler = new PasswordResetHandler(props);
    }

    componentDidMount() {

    }

    onSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();
        this.props.form.validateFields((error, values: IChangePasswordCommandRequest) => {
            if (error) {
                return;
            }

            this.handler.ChangePassword(values).then(() => {
                this.props.history.push('/login');
                NotificationService.success('Password has been updated', "", 10, true);
            });
        });
    };

    render() {
        var { getFieldDecorator } = this.props.form;
        var passwordValidations = ValidationUtility.password;
        var compareValidations = ValidationUtility.compare;
        return (
            <EditAdminChangePassword>
                <Row>
                    <Col xs={24} sm={12}>
                        <Form className={'edit-password-admin'} onSubmit={this.onSubmit}>
                            <Title>Change Password </Title>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                rules={[...ValidationUtility.required("Current Password is required")]}
                                label="Current Password"
                                name="oldPassword">
                                <Input type="password" />
                            </FormControl>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                label="New Password"
                                name="newPassword"
                                rules={[...ValidationUtility.required("New Password is required"), ...passwordValidations()]}>
                                <Input type="password" />
                            </FormControl>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                rules={[...ValidationUtility.required("Confirm Password is required"), ...compareValidations(this.props.form, 'newPassword', 'Passwords do not match')]}
                                label="Confirm Password"
                                name="confirmPassword">
                                <Input type="password" />
                            </FormControl>
                            <FormItem>
                                <Row>
                                    <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <BrandedButton htmlType="submit">Update Password</BrandedButton>
                                    </Col>
                                </Row>
                            </FormItem>
                        </Form>
                    </Col>
                </Row>
            </EditAdminChangePassword>
        );
    }
}

const ChangePasswordFormWithoutRouter = Form.create<Props>({
    name: 'ChangePasswordForm',
})(ChangePasswordFormWithoutFormsAndRouter);

const ChangePasswordForm = withRouter(ChangePasswordFormWithoutRouter);

export default ChangePasswordForm;










