import Tag, { TagProps } from 'antd/lib/tag';
import React from 'react';

interface Props<EnumType = any> extends TagProps {
  value?: EnumType | string;
  options: { value: EnumType | string, color: string }[]
}

export default function ColoredPill<EnumType = any>({ value, options, ...rest }: Props<EnumType>) {

  const color = options?.find(x => x.value === value)?.color ?? undefined;
  return <Tag {...rest} children={value} color={color} />;
}