import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { User, SlimUser, Role, PaymentListItem, SimpleOrderListItem } from 'Core/Api/Api';
import SimpleTable from 'components/Forms/DataGrid/SimpleTable';
import UserHandler from '../../UserHandler';
import { NavLink } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import DetailsMode from 'Core/models/DetailsMode';
import DetailsData from 'Core/models/DetailsData';
import DonationHandler from '../../DonationHandler';
import NumberFormat from 'react-number-format';
import Utility from 'Core/Utility';
import moment from 'moment';
import ConfigService from 'Core/ConfigService';
import { Icon } from 'antd';
export interface Props {
    children?: React.ReactNode;
}

export interface State {
    countries: IFilterSelectOption[];
    jurisdictions: IFilterSelectOption[];
    roles: IFilterSelectOption[];
}

export default class DonationListTable extends React.Component<Props, State> {
    handler: DonationHandler;
    config = ConfigService.getSync();

    constructor(props: Props) {
        super(props);

        this.state = {
            countries: [],
            jurisdictions: [],
            roles: [],
        };
        this.handler = new DonationHandler(props);
    }

    componentDidMount() {

    }

    rolesListTableColumns: Array<ColumnProps<SimpleOrderListItem>> = [
        {
            title: 'Payer',
            dataIndex: 'order.user.firstName',
            sorter: true,
            render: (text: any, record: SimpleOrderListItem, index: number) => {
                return record.userName;
            }
        },
        {
            title: 'Project',
            dataIndex: 'project',
            render: (text: any, record: SimpleOrderListItem, index: number) => {
                return record.project?.title;
            },
        },
        {
            title: 'Currency',
            dataIndex: 'order.currency',
            sorter: true,
            render: (text: any, record: SimpleOrderListItem, index: number) => {
                return record.currency;
            },
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            sorter: true,
            render: (text: any, record: SimpleOrderListItem, index: number) => {
                return <NumberFormat value={record.amount} prefix="$" thousandSeparator="," displayType='text' />;
            },
        },
        {
            title: 'Date',
            dataIndex: 'creationDate',
            sorter: true,
            render: (text: any, record: SimpleOrderListItem, index: number) => {
                return Utility.renderDateWithReference(moment(record.createdDate), this.config.Display.DateTimeFormat);   
            },
        },
        {
            title: 'Type',
            dataIndex: 'paymentType',
            render: (text: any, record: SimpleOrderListItem, index: number) => {
                return text;
            },
        },
        {
            title: '',
            render: (text: any, record: SimpleOrderListItem, index: number) => {
                return (
                    <>
                        <NavLink
                            to={{
                                pathname: 'payments/' + encodeURIComponent(record.orderId ?? ''),
                                state: new DetailsData(DetailsMode.View, record.orderId),
                            }}>
                            <Icon type="read" title="View" />
                        </NavLink>

                    </>
                );
            },
        },
    ];

    render() {
        return (
            <SimpleTable<SimpleOrderListItem>
                tableKey="id"
                dataFetcher={this.handler}
                columns={this.rolesListTableColumns}
            />
        );
    }
}



