import * as React from 'react';
import { CenterAlignedRow } from 'components/Styled/Layout/CenterAlignedRow';
import { Col, Form, Input, Row, Button, DatePicker, Select } from 'antd';
import FormControl from 'components/Forms/FormControl';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps, NavLink } from 'react-router-dom';
import ValidationUtility from 'Core/ValidationUtility';
import UserProfileHandler from '../UserProfileHandler';
import { IResetPasswordCommandRequest, IChangePasswordCommandRequest, SimpleUserProfile, IDType } from 'Core/Api/Api';
import FormItem from 'antd/lib/form/FormItem';
import NotificationService from 'Core/NotificationService';
import ConfigService from 'Core/ConfigService';
import Title from 'antd/lib/typography/Title';
import { BrandedButton } from '../../Styled/Branded/BrandedButton';
import moment from 'moment';
import styled from 'styled-components';

export interface Props extends FormComponentProps, RouteComponentProps {
    children?: React.ReactNode;
}

export interface State {
    token?: string;
    profile?: any;
    emailAddress?: string;
}

const EditAdminUserProfile = styled.div`
.user-profile-admin{
    padding: 2vw;
    justify-content: center;
    display: flex;
    flex-direction:column;
}
`;

export class EditUserProfileFormWithoutFormsAndRouter extends React.Component<Props, State> {
    handler: UserProfileHandler;

    constructor(props: Props) {
        super(props);

        this.state = {};
        this.handler = new UserProfileHandler(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.getProfileInfo();
    }

    getProfileInfo() {
        this.handler.GetMyProfile().then(
            x => { 
                this.setState({ profile: x }, ()=>{
                    this.props.form.setFieldsValue(x ?? {});
                });
            },
            x => NotificationService.error(x.message)
        )
    }

    onSubmit(event: React.FormEvent) {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values: any) => {
            if (error) {
                return;
            }
            else {
                const request = new SimpleUserProfile();
                request.id = this.state.profile.id;
                request.firstName = values.firstName;
                request.lastName = values.lastName;
                request.phoneNumber = (values as any).phoneNumber;
                request.idType = values.idType;
                request.idNumber = values.idNumber;
                request.taxIdNumber = this.state.profile.taxIdNumber;

                console.log(request, values);

                this.handler.EditMyProfile(request).then(x => {
                    this.props.history.goBack();
                    NotificationService.success(`Updated ${request.firstName} profile`);
                 });

            }
                
        });
    }

    render() {
        var { getFieldDecorator } = this.props.form;
        var passwordValidations = ValidationUtility.password;
        var compareValidations = ValidationUtility.compare;
        return (
            <EditAdminUserProfile>
                <Row>
                    <Col xs={24} sm={12}>
                        <Form className={'user-profile-admin'}>
                            <Title>Edit User Profile </Title>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                rules={[...ValidationUtility.required("First Name is required")]}
                                label="First Name"
                                name="firstName">
                                <Input />
                            </FormControl>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                label="Last Name"
                                name="lastName"
                                rules={[...ValidationUtility.required("Last Name is required")]}>
                                <Input />
                            </FormControl>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                label="Phone Number"
                                name="phoneNumber">
                                <Input />
                            </FormControl>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                rules={[...ValidationUtility.required('Id Type is required')]}
                                name="idType"
                                label="ID Type">
                                <Select allowClear={true}
                                    placeholder={'please select an option'}
                                    optionFilterProp={'label'} >
                                    <Select.Option key={'Passport'} label={'Passport'} value={IDType.Passport}>
                                        Passport
                                    </Select.Option>
                                    <Select.Option key={"Driver's License"} label={"Driver's License"} value={IDType.DriversLicense}>
                                        Driver's License
                                    </Select.Option>
                                    <Select.Option key={'National ID'} label={'National ID'} value={IDType.NationalID}>
                                        National ID
                                    </Select.Option>
                                </Select>
                            </FormControl>
                            <FormControl
                                getFieldDecorator={getFieldDecorator}
                                rules={[...ValidationUtility.required('Id Number is required')]}
                                name="idNumber"
                                label="ID Number">
                                <Input />
                            </FormControl>
                            <FormItem>
                                <Row>
                                    <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <BrandedButton onClick={this.onSubmit} htmlType="button">Update Profile</BrandedButton>
                                    </Col>
                                </Row>
                            </FormItem>
                        </Form>
                    </Col>
                </Row>
            </EditAdminUserProfile>
        );
    }
}

const EditUserProfileFormWithoutRouter = Form.create<Props>({
    name: 'EditUserProfileForm',
})(EditUserProfileFormWithoutFormsAndRouter);

const EditUserProfileForm = withRouter(EditUserProfileFormWithoutRouter);

export default EditUserProfileForm;










