import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { Row, Col, Card, Icon, Button, Tabs, List, Avatar, Divider } from 'antd';

import { NavLink, RouteComponentProps } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import DetailsMode from 'Core/models/DetailsMode';
import DetailsData from '../../../../Core/models/DetailsData';
import { encode } from 'punycode';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { BrandedLink } from '../../../Styled/Branded/BrandedLink'
import { BrandedButton } from '../../../Styled/Branded/BrandedButton';
import IfNotLoggedInModal from '../../../Forms/IfNotLoggedInModal';
import AuthContext from '../../../../Context/AuthContext';
import { startCase } from 'lodash';
import { FullWithButton } from '../../../Forms/Form.Styled';
import Title from 'antd/lib/typography/Title';

const { TabPane } = Tabs;

export interface Props extends RouteComponentProps {
    children?: React.ReactNode;
}

export interface State {
    
}

export default class DashboardViewCard extends React.Component<Props, State> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    constructor(props: Props) {
        super(props);

        this.state = {
            
        };
    }

    componentDidMount() {
        
    }

    generalApplyClicked(id: string | undefined) {
        if (id) {
            if (this.context.data) {
                this.props.history.push(
                    `/jsix/apply/${id}`,
                    new DetailsData(DetailsMode.New, id)
                );
            } else {
            }
        }
    };

    render() {
        const { Meta } = Card;
        const IconText = ({ type, text }) => (
            <span>
                <Icon type={type} style={{ marginRight: 8 }} />
                {text}
            </span>
        );

        return (
            <div>
                <Row gutter={16} type={'flex'}>
                    {
                        
                    }
                </Row>
            </div>
        );
    }
}













