import React, { ChangeEvent } from 'react';
import { DatePicker } from 'antd';
import * as moment from 'moment';
import FilterFieldWrapper from '../FilterFieldWrapper';
import { FilterFieldWrapperProps } from '../FilterFieldWrapper/FilterFieldWrapper';
import { TextInputChangeEventData } from 'react-native';
import debounce from 'lodash/debounce';
import {
  DatePickerProps,
  RangePickerValue,
  RangePickerPresetRange,
  RangePickerProps,
} from 'antd/lib/date-picker/interface';
import { IFilter, FilterType, JoinType } from 'Core/Api/Api';
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

export interface FilterDateRangePickerProps extends FilterFieldWrapperProps, RangePickerProps { }

export default class FilterDateRangePicker extends FilterFieldWrapper<FilterDateRangePickerProps> {
  constructor(props: FilterDateRangePickerProps) {
    super(props);
    this.UpdateFilter = debounce(this.UpdateFilter.bind(this), 700);

    if (this.props.showTime) {
      // IF show time is present then only use on okay. Redefine onChange to a null method
      this.onChange = (a, b) => { };
    }
  }

  onChange = (dates: RangePickerValue, dateStrings: [string, string]): void => {
    this.UpdateFilter(dates);
  };

  onOk = (selectedTime: RangePickerPresetRange) => {
    this.UpdateFilter(selectedTime);
  };

  /**
   * Create a list of filters based on the controls properties
   * and the value(s) that have been passed in
   *
   * @protected
   * @param {*} filterValue (will be [moment.Moment, moment.Moment] for this control)
   * @returns {IFilter[]}
   * @memberof FilterFieldWrapper
   */
  protected CreateFilterList(filterValue: any): IFilter[] {
    const filters: IFilter[] = [];
    const filterFields = this.props.filterFields.split(',').map(x => x.trim());

    // Get dates for filters
    const dateValues = filterValue as [moment.Moment, moment.Moment];
    const minDate = dateValues[0] ? dateValues[0].startOf('day').toISOString() : undefined;
    const maxDate = dateValues[1] ? dateValues[1].endOf('day').toISOString() : undefined;

    for (const field of filterFields) {
      var filterGroup: IFilter & { filterId?} = {}; // Because this will be the markedFilter
      filterGroup.filterType = FilterType.Group;
      filterGroup.filterId = this.state.filterId;
      filterGroup.joinType = this.props.filterJoin;
      filterGroup.groupFilters = [];

      if (minDate) {
        filterGroup.groupFilters.push({
          field,
          filterType: FilterType.GreaterThanOrEqualTo,
          joinType: JoinType.And,
          value: minDate,
        });
      }

      if (maxDate) {
        filterGroup.groupFilters.push({
          field,
          filterType: FilterType.LessThanOrEqualTo,
          joinType: JoinType.And,
          value: maxDate,
        });
      }

      filters.push(filterGroup);
    }
    return filters;
  }

  render() {
    const { filterFields, filterJoin, filterType, onFilterUpdated, ...inputProps } = this.props;
    return <RangePicker onChange={this.onChange} onOk={this.onOk} {...inputProps} />;
  }
}
















